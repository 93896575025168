import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Route, useHistory, Switch, Redirect } from 'react-router-dom'
import { getPurchasesAdmin } from '../actions/adminActions'


import './Dashboard.css'

import PedidosDashboard from './Dashboard/PedidosDashboard'
import OfertasDashboard from './Dashboard/OfertasDashboard'
import AdministradoresDashboard from './Dashboard/AdministradoresDashboard'
import ProductosDashboard from './Dashboard/ProductosDashboard'
import LayoutDashboard from '../components/LayoutDashboard'
import DiscountCodesDashboard from './Dashboard/DiscountCodesDashboard'
import PurchaseDetails from './Dashboard/PurchaseDetails'

const Dashboard = ({ match, routes }) => {
    const admin = useSelector(state => state.adminLogin )
    const { adminInfo } = admin;
    
    
    // console.log(match);
    

    const dispatch = useDispatch()
    let history = useHistory();


    useEffect(() => {
        if (adminInfo) {
            dispatch(getPurchasesAdmin(adminInfo.token))
        } else {
            history.push("/loginAdmin?redirect=dashboard")
        }
    })


    return (
        <div className=''>
            {
                adminInfo ?
                <LayoutDashboard>
                    <Switch>
                        <Redirect exact from='/dashboard' to='/dashboard/pedidos' />
                        <Route exact path={"/dashboard/pedidos"} component={PedidosDashboard} />
                        <Route exact path={"/dashboard/pedidos/:id"} component={PurchaseDetails} />
                        <Route exact path={"/dashboard/productos"} component={ProductosDashboard} />
                        <Route exact path={"/dashboard/administradores"} component={AdministradoresDashboard} />
                        <Route exact path={"/dashboard/ofertas"} component={OfertasDashboard} />
                        <Route exact path={"/dashboard/codigosDescuento"} component={DiscountCodesDashboard} />
                    </Switch>
                </LayoutDashboard>
                :
                <h1>No tienes permiso para acceder a esta pagina (NotFound)</h1>
            }
            
        </div>
    )
}

export default Dashboard
