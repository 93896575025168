import React from 'react'

import './Pagination.css'
import { fetchPageNumbers } from '../utils/paginationNumbers';

  
const PaginationDO1 = (props) => {
    const { totalRecords, pageLimit, currentPage } = props
    const totalPages = Math.ceil(totalRecords/pageLimit)
    const pageNeighbours = Math.max(0, Math.min(props.pageNeighbours, 2))
   
    
   
    const handleClick = page => e => {
        e.preventDefault();
        props.onPageChanged(page);
    }
    
    const handleMoveLeft = e => {
        e.preventDefault();
        props.onPageChanged(currentPage - (pageNeighbours * 2) - 1);
    }

    const handleMoveRight = e => {
        e.preventDefault();
        props.onPageChanged(currentPage + (pageNeighbours * 2) + 1);
    }
    
    
    
   
    const pages = fetchPageNumbers(pageNeighbours, currentPage, totalPages);
    
    
    return (
        <div >
            <ul className="pagination">
            { pages.map((page, index) => {

                if (page === 'LEFT') return (
                    <li key={index} className="page-item">
                        <button className="page-link" aria-label="Previous" onClick={handleMoveLeft}>
                            <span aria-hidden="true">&laquo;</span>
                            <span className="sr-only">Previous</span>
                        </button>
                    </li>
                );

                if (page === 'RIGHT') return (
                    <li key={index} className="page-item">
                        <button className="page-link" aria-label="Next" onClick={handleMoveRight}>
                            <span aria-hidden="true">&raquo;</span>
                            <span className="sr-only">Next</span>
                        </button>
                    </li>
                );

                return (
                <li key={index} className={`page-item${ currentPage === page ? ' active' : ''}`}>
                    <button className="page-link" onClick={ handleClick(page) }>{ page }</button>
                </li>
                );

            }) }

            </ul>
        </div>
    )
}

export default PaginationDO1
