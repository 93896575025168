import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { resendVerificationEmail } from '../actions/userActions';

const ResendEmail = () => {

    const { userInfo } = useSelector(state => state.userLogin);
    const { loadingResend, emailResentMessage } = useSelector(state => state.userResendVerificationEmail);

    const dispatch = useDispatch();

    const handleResentVerificationEmail = () => {
        dispatch(resendVerificationEmail(userInfo.token))
    }

    return (
        <div className="resend_email">
            <h5 className="error-message">
                Para proceder a la compra debes de estar verificado
            </h5>
            <h5>
                Si no has recibido un correo de verificación has 
                click en el siguiente boton
            </h5>
            <button 
                onClick={handleResentVerificationEmail} 
                className="primary2 "
            >
                {
                    loadingResend ?
                    <ClipLoader />
                    :
                    "Obtener correo de verificación"
                }
            </button>
            {
                emailResentMessage &&
                <h3>{emailResentMessage}</h3>
            }
        </div>
    )
}

export default ResendEmail
