import React, { useEffect, useState } from 'react'
import { Link  } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import './LoginAdmin.css'

import { adminLogin } from '../actions/adminActions'


function LoginAdmin(props) {
    const [ email, setEmail ] = useState('')
    const [ password, setPassword ] = useState('')
    const [ errorPassword, setErrorPassword ] = useState(null)

    const { loading, adminInfo, error } = useSelector(state => state.adminLogin)
    
    const dispatch = useDispatch()


    useEffect(() => {
        if (adminInfo){
            props.history.push("/dashboard")
        }
    }, [adminInfo, props.history])


    const submitHandler = (e) => {
        e.preventDefault();
        if (password.length < 8) {
            setErrorPassword("El campo password debe ser de al menos 8 carácteres.")
        } else {
            setErrorPassword(null)
            dispatch(adminLogin(email, password));
        }
    }
    
    return (
        <div className="loginAdmin-container">
            <div className="form-container">
                <form onSubmit={submitHandler} className="form">
                    <ul className="form-list">
                        <li>
                            <h3>Inicia sesión</h3>
                        </li>
                        <li>
                            {loading && <div>Loading...</div>}
                            {error && <h4 className="error-message">{error.message}</h4>}
                            {errorPassword && <h4 className="error-message">{errorPassword}</h4>}
                        </li>
                        <li>
                            <label for="email">
                                Email
                            </label>
                            <input 
                                type="email" 
                                name="email" 
                                id="email" 
                                onChange={(e) => setEmail(e.target.value)}    
                            />
                        </li>
                        <li>
                            <label for="Password">
                                Password
                            </label>
                            <input 
                                type="password" 
                                name="email" 
                                id="password" 
                                onChange={(e) => setPassword(e.target.value)}    
                            />
                        </li>
                        
                        <li>
                            <button type="submit" className="button primary1">
                                Iniciar sesión
                            </button>
                        </li>
                        <li>

                            <Link 
                                    to="/restorePassword" 
                                    className="button"
                                >
                                Olvidaste tu contraseña?
                            </Link>
                        </li>
                    </ul>
                </form>
            </div>
        </div>
    )
}

export default LoginAdmin
