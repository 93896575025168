import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { changeOfferStatus, getOffers } from '../../actions/adminActions'
import OfferForm from '../../components/OfferForm'

import Table from '../../components/Table'
// import { ClipLoader } from 'react-spinners'
import { useHistory } from 'react-router'
import { searchProductsArrayID } from '../../actions/productActions'




const Ofertas = () => {
    const [ isNewOfferOpen, setNewOfferOpenTo ] = useState(false)

    const admin = useSelector(state => state.adminLogin )
    const { adminInfo } = admin;

    const offersInfo = useSelector(state => state.adminOffers)
    const { offers, offerHasChanged, error } = offersInfo

    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(() => {
        if (document.querySelector(".sidebar")){
            document.querySelector(".sidebar").classList.remove("open")
        }
    }, [])
    
    useEffect(() => {
        dispatch(getOffers(adminInfo.token))    
    }, [offerHasChanged, dispatch, adminInfo.token])


    const handleStatusChange = (id_offer, status) => {
        dispatch(changeOfferStatus(adminInfo.token, id_offer, status))

    }

    function getButtonRow (isActive, id) {
        if (isActive){
            return(
                <button className="delete" onClick={() => handleStatusChange(id, true)}>
                    Desactivar
                </button>
            )
        } else {
            return(
                <button className="primary1" onClick={() => handleStatusChange(id, false)}>
                    Activar
                </button>
            )
        }
        
    }

    function getSeeOfferButton(offer) {
        return (
            <button className="primary2" onClick={() => handleSeeOffer(offer)}>
                Ver oferta
            </button>
        )
    }

    
    const handleSeeOffer = (offer) => {
        const idArray = offer.products.map(prod =>  prod.id )
        dispatch(searchProductsArrayID(idArray))
        history.push("/results")
    }

    function getTableRows (items) {
        var activeOffers = []
        items.forEach((x, i) =>{
            if (x.is_active) {
                activeOffers.push(x);
            }
        })
        const rows = activeOffers.map(offer => {
            return([
                offer.id_offer,
                // <img src={offer.image} alt="oferta"/>,
                offer.products.length,
                offer.is_active ? "Activa": "Inactiva",
                getButtonRow(offer.is_active, offer.id_offer),
                getSeeOfferButton(offer)
            ])

        })
        return rows
    }

    return (
        <div>
            <div className="new-offer">
                <div className="show-form-offer">
                    <button className="primary1" onClick={() => setNewOfferOpenTo(!isNewOfferOpen)}>
                        Crear oferta nueva
                    </button>
                    { isNewOfferOpen && <OfferForm adminInfo={adminInfo}/> }
                    
                </div>
            </div>
            <div className="listing-offers">
                <h2>Ofertas</h2>
                
                {
                    error && <h2>Ocurrio un error, inténtelo más tarde</h2>
                }
                <Table 
                    className="offers-table"
                    columnsHeader={["ID", "Productos", "Status", "Acción", "Ver"]}
                    rows={getTableRows(offers)}
                />
            </div>
        </div>
    )
}

export default Ofertas
