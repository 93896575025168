import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Table from './Table'

import './EditProduct.css'

import ClipLoader from 'react-spinners/ClipLoader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-regular-svg-icons'
import { searchProducts } from '../actions/productActions'
import { CSSTransition } from 'react-transition-group'
import EditProductModal from './EditProductModal'


const EditButton = ({ onClick }) => {
    const style = {
        "width": "100%",
        "padding": "0.5rem",
        "margin": "0"
    }
    return (
        <>
        <button type="button" style={style} onClick={onClick}>
            <FontAwesomeIcon icon={faEdit}/>
        </button>
        </>
    )
}

const Editproduct = () => {
    const [ searchQuery, setSearchQuery ] = useState("")
    const [ isEditModalOpen, setEditModalOpenTo ] = useState(false)
    const [ productToEdit, setProductToEdit ] = useState()

    const dispatch = useDispatch()

    const results = useSelector(state => state.resultsList)
    const { loading, productsResults, error } = results

    useEffect(() => {
        if(searchQuery.length >= 3) {
            dispatch(searchProducts(searchQuery));
        }
    }, [searchQuery, dispatch])

    const openEditModal = (prod) => {
        setProductToEdit(prod)
        setEditModalOpenTo(!isEditModalOpen)
    }

    function getSearchRows() {
        const rows = productsResults.map(prod => {
            return([
                <EditButton onClick={() => openEditModal(prod)} prod={prod} />,
                prod.id,
                <img src={prod.image} alt="product" />,
                prod.name,
                prod.price.toFixed(2),
                prod.first_category,
                prod.second_category
            ])
        })
        return rows
    }


    

    return (
        <div className="edit-product-form">
            <ul className="form-list">
                
                <li>
                    <input 
                        type="text" 
                        name="product-query" 
                        id="product-query"
                        placeholder="Buscar productos ..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}  
                    />
                </li>
            </ul>
            <div className="display-search">
                { loading ? <ClipLoader /> :
                    <Table 
                        columnsHeader={["", "ID", "Imágen", "Nombre", "Precio", "Categoría", "Subcategoría"]}
                        rows={getSearchRows()}
                    />
                }
                {
                    error && <h2>Se produjo un error, inténtelo más tarde</h2>
                }
            </div>
            {
                isEditModalOpen &&
                <CSSTransition
                    in={isEditModalOpen}
                    timeout={500}
                    classNames="modal-transition"
                    unmountOnExit
                >
                    <EditProductModal
                        isOpen={isEditModalOpen}
                        product={productToEdit}
                        onClose={()=>setEditModalOpenTo(false)}
                    />
                </CSSTransition>
            }
        </div>
    )
}

export default Editproduct
