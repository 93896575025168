import React, { useState } from 'react'
//import FiltersButton from './FiltersButton';
import Checkbox from './Checkbox';

import { useDispatch } from 'react-redux'

import './SideFiltersDesktop.css'
import FilterDropdown from './FilterDropdown';
import { filterSearchProducts } from '../actions/productActions';

const SideFiltersDesktop = ({ categories, subcategories }) => {
    const [ selectedCheckboxes, setSelectedCheckboxes ] = useState({
        abc: new Set(),
        price: new Set(),
        category: new Set(),
        subcategory: new Set()
    });
    


    const dispatch = useDispatch()
    const toggleCheckbox = (field, value) => {
        if (selectedCheckboxes[field].has(value)) {
            selectedCheckboxes[field].delete(value);
            const newSelectedCheckboxes = selectedCheckboxes;
            setSelectedCheckboxes(newSelectedCheckboxes);
        } else {
            selectedCheckboxes[field].add(value);
            const newSelectedCheckboxes = selectedCheckboxes; 
            setSelectedCheckboxes(newSelectedCheckboxes);
        }
        
        dispatch(filterSearchProducts(selectedCheckboxes))
    }


    return (
        <div>
            
            
            <FilterDropdown title={"Orden alfabético"}>
                <Checkbox label={"A-C"} handleCheckboxChange={toggleCheckbox} 
                    key={"A-C"} field={"abc"} value={"C"} 
                    isChecked={selectedCheckboxes["abc"].has("C")}/>
                <Checkbox label={"D-H"} handleCheckboxChange={toggleCheckbox} 
                    key={"D-H"} field={"abc"} value={"H"} 
                    isChecked={selectedCheckboxes["abc"].has("H")}
                    />
                <Checkbox label={"I-O"} handleCheckboxChange={toggleCheckbox} 
                    key={"I-O"} field={"abc"} value={"O"} 
                    isChecked={selectedCheckboxes["abc"].has("O")}
                    />
                <Checkbox label={"P-Z"} handleCheckboxChange={toggleCheckbox} 
                    key={"P-Z"} field={"abc"} value={"Z"} 
                    isChecked={selectedCheckboxes["abc"].has("Z")}
                    />
            </FilterDropdown>
            
            <FilterDropdown title={"Precio"}>
                <Checkbox label={"$0 - $49.99"}    handleCheckboxChange={toggleCheckbox} 
                    key={"$0 - $49.99"}    field={"price"} value={49.99}
                    isChecked={selectedCheckboxes["price"].has(49.99)}
                    />
                <Checkbox label={"$50 - $ 199.99"} handleCheckboxChange={toggleCheckbox} 
                    key={"$50 - $ 199.99"} field={"price"} value={199.99}
                    isChecked={selectedCheckboxes["price"].has(199.99)}
                    />
                <Checkbox label={"$200 - $499.99"} handleCheckboxChange={toggleCheckbox} 
                    key={"$200 - $499.99"} field={"price"} value={499.99}
                    isChecked={selectedCheckboxes["price"].has(499.99)}
                    />
                <Checkbox label={"+ $500.00"}      handleCheckboxChange={toggleCheckbox} 
                    key={"+ $500.00"}      field={"price"} value={1000000}
                    isChecked={selectedCheckboxes["price"].has(1000000)}
                    />
            </FilterDropdown>

            <FilterDropdown title={"Categorías"}>
                {
                categories && categories.map((cat,i) => {
                    return(
                        <Checkbox 
                            label={cat[0] + " (" + cat[1] + ")"} 
                            handleCheckboxChange={toggleCheckbox} 
                            key={i} 
                            field={"category"}
                            value={cat[0]}
                            isChecked={selectedCheckboxes["category"].has(cat[0])}
                        />
                    )
                })
                }
            </FilterDropdown>

            <FilterDropdown title={"Subcategorías"}>
                {
                    subcategories && subcategories.map((sub,i) => {
                        return(
                            <Checkbox 
                                label={sub[0] + " (" + sub[1] + ")"} 
                                handleCheckboxChange={toggleCheckbox} 
                                key={i} 
                                field={"subcategory"}
                                value={sub[0]}
                                isChecked={selectedCheckboxes["subcategory"].has(sub[0])}
                            />
                        )
                    })
                }
            </FilterDropdown>
                
        </div>
    )
}

export default SideFiltersDesktop
