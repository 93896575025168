import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'

import "./CategoryDropdown.css"



function CategoryDropdown() {
    const [ openCategory, setOpenCategory ] = useState(false)


    return (
        <>
                    <button className="category-button"  onClick={() => setOpenCategory(!openCategory)}>
                        {
                            openCategory ?
                                <>
                                Otros <FontAwesomeIcon icon={faChevronDown} />
                                </>
                                :
                                <>
                                Otros <FontAwesomeIcon icon={faChevronUp} />
                                </>
                        }
                    </button>
                    {
                        openCategory &&
                        <div className="dropdown-category">
                            <Link onClick={() => setOpenCategory(!openCategory)} to="/results_category/Abarrotes" className="category-dropdown-item">
                                Abarrotes
                            </Link>
                            <Link onClick={() => setOpenCategory(!openCategory)} to="/results_category/Bebes%20e%20incontinencia" className="category-dropdown-item">
                                Bebes e incontinencia
                            </Link>
                            <Link onClick={() => setOpenCategory(!openCategory)} to="/results_category/Cuidado%20personal%20y%20belleza" className="category-dropdown-item">
                                Cuidado personal y belleza
                            </Link>
                            <Link onClick={() => setOpenCategory(!openCategory)} to="/results_category/Desodorante" className="category-dropdown-item">
                                Desodorantes
                            </Link>
                            <Link onClick={() => setOpenCategory(!openCategory)} to="/results_category/Dulces%20y%20chocolates" className="category-dropdown-item">
                                Dulces y Chocolates
                            </Link>
                            <Link onClick={() => setOpenCategory(!openCategory)} to="/results_category/Galletas" className="category-dropdown-item">
                                Galletas
                            </Link>
                            <Link onClick={() => setOpenCategory(!openCategory)} to="/results_category/Helados" className="category-dropdown-item">
                                Helados
                            </Link>
                            <Link onClick={() => setOpenCategory(!openCategory)} to="/results_category/Higiene%20bucal" className="category-dropdown-item">
                                Higiene bucal
                            </Link>
                            <Link onClick={() => setOpenCategory(!openCategory)} to="/results_category/Higiene%20intima" className="category-dropdown-item">
                                Higiene íntima
                            </Link>
                            <Link onClick={() => setOpenCategory(!openCategory)} to="/results_category/Perfumeria" className="category-dropdown-item">
                                Perfumeria
                            </Link>
                            <Link onClick={() => setOpenCategory(!openCategory)} to="/results_category/Productos%20covid" className="category-dropdown-item">
                                Productos Covid
                            </Link>
                            <Link onClick={() => setOpenCategory(!openCategory)} to="/results_category/Salud%20sexual" className="category-dropdown-item">
                                Salud sexual
                            </Link>
                            <Link onClick={() => setOpenCategory(!openCategory)} to="/results_category/Suplementos%20alimenticios" className="category-dropdown-item">
                                Suplementos alimenticios
                            </Link>
                            <Link onClick={() => setOpenCategory(!openCategory)} to="/results_category/Tintes" className="category-dropdown-item">
                                Tintes
                            </Link>
                        </div>
                    }   
        </>
    )
}


export default CategoryDropdown
