import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { ClipLoader } from 'react-spinners'

import { validateDiscountCode } from '../actions/orderActions'

const DiscountCodeForm = () => {
    const [ discountCode, setDiscountCode ] = useState("")
    
    const { loading } = useSelector(state => state.discountCode)

    const dispatch = useDispatch()
    
    const handleSubmitDiscountCode = (e) => {
        e.preventDefault()
        dispatch(validateDiscountCode(discountCode))
    }

    return (
        <form onSubmit={handleSubmitDiscountCode}>
            <ul className="form-list">
                <li className="input-discount-code">
                    <input 
                        type="text" 
                        placeholder="Código de descuento"
                        value={discountCode}
                        required
                        onChange={(e) => setDiscountCode(e.target.value)}
                    />
                </li>
                <li className="button-discount-code" >
                    {
                        discountCode !=="" ?
                        <button   
                            type="submit"
                            className="primary1" 
                        >
                            {
                                loading ?
                                <ClipLoader />
                                :
                                "Usar"
                            }
                        </button>
                        :
                        <button 
                            disabled 
                            className="basic"
                        >
                            Usar
                        </button>
                    }
                </li>
            </ul>
        </form>
    )
}

export default DiscountCodeForm
