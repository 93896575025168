import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import Layout from '../components/Layout'

import routes from './routes'
import RouteWithSubRoutes from './RouteWithSubRoutes'

const App = () => {
    return (
        <BrowserRouter>
            <Layout>
                <Switch>
                    {
                        routes.map((route,i) => (
                            route.routes? 
                                <RouteWithSubRoutes key={i} {...route} />                            
                            : <Route exact {...route} key={i}/>
                        ))
                    }
                </Switch>
            </Layout>
        </BrowserRouter>
    )
}

export default App;