import React, { useState, useEffect, useRef } from 'react'


import './SlideShow.css'


export const useIsMount = () => {
    const isMountRef = useRef(true);
    useEffect(() => {
      isMountRef.current = false;
    }, []);
    return isMountRef.current;
  };

const SlideShow = (props) => {
    const [ slideShowing, setSlideShowing ] = useState(1)
    const [ slides, setSlides ] = useState(
        document.getElementsByClassName("carousel-item")
    )
    const [ numSlides, setNumSlides ] = useState()

    const [ dotsIndexes, setDotsIndexes ] = useState([0,1])
    


    

    useEffect(() => {
        // effect to get the number of dots and set number of slides
        var circles = [];
        for (var i=0; i<slides.length; i++) {
            circles.push(i)
        }
        setDotsIndexes(circles)
        setNumSlides(slides.length)


    }, [])

  

    useEffect(() => {
        // Effect to change the slide

        let i;
        for (i = 0; i < slides.length; i++) {
          slides[i].style.display = "none";
        }
        slides[slideShowing-1].style.display = "block";
        
        let circles = document.getElementsByClassName("slideShow_dot");
        for (i = 0; i < circles.length; i++) {
            circles[i].className = circles[i].className.replace(" active", "");
        }
        circles[slideShowing-1].className += " active";
    }, [slideShowing])
    
    

    const currentSlide = (n) => {
        
        setSlideShowing(n);
    }

    const plusSlides = (n) => {
        

        var newSlideShowing = slideShowing + n
        
        if (newSlideShowing > numSlides) {
            newSlideShowing = 1
        }
        if (newSlideShowing < 1) {
            newSlideShowing = numSlides
        }
        setSlideShowing(newSlideShowing)

        
    }


    return (
        <>

            <div className="slideShow_Container">
                { props.children}
                <a  className="slide_prev" onClick={() => plusSlides(-1)}>&#10094;</a>
                <a  className="slide_next" onClick={() => plusSlides(1)}>&#10095;</a>
            </div>
            <div className="slideShow_DotsContainer">
                {
                    
                    dotsIndexes.map((dot,i) => {
                        return(
                            <span 
                                className="slideShow_dot" 
                                onClick={() => currentSlide(i+1)} 
                                key={i}
                            >
                            </span>
                        )
                    })
                }
            </div>
        </> 
    )
}

export default SlideShow