import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { userLogin } from '../actions/userActions'

import './LoginUser.css'



function LoginUser(props) {
    if (document.querySelector(".sidebar")){
        document.querySelector(".sidebar").classList.remove("open")
    }
    const [ email, setEmail ] = useState('')
    const [ password, setPassword ] = useState('')
    const [ errorPassword, setErrorPassword ] = useState(null)

    const user = useSelector(state => state.userLogin)
    
    const { loading, userInfo, error } = user
    // console.log(error);
    const dispatch = useDispatch()
    const redirect = props.location.search?props.location.search.split("=")[1]:"/"

    useEffect(() => {
        if (userInfo){
            //console.log("redirecting from login to: ");
            //console.log(redirect);
            props.history.push(redirect)
        }
        return () => {
            
        }
    }, [userInfo, props.history, redirect])


    const submitHandler = (e) => {
        e.preventDefault();
        if (password.length < 8) {
            setErrorPassword("El campo password debe ser de al menos 8 carácteres.")
        } else {
            setErrorPassword(null)
            dispatch(userLogin(email, password));
        }
    }
    
    return (
        <div className="loginUser-container">

            <div className="form-container">
                <form onSubmit={submitHandler} className="form">
                    <ul className="form-list">
                        <li>
                            <h3>Iniciar sesión</h3>
                        </li>
                        <li>
                            {loading && <div>Loading...</div>}
                            {error && <h4 className="error-message">{error.message}</h4>}
                            {errorPassword && <h4 className="error-message">{errorPassword}</h4>}
                        </li>
                        <li>
                            <label htmlFor="email">
                                Correo electrónico
                            </label>
                            <input 
                                type="email" 
                                name="email" 
                                id="email" 
                                required
                                onChange={(e) => setEmail(e.target.value)}    
                            />
                        </li>
                        <li>
                            <label htmlFor="Password">
                                Contraseña
                            </label>
                            <input 
                                type="password" 
                                name="email" 
                                id="password" 
                                required
                                onChange={(e) => setPassword(e.target.value)}    
                            />
                        </li>
                        <li>
                            <button type="submit" className="button primary1">
                                Iniciar sesión
                            </button>
                        </li>
                        <Link 
                                to="/restorePassword" 
                                className="button"
                            >
                            Olvidaste tu contraseña?
                        </Link>
                        <li>
                            Nuevo en Farmacias Dr. Vertiz?
                        </li>
                        <li>
                            <Link 
                                to={redirect === "/" ? "signupUser" : "signupUser?redirect=" + redirect} 
                                className="button"
                            >
                                Crea una cuenta con nosotros
                            </Link>
                        </li>
                        
                    </ul>
                </form>
            </div>
        </div>
    )
}

export default LoginUser
