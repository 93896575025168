import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'


import { ClipLoader } from 'react-spinners'

import './Dashboard/PurchaseDetails.css'
import Table from '../components/Table';
import { getPurchaseByIdUser } from '../actions/userActions';
import { getStatus } from '../utils/getStatus'


const ProfilePurchaseDetails = () => {
    const params_url = useParams();

    const { userInfo } = useSelector(state => state.userLogin);

    const {loading, singlePurchase, error } = useSelector(state => state.userPurchases)

    const dispatch = useDispatch();
    // console.log("singlePurchase: ", singlePurchase);
    
    useEffect(() => {
        dispatch(getPurchaseByIdUser(userInfo.token, params_url.id))    
    }, [dispatch, userInfo.token, params_url.id])

    function getRowsPurchase() {
        return([
            ["Dirección de envio" ,singlePurchase.address],
            ["Teléfono" ,singlePurchase.phone],
            ["Total de compra" , "$ " + singlePurchase.total],
            ["Facturación" ,singlePurchase.billing_detail ? "Sí" : "No"],
            ["Estado del pedido: ", getStatus(singlePurchase.status)]
        ])
    }

    function getRowsProducts(){
        const rows = singlePurchase.purchase_detail.map(prod => {
            return([
                prod.id,
                <img src={prod.image} alt="product"/>,
                prod.name,
                prod.quantity,
            ])
        })
        return rows
    }

    return (
        <div style={{"padding": "5rem"}}>
            {
                loading && <ClipLoader />
            } 
            {
                error && <h2>{error.message}</h2> 
            }
            {
                singlePurchase &&
                <div className="purchase-info">
                    <h2>Información del pedido</h2>
                    <Table
                        columnsHeader={["",""]}
                        rows={getRowsPurchase()}
                    />
                    
                    <h2>Productos del pedido: </h2>
                    <div className="list-products">
                        <Table
                            columnsHeader={["ID","", "Producto", "Piezas"]}
                            rows={getRowsProducts()}
                        />
                        
                    </div>
                </div>
            }
        </div>
    )
}

export default ProfilePurchaseDetails
