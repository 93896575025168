import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import { useSelector, useDispatch } from 'react-redux';
import { savePurchase } from '../actions/orderActions';

import './SuccessfulPayment.css'
import { clearCart } from '../actions/cartActions';

const SuccessfulPayment = () => {

    const dispatch = useDispatch()

    const { userInfo } = useSelector(state => state.userLogin )
    const { shippingInfo } = useSelector(state => state.shipping)

    useEffect(() => {
        // console.log("renderizando Succesful Payment");
        if (shippingInfo.orderID) {
            dispatch(savePurchase(userInfo.token))
            dispatch(clearCart())
        }
    }, [dispatch, userInfo.token, shippingInfo])
    
    return (
        <div className="payment-message-page">
            {
                shippingInfo.orderID ? 
                <div>
                    
                    {
                    shippingInfo.orderID === "Recoger_en_tienda" ?
                    <h1>Tu pedido está listo para ser recogido en tienda.</h1>
                    :
                    <h1>¡Tu pago ha sido exitoso!</h1>
                    }
                
                    <h2>
                        Te hemos mandado un correo de confirmacion de pedido
                        a {userInfo.email}
                    </h2>
                    <Link to="/" className="primary1">
                        Volver a la página principal
                    </Link>
                </div>
                :
                <div>
                    <h1>Esta página no se encuentra disponible</h1>
                    
                    <Link to="/" className="primary1">
                        Volver a la página principal
                    </Link>
                </div>
            }
        </div>
    )
}

export default SuccessfulPayment
