import React from 'react'

import './NotFound.css'

const NotFound = () => {
    return (
        <div className="not-found-container">
            <h1>Esta página no se ha encontrado.</h1>
            <img src="img/logo.png" alt="logo farmacias dr vertiz." />
        </div>
    )
}

export default NotFound
