import { createStore, combineReducers, applyMiddleware, compose } from 'redux';

import thunk from 'redux-thunk'
import Cookie from 'js-cookie';

import { 
    cartReducer
} from './reducers/cartReducers';

import { 
    offersListReducer,
    suggestedListReducer, 
    resultsListReducer
} from './reducers/productReducers';

import { 
    userLoginReducer, 
    userSignupReducer,
    userResetPasswordReducer,
    userChangePasswordReducer,
    userIsVerifiedReducer,
    userResendVerificationEmailReducer,
    userPurchasesReducer
} from './reducers/userReducers';

import {
    adminSignupReducer,
    adminLoginReducer,
    adminPurchasesReducer,
    adminChangePurchaseReducer,
    adminCreateProductReducer,
    adminEditProductReducer,
    adminCreateDiscountCodeReducer,
    adminGetDiscountCodeReducer,
    adminChangeDiscountCodeStatusReducer,
    adminOffersReducer,
} from './reducers/adminReducers';

import { 
    discountCodeReducer, 
    saveShippingInfoReducer ,
} from './reducers/orderReducers';

// Cookie.remove("cartItems")
// Cookie.remove("userInfo")
// Cookie.remove("adminInfo")
const cartItems = Cookie.getJSON("cartItems") || []
const userInfo = Cookie.getJSON("userInfo") || null
const adminInfo = Cookie.getJSON("adminInfo") || null


const reducer = combineReducers({
    offersList: offersListReducer,
    suggestedList: suggestedListReducer,
    resultsList: resultsListReducer,
    
    cart: cartReducer,
    
    userLogin: userLoginReducer,
    userSignup: userSignupReducer,
    userResetPassword: userResetPasswordReducer,
    userChangePassword: userChangePasswordReducer,
    userIsVerified: userIsVerifiedReducer,
    userResendVerificationEmail: userResendVerificationEmailReducer,
    userPurchases: userPurchasesReducer,

    adminSignup: adminSignupReducer,
    adminLogin: adminLoginReducer,
    adminPurchases: adminPurchasesReducer,
    adminChangePurchase: adminChangePurchaseReducer,
    adminCreateProduct: adminCreateProductReducer,
    adminEditProduct: adminEditProductReducer,
    
    adminCreateDiscountCode: adminCreateDiscountCodeReducer,
    adminDiscountCodes: adminGetDiscountCodeReducer,
    adminChangeDiscountCode: adminChangeDiscountCodeStatusReducer,
    adminOffers: adminOffersReducer,
    
    discountCode: discountCodeReducer,
    shipping: saveShippingInfoReducer,

})

const initialState = { 
    cart: { cartItems }, 
    userLogin: { userInfo },
    adminLogin: { adminInfo },
    resultsList: { productsResults: [] }
}

const composeEnhancer = compose;
const store = createStore(
    reducer, 
    initialState,
    composeEnhancer(applyMiddleware(thunk))   
) 
//window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
export default store