import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'

import './WhatsAppChat.css'

const WhatsAppChat = () => {
  return (
    <>
        <a 
          className="chat-wa" 
          target='_blank'
          href="https://wa.me/525534340811"
          rel="noreferrer"  
        >
            <div className="chat-wa__icon">
                <p>
                <FontAwesomeIcon icon={faWhatsapp} />
                </p>
            </div>
            <div className="chat-wa__text">
                <p> No encuentras algo? </p>
                <p> Chatea con nosotros! </p>
            </div>
            
        </a>
    </>
  )
}

export default WhatsAppChat