import React from 'react'

const RowTable = ({ values }) => {
    return (
          <tr>
            {
                values.map((value, i) => {
                    return(
                        <td key={i}>{value}</td>
                    )
                })
            }
          </tr>  
    )
}

export default RowTable
