import React from 'react'
import { Link } from 'react-router-dom'
import CategoryDropdown from './CategoryDropdown'



import './CategoriesMenu.css'


/*

New Category: "Otros"
with subcategories:
    - Higiene Intima
    - Productos Covid
    - Higiene Bucal
    - Cuidado personal y belleza
    - Bebes e incontinencia
    - Helados
*/

const CategoriesMenu = () => {

    return (
        <div className="categories-menu-container">
            <ul className="menu-list">
                <li>
                    <Link to="/results_category/ampolletas" className="category-button" >
                        Ampolletas
                    </Link>
                </li>
                <li>
                    <Link to="/results_category/cremas" className="category-button" >
                        Cremas
                    </Link>
                </li>
                <li>
                    <Link to="/results_category/genericos" className="category-button" >
                        Genericos
                    </Link>
                </li>
                <li>
                <Link to="/results_category/gotas" className="category-button" >
                        Gotas
                    </Link>
                </li>
                <li>
                <Link to="/results_category/jarabes" className="category-button" >
                        Jarabes
                    </Link>
                </li>
                <li>
                <Link to="/results_category/tabletas" className="category-button" >
                        Tabletas
                    </Link>
                </li>
                <li>
                    <CategoryDropdown/>
                    
                    {/* <Link to="" className="category-button">
                        Otros
                    </Link> */}
                </li>
            </ul>
        </div>
    )
}

export default CategoriesMenu


/**
 <li>
                    <DropdownMenu  
                        classesForA={"category-button"} 
                        title={"Ampolletas"} 
                        animationClass={"category-dropdown-animation"}
                        dropdownClass={"dropdown-category"}
                    >   
                        <div className="column-category" >

                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Multivitamínico"} 
                            to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antibacteriano"} 
                            to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antiinfeccioso"} 
                            to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antinflamatorio"} 
                            to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Analgésico"} 
                            to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antidiabético"} 
                            to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Probióticos"} 
                            to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Anticonceptivo"} 
                            to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Corticoide"} 
                            to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Analgésico. Antinflamatorio"} 
                            to={""} />
                        </div>
                        <div className="column-category" >

                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Relajante muscular"} 
                            to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Hormona androgénica"} 
                            to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Nauseas"} 
                            to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Nebulizador"} 
                            to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antiinflamatorio"} 
                            to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Anticoagulante"} 
                            to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antihemorrágicos"} 
                            to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Tratamiento del aborto"} 
                            to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Expectorante"} 
                            to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Glucocorticoide"} 
                            to={""} />
                        </div>
                        <div className="column-category">
                            
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Ciática"} 
                            to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Vitamina A y D"} 
                            to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Sindrome climatérico"} 
                            to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Enfermedad pulmonar"} 
                            to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Nervio ciático"} 
                            to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antiespasmódico"} 
                            to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Infecciones bacterianas"} 
                            to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antibiótico infecciones respiratorias"} 
                            to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Infecciones vías respiratorias"} 
                            to={""} />
                        </div>
                    </DropdownMenu> 
                </li>
                <li>
                    <DropdownMenu  
                        classesForA={"category-button"} 
                        title={"Cremas"} 
                        animationClass={"category-dropdown-animation"}
                        dropdownClass={"dropdown-category"}
                    >   
                        
                        <div className="column-category">
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Cicatrizante"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antimicótico"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Rozaduras"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antiinflamatorio"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Protección dental"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antifúngico"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Analgésico"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Analgésico muscular"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Adhesivo"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Alergias y pruriginosos"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antibacteriano"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Dermatitis"}
                                to={""} />
                        </div>
                        <div className="column-category">
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antiviral"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antihemorroidal"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Micosis"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Crema Humectante"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Lubricante ocular"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Dolor articular y muscular"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antiprurítico"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Pie de atleta"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Onicomicosis"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Infección ocular"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antimicrobiano"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Sarna"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Dermatosis"}
                                to={""} />
                        </div>
                        <div className="column-category">
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Dientes sensibles"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Aftas bucales"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Despigmentante"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Reemplazo hormonal"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Infecciones dermatofitos"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Vulvitis"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Lubricante vaginal"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Higiene bucodental"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Lupus"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Acné"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Picadura de insecto"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Infecciones cutáneas"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Humectante vaginal"}
                                to={""} />
                        </div>
                        
                    </DropdownMenu>
                </li>
                <li>
                    <DropdownMenu  
                        classesForA={"category-button"} 
                        title={"Genericos"} 
                        animationClass={"category-dropdown-animation"}
                        dropdownClass={"dropdown-category"}
                    >
                        <div className="column-category">

                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Analgésico"}
                                to=""/>
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antibiótico"}
                                to=""/>
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Presión arterial"}
                                to=""/>
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Tos"}
                                to=""/>
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antinflamatorio"}
                                to=""/>
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Multivitamínico"}
                                to=""/>
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Hipertensión"}
                                to=""/>
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antidepresivo"}
                                to=""/>
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antifúngico"}
                                to=""/>
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antidiarreico"}
                                to=""/>
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Desinflamatorio ocular"}
                                to=""/>
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Artritis reumatoide"}
                                to=""/>
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Trigliceridos"}
                                to=""/>
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antibacteriano"}
                                to=""/>
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Dolor"}
                                to=""/>
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Dolor neuropático"}
                                to=""/>
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Diabetes"}
                                to=""/>
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Prueba de embarazo"}
                                to=""/>
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Bactericida"}
                                to=""/>
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Hiperplasia benigna prostática"}
                                to=""/>
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Enfermedad pulmonar"}
                                to=""/>
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antiespasmódico"}
                                to=""/>
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Infección ocular"}
                                to=""/>
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Amebiasis"}
                                to=""/>
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Corticosteroide"}
                                to=""/>
                                <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Rinitis"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Hipertrofia prostática benigna"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Ulcera péptica"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Laxante"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Perdida de peso"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antidiabético"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Diurètico"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Transtorno ácido péptico"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antipiléptico"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Irritación ocular"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Epilepsia"}
                                to="" />
                        </div>
                        <div className="column-category">
                            
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Esquizofrenia"}
                                to="" />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Reflujo gastroesofágico"}
                                to="" />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antihistamínico"}
                                to="" />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Tracto urinario"}
                                to="" />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antiviral"}
                                to="" />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Enfermedades endocrinas"}
                                to="" />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Anestésico"}
                                to="" />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Alergias"}
                                to="" />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Circulación"}
                                to="" />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Infección urinaria"}
                                to="" />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antispicótico"}
                                to="" />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Hipoglucemiante"}
                                to="" />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Acidez estomacal"}
                                to="" />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Disfunción erectil"}
                                to="" />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Sistema respiratorio"}
                                to="" />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antiparasitario"}
                                to="" />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antiseptico"}
                                to="" />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Hiperaldosteronismo"}
                                to="" />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Lubricante ocular"}
                                to="" />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Nauseas vomito"}
                                to="" />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Espasmolítico"}
                                to="" />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antinfeccioso"}
                                to="" />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Tratamiento gota artrítica"}
                                to="" />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Dientes sensibles"}
                                to="" />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antibiótico ocular"}
                                to="" />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Presión ocular"}
                                to="" />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Analgesico"}
                                to="" />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Descongestionante nazal"}
                                to="" />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Vasoprotector"}
                                to="" />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Úlcera peptica"}
                                to="" />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Malestar vías urinarias"}
                                to="" />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Anticonceptivo"}
                                to="" />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antipilèptico"}
                                to="" />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Arttritis"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Infecciones respiratorias"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Dermatitis"}
                                to={""} />
                        </div>
                        <div className="column-category">
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Influenza"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Analgésico muscular"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antimicrobiano"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Úlceras"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Úlcera gastrica"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Padecimiento gastrointestinal"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Transito digestivo"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Estriñimiento"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antioxidante"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Control de peso"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Ácido graso"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Glucocorticoide"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Asma bronquial"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Reducción de trigliceridos"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Disminución de colesterol"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Restauración gastrointestinal"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antiplaquetario"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Diurético"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Sistema nervioso"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Mareo y vómito"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antimicótico"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antinflamatorio y antinfeccioso"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Anticonvulsivo"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Tratcto alimentario y metabolismo"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Sistema cardiovascular"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Anticoagulante"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Retención de liquidos"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Hipertensión arterial"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Secreción nazal"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antirreumático"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Vomito y mareo"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Piojos y Covid 19"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Alegias"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antialérgico"}
                                to={""} />
                            
                            
                        </div>
                    </DropdownMenu>
                </li>
                <li>
                    <DropdownMenu  
                        classesForA={"category-button"} 
                        title={"Gotas"} 
                        animationClass={"category-dropdown-animation"}
                        dropdownClass={"dropdown-category"}
                    >
                        <div className="column-category">
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Glaucoma"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Irritación ocular"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Humectante nasal"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Sistema respiratorio"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Lubricante ocular"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Infección ocular"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Rinitis"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Alergias"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Congestion nasal"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antiglaucomatoso y miótico"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antinfeccioso"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Lagrima artificial"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Presión intraocular"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antigripal"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Descongestivo"}
                                to={""} />
                        </div>
                        <div className="column-category">
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Anestesia tópica oftálmica"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antiviral"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Desmaquillante"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Padecimientos gastrointestinales"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Infección conducto auditivo"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Enfermedad pulmonares"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Resequedad nasal"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Infecciones respiratorias"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Reflujo gastroesofágico"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Resequedad"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antiséptico nasal"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Estriñimiento"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Corticoide"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Terapia cardiaca"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Bronquitis"}
                                to={""} />

                        </div>
                        <div className="column-category">
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Enfermedad pulmonar"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antinflamatotio"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Corticosteroide"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Padecimientos de intestino"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Enrojecimiento ocular"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Tratamiento dispepsia"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Analgésico"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Infecciones bacterianas"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Inflamación ocular"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antihistamínico"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Resequedad y ardor"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Bolsas y ojeras"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Limpieza de ojos"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Otitis"}
                                to={""} />

                        </div>
                    </DropdownMenu>
                </li>
                <li>
                    <DropdownMenu  
                        classesForA={"category-button"} 
                        title={"Jarabes"} 
                        animationClass={"category-dropdown-animation"}
                        dropdownClass={"dropdown-category"}
                    >
                        <div className="column-category">

                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Sistema respiratorio"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antiséptico"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antiinfeccioso"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antidiarreico"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Laxante"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antimicótico"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Acidez"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antiácido"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antiparasitario"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Alergias"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Suplemento vitamínico"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Estreñimiento"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Tratamiento para piojos"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Lubricante ocular"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Tos"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Analgésico"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Vías Respiratorias"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Pie de atleta"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Cicatrizante"}
                                to={""} />
                        </div>
                        <div className="column-category">

                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antirreumático"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Probióticos"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Lactobacillus"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Úlcera péptica"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antiinflamatorio"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antiverrugas"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Pasta dental"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Nauseas. vómitos"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Baño coloidal"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Dermatitis seborreica"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Evacuante"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Bifidobacterias"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Bactericida"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Dolor"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antiviral"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Higiene íntima"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Asma"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antibacteriano"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Actividad sexual"}
                                to={""} />
                        </div>
                        <div className="column-category">

                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Desenterrador de uñas"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Indigestión"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Herpes"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Infecciones respiratorias"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Multivitamínico"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antimicrobiano"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antigripal"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antihistamínico"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Anestésico"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Suplemento"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Prótesis dental"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antifúngico"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Gastritis"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Limpieza intestinal"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Anestésico de uso tópico"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Piojos"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Suplemento alimenticio"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Inmunodeficiencia"}
                            to={""} />
                        
                        </div>
                    </DropdownMenu>
                </li>
                <li>
                    <DropdownMenu  
                        classesForA={"category-button"} 
                        title={"Tabletas"} 
                        animationClass={"category-dropdown-animation"}
                        dropdownClass={"dropdown-category"}
                    >
                        <div className="column-category">

                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Analgésico"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antigripal"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antibiótico"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antiinflamatorio"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Presión arterial"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Tos"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Multivitamínico"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Alergias"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antidiarreico"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Bactericida"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antibacteriano"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Disfunción eréctil"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antiinfeccioso"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Ulcera péptica"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Sistema nervioso"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antiparasitario"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antiácido"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antihistamínico"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antidepresivo"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Anticonceptivo"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Tos y resfriado"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Vitamínico"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Hipertensión arterial"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Disfunción tiroides"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Tracto alimentario y metabolismo"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antiviral"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Angina de pecho"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Relajante muscular"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Infecciones respiratorias"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antiepiléptico"}
                                to={""} />
                             <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Reflujo"}
                            to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Hemorroides"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Infecciones bacterianas"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Osteoartritis"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Insuficiencia hepática"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Colitis ulcerosa"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Síndrome demencial"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Vitamina D3"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Reemplazo hormonal"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Sistema musculo-esquelético"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Migraña"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antiplaquetario"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Lactobacillus"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Psicolépticos"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Inflamación y dolor"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Reflujo y ulcera péptica"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Control de glucemia"}
                                to={""} />

                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Sistema nervioso periférico"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Colón irritable"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antirreumático"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Patología cerebral"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Síndrome colón irritable"}
                            to={""} />

                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antidiabéticos"}
                            to={""} />

                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Profilaxis de la migraña"}
                            to={""} />
                        </div>
                        <div className="column-category">
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Sistema respiratorio"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Vitaminas"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antidiabetico"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Hipertensión"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Anti infeccioso"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Úlcera péptica"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Colesterol elevador"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Control de niveles de azúcar"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Diurético"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Sistema cardiovascular"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Inflamación de garganta"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Vómito"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Cólicos menstruales"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Suplemento alimenticio"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Vasoprotector"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Artrítico"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Laxante"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Corticoide"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antimicótico"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Síndrome varicoso"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Relajante"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Broncodilatador"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Hormona tiroidea"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Probióticos"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Ácido úrico"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Gastritis"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Espasmos"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Hipertiroidismo"}
                            to={""} />

                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Fiebre y dolor"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Diarreico"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Anemia en embarazo"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Betabloqueantes"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antiespasmódico"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Infecciones urinarias"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antimigrañoso"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Acidez"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Digestivo"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Vitamina E"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Modificador de lípidos"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Digestión"}
                            to={""} />
                        
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Infecciones respiratorias y urinarias"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Polineuropatía diabética"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Colitis"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Sensación sexual"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Anticoagulante"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Parásitos intestinales"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Anemia"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Progesterona natural"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Diabetes"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Infecciones genitales"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Deterioro intelectual"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Estabilizadores capilares"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Incontinencia"}
                                to={""} />
                            <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Dolor e inflamación"}
                            to={""} />
                        </div>
                        <div className="column-category">

                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antiparkinsoniano"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Presión arterial alta"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Reflujo gastroesofágico"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Padecimientos gastrointestinales"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Amibiasis intestinal"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Varices"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antiséptico intestinal"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Osteoporosis"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Inhibidor de angiotensina"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Demencia degenerativa"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Función hepática"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Regulador capilar"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Trastornos gastrointestinales"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antihemorrágicos"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Circulación de venas"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Enfermedades óseas"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Úlcera gástrica"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Suplemento nutricional"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Limpiador de protesis dentales"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Vejiga hiperactiva"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antivertiginoso"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Infecciones vaginales"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Infecciones estomacales"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Ansiolítico"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Infecciones gastrointestinales"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Ulcera gástrica"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Rinitis alérgica"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Reducción de colesterol"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Inflamación de esófago"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Trastornos digestivos"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Enzimas pancreáticos"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Espasmolítico"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Neuralgias"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Ansiedad"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Epilepsia"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antihipertensivo"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Profilaxis y asma"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Restaurador intestinal"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Síndrome de intestino irritable"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Síndrome intestino irritable"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antiinflamatorio. Antirreumático"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Tratamiento contra hemorragias"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Disminución de grasas"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Dilatador de vasos sanguíneos"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Reducción de mortalidad por infarto"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Fatiga crónica"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Ulcera péptica y reflujo"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Tratamiento para acidez"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Lesiones en el hígado"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Analgésico antiespasmódico"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Suplementos y minerales"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Hipoglucemiante"}
                            to={""} />
                        <DropdownItem dropdownItemClass={"category-dropdown-item"} link={true} text={"Antifúngicos"}
                            to={""} />
                        </div>
                    </DropdownMenu>
                </li>
 */
