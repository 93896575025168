import React from 'react'

import { CSSTransition } from 'react-transition-group'

import './DropdownMenu.css'



function DropdownMenu(props) {
    const { open, setOpen } = props

    return (
        <>  
            {
                props.button ?
                <button className={props.classesForA}  onClick={() => setOpen(!open)}>
                    {props.title}
                </button>
                :
                <button href="#" className={props.classesForA}  onClick={() => setOpen(!open)}>
                    {props.title}
                </button>
            }
            
            
            <CSSTransition 
                in={open} 
                timeout={500} 
                classNames={props.animationClass}
                unmountOnExit
            >
                <div className={props.dropdownClass}>
                    {props.children}
                </div>
            </CSSTransition>
            
        </>
    
    )
}


export default DropdownMenu
