import React from 'react'

import './CheckoutSteps.css'


function CheckoutSteps(props) {
    return (
        <div className="checkout-steps">
            <div className={props.step1 ? 'active' : ''}>
                Iniciar sesión
            </div>
            <div className={props.step2 ? 'active' : ''}>
                Envío
            </div>
            <div className={props.step3 ? 'active' : ''}>
                Pago
            </div>
        </div>
    )
}

export default CheckoutSteps
