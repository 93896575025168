import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'


import BillingForm from './BillingForm'
import Paypal from './Paypal'

import './PaymentForm.css'


const PaymentForm = ({ cartItems }) => {
    const [ billingDetails, setBillingDetailsTo ] = useState(false)
    
    const history = useHistory();

    const handleBackToShipping = () => {
        history.push("/shipping")
    }

    return (
        <div className="select-payment-method">
            <div className="billing">
                <button className="primary2" onClick={() => setBillingDetailsTo(!billingDetails)}>
                    Necesitas factura?
                </button>
               
                {
                billingDetails && <BillingForm />
                }
            </div>
            <div className="card">
                <Paypal cartItems={cartItems} />
            </div>
            <button onClick={handleBackToShipping} className="button basic ">
                Volver
            </button>
                
        </div>
    )
}

export default PaymentForm