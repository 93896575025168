import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { saveShippingInfo } from '../actions/orderActions';
import { zipCodeAvailable } from '../utils/zip_code_available';

import './ShippingForm.css'

const ShippingForm = (props) => {

    const [name, setName] = useState("");
    const [lastname, setLastname] = useState('');
    const [street, setStreet] = useState('');
    const [streetNumber, setStreetNumber] = useState('');
    const [neighborhoud, setNeighborhoud] = useState('');
    const [references, setReferences] = useState('');
    const [zipcode, setZipcode] = useState('');
    const [phone, setPhone] = useState('');
    const [ isZipCodeValid, setZipcodeValidTo ] = useState(true)

    const { isValid, code, discount } = useSelector(state => state.discountCode)
    
    const dispatch = useDispatch();
    const history = useHistory();

    const zipCodeValidation = (value) => {
        setZipcode(value)
        if (value.length >= 5) {
          const zipFound = zipCodeAvailable.find(zip => zip === value)
          zipFound ? setZipcodeValidTo(true) : setZipcodeValidTo(false)
        } else {
          setZipcodeValidTo(true)
        }
    }

    const handleContinuePayment = (e) => {
        e.preventDefault()
        if (props.shippingAvailable && isZipCodeValid) {
            dispatch(saveShippingInfo(
                name, lastname, street, streetNumber, neighborhoud, zipcode, 
                phone, references, isValid ? code : "", isValid ? discount : 0
            ))
            history.push('/payment')
        } else {
            dispatch(saveShippingInfo(
                name, lastname, "Recoger en tienda - Cumbres de maltrata ", "398 Local A",  "Narvarte", "03020", 
                phone, references, isValid ? code : "", isValid ? discount : 0
            ))
            history.push('/payment')  
        }
    }
    return (
        <div className="shipping-form-container">
            <div className="shipping-headers">
                <h2>Datos de envío</h2>
                {
                    !isZipCodeValid && 
                    <h3 className="error-message">
                        Tu código postal no es valido, no se encuentra dentro de la región de envío, da click en recoger en tienda
                    </h3>
                }
                
                {
                    isZipCodeValid && props.shippingAvailable &&
                    <>
                        <h3>
                            *Por lo pronto solo realizamos envíos dentro de
                            Benito Juárez, Ciudad de México
                        </h3>
                        <h3>
                            Tu pedido será entregado en breve. Gracias !
                        </h3>
                    </>
                }
                {
                    !props.shippingAvailable &&
                    <h3 className="error-message">
                        Tu pedido no califica para envío, 
                        puede registrar el pedido y hacer el pago en línea para recoger en tienda. 
                    </h3>
                }
            </div>
            <div className="form-div">

                <form onSubmit={handleContinuePayment} className="f">
                    <ul className="form-list">
                        <li>
                            <label htmlFor="name">
                                Nombre
                            </label>
                            <input 
                                value={name} 
                                type="name" 
                                name="name" 
                                id="name" 
                                onChange={(e) => setName(e.target.value)}
                                required
                                placeholder="John"
                            />
                        </li>
                        <li>
                            <label htmlFor="lastname">
                                Apellido
                            </label>
                            <input 
                                value={lastname} 
                                type="lastname" 
                                name="lastname" 
                                id="lastname"
                                placeholder="Doe"
                                onChange={(e) => setLastname(e.target.value)} 
                                required
                            />
                        </li>
                        {
                            props.shippingAvailable &&
                            <>
                                <li>
                                    <ul className="calle-list">
                                        <li>
                                            <label htmlFor="street">Nombre de la calle</label>
                                            <input 
                                                value={street} 
                                                type="street" id="street" 
                                                name="street" 
                                                placeholder="Calle"
                                                
                                                onChange={(e) => setStreet(e.target.value)} 
                                                required
                                            />
                                        </li>
                                        <li>
                                            <label htmlFor="street">Número </label>
                                            <input 
                                                value={streetNumber} 
                                                type="streetNumber" id="streetNumber" 
                                                name="streetNumber" 
                                                
                                                placeholder="10"
                                                onChange={(e) => setStreetNumber(e.target.value)} 
                                                required
                                            />
                                        </li>
                                    </ul>
                                </li>

                                <li>
                                    <label htmlFor="neighborhoud">Colonia</label>
                                    <input 
                                        value={neighborhoud} 
                                        type="neighborhoud" 
                                        id="neighborhoud" 
                                        name="neighborhoud"
                                        placeholder="Colonia Narvarte"
                                        onChange={(e) => setNeighborhoud(e.target.value)} 
                                        required
                                    />
                                </li>
                                <li>
                                    <label htmlFor="references">Dejanos alguna referencia de dónde dejar tu pedido</label>
                                    <input 
                                        value={references} 
                                        type="references" 
                                        id="references" 
                                        name="references" 
                                        placeholder="Dejar en la recepción del edificio ..."
                                        onChange={(e) => setReferences(e.target.value)} 
                                    />
                                </li>
                                <li>
                                    <label htmlFor="zipcode">Código postal</label>
                                    <input 
                                        value={zipcode} 
                                        type="text" id="zipcode" 
                                        name="zipcode" 
                                        pattern="[0-9]{5}" 
                                        placeholder="12345"
                                        onChange={(e) => zipCodeValidation(e.target.value)} 
                                        required
                                    />
                                </li>
                            </>
                        }
                        
                        <li>
                            <label htmlFor="phone">Teléfono (10 dígitos)</label>
                            <input 
                                value={phone} 
                                type="text" 
                                id="phone" 
                                name="phone" 
                                onChange={(e) => setPhone(e.target.value)} 
                                required 
                                placeholder="1234567890" 
                                pattern="[0-9]{10}"
                            />
                        </li>
                        <li>
                        {
                            !isZipCodeValid ? 
                            <>
                                <h5 className="error-message">
                                    Tu código postal no es valido, no se encuentra dentro de la región de envío, da click en recoger en tienda
                                </h5>
                                <button type="submit" className="button primary1 inline">
                                    Recoger en tienda
                                </button>
                            </> :
                            <button type="submit" className="button primary2 inline">
                                Continuar con el pago
                            </button>
                        }
                            
                        </li>
                    </ul>
                    
                </form>
            </div>
        </div>
    )
}

export default ShippingForm
