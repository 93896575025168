import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTruck, faDollarSign, faShieldAlt } from '@fortawesome/free-solid-svg-icons'

import './CustomerRelations.css'

const CustomerRelations = () => {
    return (
        <div className="customer-relations">  
            
            <div className="customer-offerings">
                
                <ul>
                    <li>
                        <div className="inline-container icon_cr">
                            <FontAwesomeIcon icon={faTruck} />
                        </div>
                        <div className="inline-container text_cr">
                            <p> Entregas solo en Benito Juárez, CDMX </p>
                        </div>
                    </li>

                    <li>
                        <div className="inline-container icon_cr">
                            <FontAwesomeIcon icon={faDollarSign} />
                        </div>
                        <div className="inline-container text_cr" >
                            <p> Pago con Tarjeta de débito o crédito </p>
                        </div>
                    </li>
                    
                    <li>
                    <div className="inline-container icon_cr">
                        <FontAwesomeIcon icon={faShieldAlt} /> 
                    </div>
                    <div className="inline-container text_cr">
                        <p> Compras 100% SEGURAS </p>
                    </div>
                    </li>

                </ul>
            </div>
        </div>
    )
}

export default CustomerRelations
