import React  from 'react'


import './Checkbox.css'

const Checkbox = (props) => {
    const { handleCheckboxChange, label, isChecked } = props;
    // const [ isChecked, setCheckedChange ] = useState(false)
    
    const toggleCheckboxChange = () => {
        // setCheckedChange(!isChecked)
        handleCheckboxChange(props.field, props.value)
    }

    return (
        <div className="checkbox">
            <label>
                
                <input 
                    type="checkbox"
                    value={label && label}
                    checked={isChecked}
                    className="checkbox-input"
                    onChange={toggleCheckboxChange}    
                />
                {label && label}
            </label>
        </div>
    )
}

export default Checkbox
