import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'

import { changePurchaseStatus, getPurchasesAdmin } from '../../actions/adminActions'
import ClipLoader from 'react-spinners/ClipLoader' //https://www.npmjs.com/package/react-spinners
import Table from '../../components/Table'
import { getStatus } from '../../utils/getStatus'

const columnsTable = [
    "ID", "Fecha y hora", "Artículos", "Total", "Factura", "Cambiar estado", "Estado", "Detalles"
]

const Pedidos = () => {

    const admin = useSelector(state => state.adminLogin )
    const { adminInfo } = admin;

    const adminPurch = useSelector(state => state.adminPurchases)
    const { loading, unactivePurchases, activePurchases, error } = adminPurch

    const changePurch = useSelector(state => state.adminChangePurchase)
    const loadingChangePurch = changePurch.loading
    const purchaseStatusChanged = changePurch.purchaseStatusChanged

    const dispatch = useDispatch()
    let history = useHistory();

    useEffect(() => {
        if (document.querySelector(".sidebar")){
            document.querySelector(".sidebar").classList.remove("open")
        }
    }, [])
    
    useEffect(() => {
        if (adminInfo) {
            dispatch(getPurchasesAdmin(adminInfo.token))
        } else {
            history.push("/loginAdmin")
        }
    }, [purchaseStatusChanged, history, adminInfo, dispatch])

    const handleStatusChange = id => e => {
        // console.log("Changing status");
        dispatch(changePurchaseStatus(adminInfo.token, id, e.target.value))
    }

    function selectStatus(status, id) {
        return (
            <select  onChange={handleStatusChange(id)} value={status}>
                <option value="1">Procesado</option>
                <option value="2">Enviado</option>
                <option value="3">Entregado</option>
                <option value="4">Cancelado</option>
            </select>
        )
    }

    function getReadableDate(date) {
        const date_ = new Date(date)
        const readable_date = (date_.getMonth() + 1) + '/' + date_.getDate() + '/' +  date_.getFullYear() + " - " + date_.getHours() + ":" + date_.getMinutes();
        return readable_date
    }

    function getLinkPurchase (id) {
        return (
            <Link to={"/dashboard/pedidos/" + id}
                 className="primary1"
            >
                Ver detalles
            </Link>
        )
    }
    function getTableRows (purchases) {
        const rows = purchases.map(purchase => {
            return([
                purchase.id,
                getReadableDate(purchase.created_at),
                purchase.purchase_details.length,
                `$ ${purchase.total}`,
                purchase.billing_detail ? "Si" : "No",
                selectStatus(purchase.status, purchase.id),
                getStatus(purchase.status),
                getLinkPurchase(purchase.id)
            ])

        })
        return rows
    }

    return (
        <div>
            <h1>Pedidos</h1>
            {
                loading && <h2>Obteniendo pedidos ... <ClipLoader/> </h2>
            }
            {
                error && <h2>Ocurrió un error al momento de obtener los pedidos, inténtelo más tarde</h2>
            }
            <div>
                <h2>
                    Lista de pedidos activos { loadingChangePurch ? <ClipLoader/>: ""}
                </h2>
                <div>
                {
                    activePurchases && 
                    <Table
                        className="active-purchases-table"
                        columnsHeader={columnsTable}
                        rows={getTableRows(activePurchases)}                                
                    />
                }
                    
                </div>
                <h2>
                    Lista de pedidos pasados { loadingChangePurch ? <ClipLoader/>: ""}
                </h2>
                <div>
                {
                    unactivePurchases && 
                    <Table
                        className="unactive-purchases-table"
                        columnsHeader={columnsTable}
                        rows={getTableRows(unactivePurchases)}
                    />
                }
                    
                </div>
            </div>
        </div>
    )
}

export default Pedidos
