export const filterByLetterRange = (arr, start, end) => {
    const isGreater = (c1, c2) => c1 >= c2;
    const isSmaller = (c1, c2) => c1 <= c2;
    const filtered = arr.filter(prod => {
        const name = prod.name
        const [firstChar] = name.toLowerCase();
        // console.log(firstChar);
        return isGreater(firstChar, start) && isSmaller(firstChar, end);
    });
    // console.log(filtered);
    return filtered;
}

export const filterByPriceRange = (arr, start, end) => {
    const isGreater = (c1, c2) => c1 >= c2;
    const isSmaller = (c1, c2) => c1 <= c2;
    const filtered = arr.filter(prod => {
        const price = prod.price
        return isGreater(price, start) && isSmaller(price, end);
    });
    // console.log(filtered);
    return filtered;
}

