import React from 'react'

import './Slide.css'

const Slide = (props) => {

  return (
    
    <div className={props.className} style={props.style} >
        {props.children}
    </div>
    
  )
}

export default Slide