import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { userChangePassword } from '../actions/userActions';

import ClipLoader from 'react-spinners/ClipLoader'

function ChangePassword(props) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [rePassword, setRePassword] = useState('');
    const [ errorPassword, setErrorPassword ] = useState(null);
    const [ formSubmitted, setFormSubmittedTo ] = useState(false);
    
    const changingPassword = useSelector(state => state.userChangePassword)
    const { loading, error, passwordChangedMessage} = changingPassword
    // console.log(passwordChangedMessage);
    const dispatch = useDispatch();
    
    const token = props.location.search?props.location.search.split("=")[1]:null
    
    const submitHandler = (e) => {
      e.preventDefault();
      if (password !== rePassword) {
        setErrorPassword("Las contraseñas no coinciden")
      } else if (password.length < 8){
        setErrorPassword("La longitud de la contraseña debe ser de almenos 8 caracteres")
      } else {
        setErrorPassword(null)
        setFormSubmittedTo(true)
        dispatch(userChangePassword(email, password, rePassword, token));
      }
    }
    
    return (
        <div className="changePassword-container">
            <div className="form-container">
                <form onSubmit={submitHandler} className='form'>
                    <ul className="form-list">
                        <li>
                        <h2>Cambiar Contraseña</h2>
                        </li>
                        <li>
                        {loading && <div><ClipLoader/></div>}
                        { passwordChangedMessage && <h4 className="win-message">{passwordChangedMessage + " Favor de iniciar sesión"}</h4>}
                        {error && <h4 className="error-message">{error}</h4>}
                        { errorPassword && <h4 className="error-message">{errorPassword}</h4> }

                        </li>
                        
                        <li>
                            <label htmlFor="email">Email</label>
                            <input type="email" name="email" id="email" onChange={(e) => setEmail(e.target.value)} />
                        </li>
                        <li>
                            <label htmlFor="password">Contraseña</label>
                            <input type="password" id="password" name="password" onChange={(e) => setPassword(e.target.value)}/>
                        </li>
                        <li>
                            <label htmlFor="rePassword">Confirmar contraseña</label>
                            <input type="password" id="rePassword" name="rePassword" onChange={(e) => setRePassword(e.target.value)}/>
                        </li>
                        <li>
                        {
                            formSubmitted ? 
                            <button type="submit" disabled className="button primary1">Enviar</button>:
                            <button type="submit" className="button primary1">Enviar</button>
                        }
                        </li>
                    

                    </ul>
                </form>
            </div>
        </div>
    )
}

export default ChangePassword
