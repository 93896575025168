function offersListReducer(state = { productsOffers: [] }, action) {
    switch (action.type) {
        case 'OFFERS_REQUEST':
            return {
                loadingOffers: true
            }
        case 'OFFERS_SUCCESS':
            return {
                loadingOffers: false,
                productsOffers: action.payload
            }
        case 'OFFERS_FAIL':
            return {
                loadingOffers: false, 
                errorOffers: action.payload
            }
        default:
            return state;
    }
}

function suggestedListReducer(state = { productsSuggested: [] }, action) {
    switch (action.type) {
        case 'SUGGESTED_REQUEST':
            return {
                loadingSuggested: true
            }
        case 'SUGGESTED_SUCCESS':
            return {
                loadingSuggested: false,
                productsSuggested: action.payload
            }
        case 'SUGGESTED_FAIL':
            return {
                loadingSuggested: false, 
                errorOffers: action.payload,
                productsSuggested: []
            }
        default:
            return state;
    }
}

function resultsListReducer(state = { productsResults: [] }, action) {
    switch (action.type) {
        case 'QUERY_REQUEST':
            return {
                loading: true
            }
        case 'QUERY_SUCCESS':
            return {
                loading: false,
                productsResults: action.payload[0],
                filteredResults: action.payload[0],
                categoriesAvailable: action.payload[1],
                subcategoriesAvailable: action.payload[2]
            }
        case 'QUERY_FAIL':
            return {
                loading: false,
                error: action.payload,
                productsResults: []
            }
        case 'GROUP_QUERY_REQUEST':
            return {
                loading: true
            }
        case 'GROUP_QUERY_SUCCESS':
            return {
                loading: false,
                productsResults: action.payload[0],
                filteredResults: action.payload[0],
                categoriesAvailable: action.payload[1],
                subcategoriesAvailable: action.payload[2]
            }
        case 'GROUP_QUERY_FAIL':
            return {
                loading: false,
                error: action.payload,
                productsResults: []
            }
        case 'FILTER_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case 'FILTER_SUCCESS':
            return {
                ...state,
                loading: false,
                filteredResults: action.payload
            }
        case 'CLEAN_QUERY_RESULTS':
            if (state.productsResults === []){
                return state;
            }
            return {
                ...state,
                productsResults: []
            }
        case 'CATEGORY_REQUEST':
            return {
                loading: true
            }
        case 'CATEGORY_SUCCESS':
            return {
                loading: false,
                productsResults: action.payload[0],
                filteredResults: action.payload[0],
                categoriesAvailable: action.payload[1],
                subcategoriesAvailable: action.payload[2]
            }
        case 'CATEGORY_FAIL':
            return {
                loading: false,
                error: action.payload
            }
        default:
            return state;
    }
}


export  { 
    offersListReducer,
    suggestedListReducer,
    resultsListReducer,
}