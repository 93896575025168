import React, {useState} from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { userSendLinkResetPassword } from '../actions/userActions'

import ClipLoader from 'react-spinners/ClipLoader' //https://www.npmjs.com/package/react-spinners

import './RestorePassword.css'

const RestorePassword = () => {
    const [email, setEmail] = useState('')

    const sendingRestoreLink = useSelector(state => state.userResetPassword)
    const { loading, linkSentMessage } = sendingRestoreLink
    
    const dispatch = useDispatch()
    // let history = useHistory();
    const handleResetPasswordSubmit = (e) => {
        e.preventDefault();
        dispatch(userSendLinkResetPassword(email))
    }

    return (
        <div className="restorePassw-container">
            <div className="form-container">

                <form onSubmit={handleResetPasswordSubmit} className="form">
                    <h1>Restablecer contraseña</h1>
                    <ul className="form-list">
                        <li>
                            <h4>Te mandaremos un link a tu correo electrónico para reestablecer tu contraseña</h4>
                        </li>
                        <li>

                            <label htmlFor="email">
                                ¿Cuál es el correo electrónico de tu cuenta?
                            </label>
                            <input 
                                required
                                type="email" 
                                name="email" 
                                onChange={(e) => setEmail(e.target.value)}    
                            />
                        </li>
                        <li>
                            
                            {
                                loading ?
                                

                                <ClipLoader  className="loader-spinner"/>
                                
                                :
                                <button type="submit" className="button primary1">
                                    Enviar
                                </button>
                            }
                        </li>
                        <li>
                            {
                                linkSentMessage && <h4>{linkSentMessage.message}</h4>
                            }
                        </li>
                    </ul>
                </form>
            </div>
        </div>
    )
}

export default RestorePassword
