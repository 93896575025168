export function getStatus(number) {
    switch (number) {
        case 1:
            return "Procesado"
        case 2:
            return "Enviado"
        case 3:
            return "Entregado"
        case 4:
            return "Cancelado"
        default: 
            return ""
    }
}

