import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom'



import { userPurchases } from '../actions/userActions';
import { getStatus } from '../utils/getStatus';

import Table from '../components/Table';
import { ClipLoader } from 'react-spinners';

const columnsTable = [
    "Fecha y hora", "Artículos", "Total", "Factura", "Status" ,"Detalles"
]

const Profile = (props) => {

    const { userInfo } = useSelector(state => state.userLogin);
    
    const dispatch = useDispatch()

    const { 
        loadingPurchases, 
        activePurchases, 
        unactivePurchases, 
        error 
    } = useSelector(state => state.userPurchases)
    
    // console.log(activePurchases);

    useEffect(() => {
        if (userInfo) {
            dispatch(userPurchases(userInfo.token))
        }
        else{
            props.history.push("/loginUser?redirect=account");
        }
      }, [dispatch, props.history, userInfo]);
    

    function getReadableDate(date) {
        const date_ = new Date(date)
        const readable_date = (date_.getMonth() + 1) + '/' + date_.getDate() + '/' +  date_.getFullYear() + " - " + date_.getHours() + ":" + date_.getMinutes();
        return readable_date
    }

    function getLinkPurchase (id) {
        return (
            <Link to={"/account/pedidos/" + id}
         className="primary1"
            >
                Ver detalles
            </Link>
        )
    }

    function getTableRows (purchases) {
        let rows = []
        purchases.map(purchase => {
            rows.push([
                getReadableDate(purchase.created_at),
                purchase.purchase_detail.length,
                purchase.total,
                purchase.billing_detail ? "Si" : "No",
                getStatus(purchase.status),
                getLinkPurchase(purchase.id)
            ])
            return 0
        })
        return rows
    }

    return (
        <div style={{"padding": "5rem"}}>
            {
                loadingPurchases && <h2>Obteniendo pedidos ... <ClipLoader/> </h2>
            }
            {
                error && <h2>Ocurrió un error al momento de obtener los pedidos, inténtelo más tarde</h2>
            }
            <h3>Pedidos activos</h3>
            <div className="active-purchases">
                {
                    activePurchases && 
                    <Table
                        className="user-active-purchases-table"
                        columnsHeader={columnsTable}
                        rows={getTableRows(activePurchases)}
                    />
                }
            </div>
            <h3>Pedidos pasados</h3>
            <div className="past-purchases">
                {
                    unactivePurchases &&
                    <Table
                        className="user-unactive-purchases-table"
                        columnsHeader={columnsTable}
                        rows={getTableRows(unactivePurchases)}
                    />

                }
            </div>
        </div>
    )
}

export default Profile
