import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'


import './Searcher.css'

function Searcher() {
    const [ query, setQuery ] = useState('')
 
    let history = useHistory();

    const handleSearchSubmit = (e) => {
        e.preventDefault()
        history.push("/results_query/"+query)
    }

    return (
        <div className="form-searcher">
            <form onSubmit={handleSearchSubmit}>
            
                <input 
                    type="text" 
                    placeholder="Buscar en el catálogo..."
                    className="search-input"
                    required
                    onChange={(e) => setQuery(e.target.value)}
                />
                <button type="submit" className="search-button">
                    <FontAwesomeIcon icon={faSearch} />
                </button>
            </form>
        </div>
    )
}

export default Searcher
