import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';


import { userLogout } from '../actions/userActions'
import { adminLogout } from '../actions/adminActions';
// import { getProductsByCategory } from '../actions/productActions'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faTimes, faChevronDown, faChevronUp  } from '@fortawesome/free-solid-svg-icons'

import './SideMenu.css'


const SideMenu = (props) => {
    
    const [ openCategory, setOpenCategory ] = useState(false)

    const user = useSelector(state => state.userLogin )
    const { userInfo } = user;

    const admin = useSelector(state => state.adminLogin )
    const { adminInfo } = admin;
    

    //const results = useSelector(state => state.resultsList)

    const dispatch = useDispatch()
    let history = useHistory();

    const handleLogoutUser = () => {
        dispatch(userLogout())
        props.onCloseMenu()
        history.push("/")
    }
    
    const handleLogoutAdmin = () => {
        dispatch(adminLogout())
        props.onCloseMenu()
        history.push("/")
    }

    
    
    
    return (
        <aside className="sidebar">
            <h3>
                <FontAwesomeIcon className="user-icon-sidebar" icon={faUser} /> 
                {
                    userInfo ? 
                    "Hola, " + userInfo.first_name 
                    : adminInfo ?
                    "Hola admin, " + adminInfo.first_name 
                    :
                    " Mi cuenta"
                } 
            </h3>
            {
                userInfo ?
                <ul>
                    <li> <button onClick={handleLogoutUser}>Cerrar sesión</button> </li>
                    <li> <Link onClick={props.onCloseMenu} to="/account">Ver mis pedidos</Link></li>
                </ul>
                :adminInfo ?
                <ul>
                    <li> <Link onClick={props.onCloseMenu} to="/dashboard"> Dashboard </Link> </li>
                    <li> <button  onClick={handleLogoutAdmin}>Cerrar sesión</button> </li>
                </ul>
                :
                <ul>
                    <li> <Link onClick={props.onCloseMenu} to="/loginUser">Iniciar sesión</Link> </li>
                    <li> <Link onClick={props.onCloseMenu} to="/signupUser">Registrarse</Link> </li>
                    <li> <Link onClick={props.onCloseMenu} to="/cart">Ver el carrito</Link></li>
                </ul>
            }
            
            {
                !adminInfo && <h3>Categorías</h3>
            }
            {
                adminInfo && <h3>Secciones</h3>
            }           
            <button className="sidebar-close-button" onClick={props.onCloseMenu}>
                <FontAwesomeIcon icon={faTimes} />
            </button>
            {
                !adminInfo &&
                <ul>
                    <li> <Link onClick={props.onCloseMenu} to="/results_category/cremas"> Cremas </Link> </li>
                    <li> <Link onClick={props.onCloseMenu} to="/results_category/genericos"> Genericos </Link> </li>
                    <li> <Link onClick={props.onCloseMenu} to="/results_category/gotas"> Gotas </Link> </li>
                    <li> <Link onClick={props.onCloseMenu} to="/results_category/jarabes"> Jarabes </Link> </li>
                    <li> <Link onClick={props.onCloseMenu} to="/results_category/tabletas"> Tabletas </Link> </li>
                    <li> <Link onClick={props.onCloseMenu} to="/results_category/ampolletas"> Ampolletas </Link> </li>
                    <li> 
                        <button onClick={() => setOpenCategory(!openCategory)}>
                            

                            {
                                openCategory ?
                                    <>
                                    Otros <FontAwesomeIcon icon={faChevronDown} />
                                    </>
                                    :
                                    <>
                                    Otros <FontAwesomeIcon icon={faChevronUp} />
                                    </>
                            }
                        </button> 
                    </li>
                    {
                        openCategory &&
                        <>
                            <li>   <Link onClick={props.onCloseMenu} to="/results_category/Abarrotes">Abarrotes</Link>  </li>
                            <li>   <Link onClick={props.onCloseMenu} to="/results_category/Bebes_e_incontinencia">Bebes e incontinencia</Link>  </li>
                            <li>   <Link onClick={props.onCloseMenu} to="/results_category/Cuidado_personal_y_belleza">Cuidado personal y belleza</Link>  </li>
                            <li>   <Link onClick={props.onCloseMenu} to="/results_category/Desodorantes">Desodorantes</Link>  </li>
                            <li>   <Link onClick={props.onCloseMenu} to="/results_category/Dulces_y_chocolates">Dulces y Chocolates</Link>  </li>
                            <li>   <Link onClick={props.onCloseMenu} to="/results_category/Galletas">Galletas</Link>  </li>
                            <li>   <Link onClick={props.onCloseMenu} to="/results_category/Helados">Helados</Link>  </li>
                            <li>   <Link onClick={props.onCloseMenu} to="/results_category/Higiene_bucal">Higiene bucal</Link>  </li>
                            <li>   <Link onClick={props.onCloseMenu} to="/results_category/Higiene_intima">Higiene íntima</Link>  </li>
                            <li>   <Link onClick={props.onCloseMenu} to="/results_category/Perfumeria">Perfumeria</Link>  </li>
                            <li>   <Link onClick={props.onCloseMenu} to="/results_category/Productos_covid">Productos Covid</Link>  </li>
                            <li>   <Link onClick={props.onCloseMenu} to="/results_category/Salud_sexual">Salud sexual</Link>  </li>
                            <li>   <Link onClick={props.onCloseMenu} to="/results_category/Suplementos_alimenticios">Suplementos alimenticios</Link>  </li>
                            <li>   <Link onClick={props.onCloseMenu} to="/results_category/Tintes">Tintes</Link>  </li>
                        </>
                    }
                </ul>
            }
            {
                adminInfo &&
                <ul>
                        
                    <li>
                        <Link to="/dashboard/pedidos" className="dashboard-link">
                            Pedidos
                        </Link>
                    </li>
                    <li>
                        <Link to="/dashboard/productos" className="dashboard-link">
                            Productos
                        </Link>
                    </li>
                    <li>
                        <Link to="/dashboard/codigosDescuento" className="dashboard-link">
                            Códigos de descuento
                        </Link>
                    </li>
                    <li>
                        <Link to="/dashboard/ofertas" className="dashboard-link">
                            Ofertas
                        </Link>
                    </li>
                    <li>
                        <Link to="/dashboard/administradores" className="dashboard-link">
                            Cuenta
                        </Link>
                    </li>
    
                </ul>
            }
            
        </aside>
    )
}

export default SideMenu
