import React, { useEffect, useRef } from 'react'


import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';

import { saveOrderIDPaypal } from '../actions/orderActions';

// https://developer.paypal.com/docs/get-started/

// sb-vtal436289451@business.example.com
// Fn61e=_d

// sb-jdirf6286405@personal.example.com
// ]|w<n]4V

// https://developer.paypal.com/docs/checkout/integration-features/standard-card-fields/

// const PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });


const Paypal = () => {

    const paypal = useRef()

    const { userInfo } = useSelector(state => state.userLogin )
    const { shippingInfo } = useSelector(state => state.shipping)

    console.log(userInfo);
    console.log(shippingInfo);
    
    let history = useHistory();
    const dispatch = useDispatch();
    
    // const description = "Compra realizada por: " + userInfo.first_name + " with email: " + userInfo.email + ", ver pedido completo en https://farmaciasdrvertiz.com"
    
    useEffect(() => {
      window.paypal.Buttons({
        createOrder: (data,actions,err) => {
          return actions.order.create({
            intent: "CAPTURE",
            purchase_units: [
              {
                description: "Compra realizada por: " + userInfo.first_name + " with email: " + userInfo.email + ", ver pedido completo en https://farmaciasdrvertiz.com",
                amount: {
                  value: shippingInfo.final_price,
                },
                shipping: {
                  address: {
                      given_name: userInfo.first_name,
                      surname: "",
                      address_line_1: shippingInfo.street,
                      address_line_2: shippingInfo.neighborhoud,
                      admin_area_2: 'Benito Juarez',
                      admin_area_1: 'Ciudad de México',
                      postal_code: shippingInfo.zipcode,
                      country_code: 'MX'
                  }
                },
                payer: {
                  name: {
                    given_name: shippingInfo.name,
                    surname: shippingInfo.lastname
                  },
                  address: {
                      given_name: "",
                      surname: "",
                      address_line_1: shippingInfo.street,
                      address_line_2: shippingInfo.neighborhoud,
                      admin_area_2: 'Benito Juarez',
                      admin_area_1: 'Ciudad de México',
                      postal_code: shippingInfo.zipcode,
                      country_code: 'MX'
                  },
                  email_address: userInfo.email,
                  phone: {
                    phone_type: "MOBILE",
                    phone_number: {
                      national_number: shippingInfo.phone
                    }
                  }
              },
              },
            ],
          })
        },
        onApprove: async (data, actions) => {
          // const order = await actions.order.capture()
          dispatch(saveOrderIDPaypal(data.orderID))
          // console.log(order);
          history.push("/successfulPayment")
        },
        onError: (err) => {
          console.log(err);
          history.push("/unsuccessfulPayment")
        }
      }).render(paypal.current)
    }, [])

    
    

    return (
      <div>
        <div ref={paypal}></div>
      </div>
    )
}

export default Paypal
