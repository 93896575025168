import Cookie from "js-cookie";
import axios from "axios"
import { api_url } from "../utils/api-route";

const baseUrl = api_url


export const addToCart = (product, qty) => (dispatch, getState) => {
    // console.log("product to add: ", product);
    dispatch({
        type: 'ADD_TO_CART',
        payload: {
            product: product,
            qty: parseInt(qty),
            
        }
    })
    const { cart: { cartItems } } = getState();
    Cookie.set("cartItems", JSON.stringify(cartItems))
}

export const removeFromCart = (product) => (dispatch, getState) => {
    dispatch({
        type: 'REMOVE_FROM_CART',
        payload: product,
    })
    const { cart: { cartItems } } = getState();
    Cookie.set("cartItems", JSON.stringify(cartItems))
}


export const refreshCart = (items_id) => async (dispatch, getState) => {
    dispatch({
        type: 'REFRESH_CART_REQUEST'
    })
    try {                
        const { data } = await axios.get(baseUrl + 'shoppingCart/refreshShoppingCart', {
            params: {
                "id_products": items_id
            }
        })
        
        const { cart: { cartItems } } = getState();
        let newItems = []
        cartItems.map(item => {
            const id = item.product.id
            const newItem = data.find(itemData => id === itemData.id)
            if (item.product.price !== newItem.price  && 
                item.product.inventory !== newItem.inventory) {
                let new_qty = item.qty;
                if (item.qty > newItem.inventory){
                    new_qty = newItem.inventory
                }
                item = {
                    product: {
                        ...item.product,
                        inventory: newItem.inventory,
                        price: newItem.price
                    },
                    qty: new_qty,
                    hasChanged: true
                }
                newItems.push(item)    
            } else {
                item = {
                    ...item,
                    hasChanged: false
                }
                newItems.push(item)
            }
            return []
        })
        dispatch({
            type: 'REFRESH_CART_SUCCESS',
            payload: newItems
        })

    } catch (error) {
        dispatch({
            type: 'REFRESH_CART_FAIL',
            payload: error
        })
    }
}

export const clearCart = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_CART_ITEMS'
    })
    // console.log("clear cart...");
    Cookie.remove("cartItems")
}

