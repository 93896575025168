import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'

import './global.css';

import App from './routes/App';

import store from './store';

// import "bootstrap/dist/css/bootstrap.min.css";


ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>
, document.getElementById('root'));
