import React, { useState } from 'react'
import Modal from './Modal'
import { useSelector } from 'react-redux';

import ClipLoader from 'react-spinners/ClipLoader' //https://www.npmjs.com/package/react-spinners
import editProduct from '../api_requests/edit_product';


const EditProductModal = (props) => {
    
    const currentProduct = props.product;

    const [ productName, setProductName ] = useState(currentProduct.name);
    const [ image, setImage ] = useState(null);



    const admin = useSelector(state => state.adminLogin )
    const { adminInfo } = admin;
    
    const [ editStatus, setEditStatus ] = useState(null)
    const [ editError, setEditError ] = useState(null)
    const [ editLoading, setEditLoading ] = useState(null)
    


    const fileChangeHandler = (e) => {
        setImage(e.target.files[0])
    }

    const submitChangesHandler = (e) => {
        e.preventDefault()

        setEditLoading(true);
        editProduct(
            currentProduct.id,
            productName,
            currentProduct.price,
            currentProduct.inventory,
            image,
            adminInfo.token,
            currentProduct.first_category,
            currentProduct.second_category,
        )
        .then((response) => {
            // console.log(response)
            setEditStatus(response.data.message)
            setEditError(null);
            setEditLoading(false);
        })
        .catch((err) => {
            console.log(err.response)
            setEditError(err.response.data)
            setEditStatus(null);
            setEditLoading(false);
        });

        
    }

    
    function getErrorMessages() {
        let errorMessages = [];
        for (const [key, value] of Object.entries(editError.message)) {
            value.map((m,i) => errorMessages.push(
                <h4 key={key + i} className="error-message">{m}</h4>
            ))
        }
        return errorMessages
    }

    
    return (
        <Modal isOpen={props.isOpen} onClose={props.onClose} className="Modal">
            <h2>Editando producto</h2>
            <div className="form-container">
                <form onSubmit={submitChangesHandler} className="form">
                    <ul className="form-list">
                        <li>
                            {  editError &&  getErrorMessages() } 
                            {  editStatus && <h4 className="win-message">{ editStatus }</h4>  }
                        </li>
                        <li>
                            <label htmlFor="nombre-producto">
                                Nombre del producto
                            </label>
                            <input 
                                type="text" 
                                name="nombre-producto" 
                                id="nombre-producto" 
                                required
                                value={productName}
                                onChange={(e) => setProductName(e.target.value)}    
                            />
                        </li>
                        <li>
                            <label htmlFor="price-product">
                                Precio
                            </label>
                            <input 
                                type="price-product" 
                                name="price-product" 
                                id="price-product" 
                                value={currentProduct.price}
                                readOnly  
                            />
                        </li>
                        
                        <li>
                            <label htmlFor="image-product">
                                Imagen
                            </label>
                            <input 
                                type="file" 
                                name="image-product" 
                                id="image-product" 
                                set={image}
                                onChange={fileChangeHandler}    
                            />
                        </li>
                        
                        <li>
                            {
                                editLoading?
                                <button type="button" className="button primary1">
                                    <ClipLoader />
                                </button>
                                :
                                <button type="submit" className="button primary1">
                                    Guardar cambios
                                </button>
                            }
                        </li>        
                    </ul>
                </form>
            </div>
        </Modal>
    )
}

export default EditProductModal
