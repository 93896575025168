function savePurchaseReducer (state = {}, action) {
    switch (action.type) {
        case 'SAVE_PURCHASE_REQUEST':
            return {
                loading: true,
            }
        case 'SAVE_PURCHASE_SUCCESS':
            return {
                loading: false,
                paymentIntent: action.payload
            }
        case 'SAVE_PURCHASE_FAIL':
            return {
                loading: false,
                error: action.payload
            }
        default:
            return state;
    }
}
function saveShippingInfoReducer (state={ shippingInfo: {} }, action) {
    switch (action.type) {
        case 'SAVE_SHIPPING_INFO':
            return {
                ...state,
                shippingInfo: action.payload
            }
        case 'SAVE_BILLING_DETAILS':
            return {
                ...state,
                shippingInfo: {
                    ...state.shippingInfo,
                    billing: action.payload
                }
            }
        case 'SAVE_ORDER_ID':
            return {
                ...state,
                shippingInfo: {
                    ...state.shippingInfo,
                    orderID: action.payload.orderID
                }
            }
        default:
            return state;
    }
}

function discountCodeReducer (state = {}, action) {
    switch (action.type) {
        case 'VALIDATE_CODE_REQUEST':
            return {
                loading: true,
            }
        case 'VALIDATE_CODE_SUCCESS':
            return {
                loading: false,
                isValid: action.payload.data.isValid,
                discount: parseFloat(action.payload.data.discount_percent),
                code: action.payload.code
            }
        case 'VALIDATE_CODE_FAIL':
            return {
                loading: false,
                isValid: false // cambiar cuando curi arregle lo del bug del badrequest 400 no debe de mandar datos
            }
        default:
            return state;
    }
}

export {
    savePurchaseReducer,
    discountCodeReducer,
    saveShippingInfoReducer,
}

