import React, { useState } from 'react'

import './FilterDropdown.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'


const FilterDropdown = ({ title, children }) => {
    const [ isOpen, setOpenTo ] = useState(true)

    return (
        <div className="ddf-wrapper">
            <div 
                className="ddf-header"
                role="button"
                onClick={() => setOpenTo(!isOpen)}
            >   
                <div className="ddf-header__title">
                    <h2>{title}</h2>
                </div>
                <div className="ddf-header__chevron">
                    <h2>
                        {
                            isOpen ?
                            <FontAwesomeIcon icon={faChevronUp} />
                            :
                            <FontAwesomeIcon icon={faChevronDown} />
                        }
                    </h2>
                </div>    
            </div>
            <div className="ddf-content">
                {isOpen && children}
            </div>
        </div>
    )
}

export default FilterDropdown
