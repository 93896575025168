import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { adminSignup } from '../actions/adminActions';

function SignupAdmin(props) {
    const [name, setName] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [rePassword, setRePassword] = useState('');
    const [ errorPassword, setErrorPassword ] = useState(null)
    const admin = useSelector(state => state.adminSignup);
    
    const { loading, admin_register_success, error } = admin;
    const dispatch = useDispatch();

    const admin_loggeado = useSelector(state => state.adminLogin )
    const { adminInfo } = admin_loggeado;

    useEffect(() => {
      if (admin_register_success) {
        props.history.push("/");
      }
    }, [admin_register_success, props.history]);
  
    const submitHandler = (e) => {
      e.preventDefault();
      if (password !== rePassword) {
        setErrorPassword("Las contraseñas no coinciden")
      } else if (password.length < 8){
        setErrorPassword("La longitud de la contraseña debe ser de almenos 8 caracteres")
      } else {
        setErrorPassword(null)
        dispatch(adminSignup(name, lastname, email, password, rePassword, adminInfo.token));
      }
    }
    
    return (
        <div className="signupAdmin-container">
          {
            adminInfo ?
            <div className="form-container">  
                <form onSubmit={submitHandler} className="form" >
                    <ul className="form-list">
                        <li>
                        <h2>Crear cuenta de administrador</h2>
                        </li>
                        <li>
                        {loading && <div>Loading...</div>}
                        {error && <h4 className="error-message">{error.message}</h4>}
                        { errorPassword && <h4 className="error-message">{errorPassword}</h4> }
                        </li>
                        <li>
                          <label htmlFor="name"> Nombre </label>
                          <input type="name" name="name" id="name" onChange={(e) => setName(e.target.value)}/>
                        </li>
                        <li>
                          <label htmlFor="last_name"> Apellidos </label>
                          <input type="last_name" name="last_name" id="last_name" onChange={(e) => setLastname(e.target.value)}/>
                        </li>
                        <li>
                        <label htmlFor="email">Email</label>
                        <input type="email" name="email" id="email" onChange={(e) => setEmail(e.target.value)} />
                        </li>
                        <li>
                        <label htmlFor="password">Contraseña</label>
                        <input type="password" id="password" name="password" onChange={(e) => setPassword(e.target.value)}/>
                        </li>
                        <li>
                        <label htmlFor="rePassword">Confirmar contraseña</label>
                        <input type="password" id="rePassword" name="rePassword" onChange={(e) => setRePassword(e.target.value)}/>
                        </li>
                        <li>
                        <button type="submit" className="primary1">Registrarse</button>
                        </li>
                    </ul>
                </form>
            </div>
            :
            <h1>Esta pagina solo se puede entrar como administrador (NotFound)</h1>
          }
        </div>
    )
}

export default SignupAdmin
