import React from 'react'
import { useMediaQuery } from 'react-responsive'

import CategoriesMenu from '../components/CategoriesMenu'
import Navbar from './Navbar'
import Footer from './Footer'


import WhatsAppChat from './WhatsAppChat'

const Layout = ({ children }) => {

    
    const isDesktop = useMediaQuery({
        query: '(min-width: 769px)'
    })

    return (
        <div className="grid-container">
            <header className="header">
                <Navbar />
                {
                    isDesktop && <CategoriesMenu />
                }
            </header>
            <main className="main">
                { children }
            <WhatsAppChat />
            </main>
            <footer className="footer">
                <Footer />            
            </footer>
        </div>
    )
}

export default Layout
