import React from 'react'
import ReactDOM from 'react-dom'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import './Modal.css'

function Modal (props) {
    if (!props.isOpen) {
        return null;
    }
    return ReactDOM.createPortal(
            <div className={props.className}>
                <div className="Modal__container">
                    <button onClick={props.onClose} className="Modal__close-button">
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                    {props.children}
                </div>
            </div>,
            document.getElementById('modal')
    )
    
}

export default Modal