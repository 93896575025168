import axios from "axios"
import { api_url } from "../utils/api-route"


async function editProduct(productID, productName, productPrice, quantity, image, token, category, subcategory) {
    
    // dispatch({ type: 'EDIT_PRODUCT_REQUEST' })
    const body = new FormData() 
    body.append('token', token)
    body.append('id', productID)
    console.log(image);
    if (image) {
        console.log("image exists");
        body.append('imagen', image)
    }
    else {
        console.log("image does not exists");
        // body.append('imagen', null)
    }
    body.append('inventory', quantity)
    body.append('price', productPrice)
    body.append('name', productName)
    console.log(body);
    // body.append('first_category', category)
    // body.append('second_category', subcategory)
    // const { data } = await axios.post(api_url + 'admin/updateProduct', body)
    // dispatch({ type: 'EDIT_PRODUCT_SUCCESS', payload: data })
    return await axios.post(api_url + 'admin/updateProduct', body);
    
}

export default editProduct