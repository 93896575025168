import React from 'react'
import { useSelector } from 'react-redux';

import getProductSumPrice from '../utils/get-sum-price';
import ShippingDetailsItem from './ShippingDetailsItem';

import './ShippingDetails.css'
import DiscountCodeForm from './DiscountCodeForm';

const OrderSummary = ({ paymentStage }) => {
    
    const { isValid, discount, code } = useSelector(state => state.discountCode)
    const { cartItems } = useSelector(state => state.cart)
    
    return (
        <>
            <div className="shippingDetails_items">
            {
                cartItems.map((item,i)=> {
                    return(
                        <ShippingDetailsItem key={i} product={item.product} qty={item.qty} />
                    )
                })
            }
            </div>
            <div className="shippingDetails_discountCode">
                { !paymentStage && <DiscountCodeForm /> }
            </div>
            <div className="shippingDetails_subtotal">
                <div className="subtotal">
                    <p>
                    Subtotal:
                    </p>
                    <p>
                        $ {getProductSumPrice(cartItems)}
                    </p>
                </div>
                <div className="discount-code">
                    {

                        isValid && discount ?
                        <>
                            <p style={{"color": "var(--green)", "fontWeight": "900"}}> Código de descuento valido: </p>
                            <p>{discount*100}%</p>
                        </>
                        :
                        <>
                        {
                            !paymentStage && code &&
                            <p style={{"color": "var(--red)", "fontWeight": "900"}}> Código de descuento no valido </p>
                        }
                        </>
                    }
                </div>
            </div>
            <div className="shippingDetails_total">
                <h2>Total</h2>
                {   
                    isValid ?
                    <h2>${(getProductSumPrice(cartItems) * (1-discount)).toFixed(2)}</h2>
                    :
                    <h2>${getProductSumPrice(cartItems)}</h2>
                }
            </div>
        </>                
    )
}

export default OrderSummary
