import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faInstagram, 
    faFacebook, 
    faWhatsapp,
    faCcVisa, 
    faCcAmex, 
    faCcMastercard,
    faPaypal
} from '@fortawesome/free-brands-svg-icons'
import {
    faPhoneAlt,
    faEnvelope,
    faMapMarkerAlt
} from '@fortawesome/free-solid-svg-icons'

import './Footer.css'
import imgLogo from '../assets/img/logo.png'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <div className="footer-container">
            <div className="footer-info">
                <div className="contact">    
                    <img src={imgLogo} alt="Farmacias Dr. Vertiz logo"/> 
                    <ul className="contact-items">
                        <li className="media">
                            <a href="https://www.instagram.com/farmvertiz/?hl=en">
                                <FontAwesomeIcon className="icon" icon={faInstagram}/>
                            </a>
                        </li>
                        <li className="media">
                            <a href="https://www.facebook.com/FarmaciasDrVertiz">
                                <FontAwesomeIcon className="icon" icon={faFacebook}/>
                            </a>
                        </li>
                        <li className="media">
                            <a href="https://api.whatsapp.com/message/N7KQ5VXRUCZ5F1">
                                <FontAwesomeIcon className="icon" icon={faWhatsapp}/>
                            </a>
                        </li>
                        <li className="address">
                            <a href="https://www.google.com/maps/place/Cumbres+de+Maltrata+398,+Narvarte+Oriente,+Benito+Ju%C3%A1rez,+03020+Ciudad+de+M%C3%A9xico,+CDMX,+Mexico/@19.3914355,-99.1507459,19.34z/data=!4m5!3m4!1s0x85d1ff016b35b441:0x2f98c4fbf2c06c0!8m2!3d19.3915426!4d-99.1507617">
                                <FontAwesomeIcon className="icon" icon={faMapMarkerAlt}/>
                            </a>
                        </li>
                        <li>
                            <a href="mailto:farmvertiz@hotmail.com">
                                <FontAwesomeIcon className="icon" icon={faEnvelope}/>
                            </a>
                        </li>
                    </ul>
                    <ul className="contact-email-tel">
                        
                        <li>
                            <FontAwesomeIcon className="icon" icon={faPhoneAlt}/>
                            55-5682-0029 y 55-5523-5350
                        </li> 
                    </ul>

                </div>
                
                    <div className="policys">
                        <h2>

                            Políticas
                        </h2>
                        <ul>
                            <li>
                                <Link to="/aviso-de-privacidad">Aviso de privacidad</Link>
                            </li>
                            <li>
                                <Link to="/terminos-y-condiciones">Términos y Condiciones</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="payment-methods">
                        <h2>
                            Métodos de Pago
                        </h2>
                        <ul>
                            <li>
                                <FontAwesomeIcon className="payment-icon" icon={faCcVisa}/>
                            </li>
                            <li>
                                <FontAwesomeIcon className="payment-icon" icon={faCcMastercard}/>
                            </li>
                            <li>
                                <FontAwesomeIcon className="payment-icon" icon={faCcAmex}/>
                            </li>
                            <li>
                                <FontAwesomeIcon className="payment-icon" icon={faPaypal}/>
                            </li>
                        </ul>
                    </div>
                
            </div>
            <div className="rigths-reserved">
                <h4>
                    Prohibida la venta de medicamentos a menores de edad
                </h4>
                <h4>
                    © 2021 Farmacias Dr. Vertiz - Todos los derechos reservados
                </h4>
            </div>
        </div>
    )
}

export default Footer
