import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { searchProducts } from '../actions/productActions';


import ClipLoader from 'react-spinners/ClipLoader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltDown, faLongArrowAltUp } from '@fortawesome/free-solid-svg-icons'

// import Checkbox from './Checkbox';
import { createOffer } from '../actions/adminActions';

import './OfferForm.css'
import Table from './Table';
// import { element } from 'prop-types';


const ArrowUp = ({ onClick }) => {
    const style = {
        "width": "100%",
        "padding": "2rem",
        "margin": "0"
    }
    return (
        <button type="button" style={style} onClick={onClick}>
            <FontAwesomeIcon icon={faLongArrowAltUp}/>
        </button>
    )
}

const ArrowDown = ({ onClick }) => {
    const style = {
        "width": "100%",
        "padding": "2rem",
        "margin": "0"
    }
    return (
        <button type="button" style={style} onClick={onClick}>
            <FontAwesomeIcon icon={faLongArrowAltDown}/>
        </button>
    )
}

const OfferForm = ({ adminInfo }) => {

    const [ image, setImage ] = useState("");
    const [ searchQuery, setSearchQuery ] = useState("")
    
    const [ selectedProducts, setSelectedProducts ] = useState([])
    const [ offerProducts, setOfferProducts ] = useState([])


    const dispatch = useDispatch()

    const results = useSelector(state => state.resultsList)
    const { loading, productsResults, error } = results

    const offers = useSelector(state => state.adminOffers)
    const { loadingO, offerCreationMessage, errorO } = offers

    // console.log("selected ", selectedProducts);    
    // console.log("offers ", offerProducts);
    useEffect(() => {
        if(searchQuery.length >= 3) {
            dispatch(searchProducts(searchQuery));
        }
    }, [searchQuery, dispatch])


    const selectProduct = (prod) => {
        const found = selectedProducts.find(element => element === prod)
        if (found) {
            alert("Este producto ya ha sido agregado")
        } else {
            const newSelected = [...selectedProducts, prod]
            const newOffers = newSelected.map(item => {
                return { id:item.id, offer_price: 0}
            })
            setOfferProducts(newOffers)
            setSelectedProducts(newSelected)
        }
    }

    const unselectProduct = (prod) => {
        let newSelected = []
        selectedProducts.map(item => {
            if ( item !== prod ){
                newSelected.push(item)
            }
            return 0
        })
        let newOffers = []
        offerProducts.map(item => {
            if ( item.id !== prod.id ){
                newOffers.push(item)
            }
            return 0

        })
        setOfferProducts(newOffers)
        setSelectedProducts(newSelected)
    }

    const changeProductOfferPrice = (id, offer_price) => {
        const offers = offerProducts.map(prod => {
            if (prod.id === id){
                return { id:id, offer_price: offer_price}
            }  else {
                return prod
            }
        })
        setOfferProducts(offers)
    }

    
    function getSearchRows() {
        const rows = productsResults.map(prod => {
            return([
                <ArrowDown onClick={() => selectProduct(prod)}/>,
                prod.id,
                <img src={prod.image} alt="product" />,
                prod.name,
                prod.price.toFixed(2),
                prod.first_category,
                prod.second_category
            ])
        })
        return rows
    }
    
    function getFormRows() {
        const rows = selectedProducts.map(prod => {
            return([
                <ArrowUp onClick={() => unselectProduct(prod)}/>,
                prod.id,
                <img src={"../product_img/products_all/"+prod.image} alt="product" />,
                prod.name,
                prod.price.toFixed(2),
                <input type="number" step=".01" required value={getOfferFromId(prod.id)}
                onChange={(e) => changeProductOfferPrice(prod.id, e.target.value)} />
            ])
        })
        return rows
    }
    
    function getOfferFromId(id) {
        let offer = "";
        offerProducts.map(item => {
            if (id === item.id) offer = item.offer_price
            return ""
        })
        return offer;
    }

    const submitSaveOffer = (e) => {
        e.preventDefault()
        // console.log("submitting offer");
        dispatch(createOffer(adminInfo.token, image, offerProducts))
    }
    
    return (
        <div className="offer-form">
            <ul className="form-list">
                
                <li>
                    <input 
                        type="text" 
                        name="product-query" 
                        id="product-query"
                        placeholder="Buscar productos ..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}  
                    />
                </li>
            </ul>
            <div className="display-search">
                { loading ? <ClipLoader /> :
                    <Table 
                        columnsHeader={["", "ID", "Imágen", "Nombre", "Precio", "Categoría", "Subcategoría"]}
                        rows={getSearchRows()}
                    />
                }
                {
                    error && <h2>Se produjo un error, inténtelo más tarde</h2>
                }
            </div>
            <h3>Precio de los productos en oferta</h3>
            <div className="display-offer-prods">
                <form className="form" onSubmit={submitSaveOffer}>
                <ul className="form-list">
                    <div className="offer-prices-list">
                        <li>
                            <Table 
                                columnsHeader={["", "ID", "Imágen", "Nombre", "Precio original", "Precio de oferta"]}
                                rows={getFormRows()}
                            />
                            
                        </li>
                    </div>
                    
                    <li>
                        <label htmlFor="image-product">
                            Imagen de la oferta
                        </label>
                        <input 
                            type="file" 
                            name="image-product" 
                            id="image-product" 
                            required
                            onChange={(e) => setImage(e.target.files[0])}    
                        />
                    </li>
                </ul>
                    {
                        loadingO ? 
                        <ClipLoader />
                        :
                        <button type="submit" className="primary1">
                            Guardar oferta
                        </button>
                    }
                </form>
                {
                    offerCreationMessage && <h2>{offerCreationMessage}</h2>
                }
                {
                    errorO && <h2>{errorO}</h2>
                }
            </div>
        </div>
    )
}

export default OfferForm
