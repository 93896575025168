import React from 'react'
import { Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

import './LayoutDashboard.css'


const LayoutDashboard = ({ children }) => {
    
    // const isMobile = useMediaQuery({maxWidth: 768})
    const isDesktop = useMediaQuery({minWidth: 768})

    

    return (
        <>
            
            <div className="dashboard-page">
                {
                    isDesktop &&

                    <div className="menu-dashboard">
                        <ul>
                            
                            <li>
                                <Link to="/dashboard/pedidos" className="dashboard-link">
                                    Pedidos
                                </Link>
                            </li>
                            <li>
                                <Link to="/dashboard/productos" className="dashboard-link">
                                    Productos
                                </Link>
                            </li>
                            <li>
                                <Link to="/dashboard/codigosDescuento" className="dashboard-link">
                                    Codigos de descuento
                                </Link>
                            </li>
                            <li>
                                <Link to="/dashboard/ofertas" className="dashboard-link">
                                    Ofertas
                                </Link>
                            </li>
                            <li>
                                <Link to="/dashboard/administradores" className="dashboard-link">
                                    Cuenta
                                </Link>
                            </li>
            
                        </ul>
                    </div>
                }
                
                <div className="page-dashboard">                    
                    { children }
                </div>
                
                
            </div>
        </>
    )
}

export default LayoutDashboard
