import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive'

import { userLogout } from '../actions/userActions'

import SideMenu from './SideMenu'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingCart, faBars } from '@fortawesome/free-solid-svg-icons'
import { faUser } from '@fortawesome/free-solid-svg-icons'


import Searcher from './Searcher'

import imgLogo from '../assets/img/logo.png'
import imgLogoSimple from '../assets/img/logo_simple.png'


import './Navbar.css'
import DropdownMenu from './DropdownMenu';
import DropdownItem from './DropdownItem';


const Navbar = () => {
    useEffect(() => {
        if (document.querySelector(".sidebar")){
            document.querySelector(".sidebar").classList.remove("open")
        }
    })
    

    // const isMobile = useMediaQuery({query: '(max-width: 900px)'})
    // const isDesktop = useMediaQuery({query: '(min-width: 900px)'})
    
    const isMobile = useMediaQuery({maxWidth: 768})
    const isDesktop = useMediaQuery({minWidth: 768})

    const numItems = useSelector(state => state.cart.cartItems.length)
    const userLogin = useSelector(state => state.userLogin )

    const { userInfo } = userLogin;

    const dispatch = useDispatch()
    let history = useHistory();

    const handleLogout = () => {
        // console.log("cerrando sesion");
        setOpenUserMenu(false)
        dispatch(userLogout())
        history.push("/")
    }

    const onOpenMenu = () => {
        document.querySelector(".sidebar").classList.add("open");
    }
    
    const onCloseMenu = () => {
        document.querySelector(".sidebar").classList.remove("open")
    }

    const [ openUserMenu, setOpenUserMenu ] = useState(false)
    
    return (
        <>
        {isMobile &&
            <>
            <div className="navbar">
                <div className="header-links">
                    <button onClick={onOpenMenu}>
                        <FontAwesomeIcon className="hamburger-menu" icon={faBars}/>
                    </button>    
                </div>
                <ul className="navbar-items">
                    <NavbarItemCart numItems={numItems}/>
                    <NavbarItem 
                        link={true} to={"/"} 
                        text={<img src={imgLogoSimple} alt="" width="40px"/>} 

                    />
                </ul>
            </div>
            <Searcher />
            <SideMenu onCloseMenu={onCloseMenu}/>
            
            </>
        }
        {isDesktop &&
            <>
                <div className="navbar">
                    <div className="brand">
                        <Link to="/">
                            <img src={imgLogo} alt="Farmacias Dr. Vertiz logo"/>
                        </Link>
                    </div>
                    <Searcher />
                    <ul className="navbar-items">
                        <NavbarItemCart numItems={numItems}/>
                        
                        <li className="nav-item">
                            <DropdownMenu 
                                classesForA={"icon-button"} 
                                title={<FontAwesomeIcon icon={faUser}/>} 
                                animationClass={"user-dropdown-animation"}
                                dropdownClass={"dropdown-user"}
                                open={openUserMenu}
                                setOpen={setOpenUserMenu}
                            > 
                                {
                                    userInfo ?
                                    <>
                                        <DropdownItem 
                                            dropdownItemClass={"user-dropdown-item"} 
                                            button
                                            onClick={handleLogout} 
                                            text={"Cerrar sesión"}
                                        />
                                        <DropdownItem 
                                            dropdownItemClass={"user-dropdown-item"} 
                                            link
                                            onClick={() => setOpenUserMenu(false)} 
                                            to="/account"
                                            text={"Mis pedidos"}
                                        />
                                    </>
                                    :
                                    <> 
                                    <DropdownItem 
                                        dropdownItemClass={"user-dropdown-item"} 
                                        link
                                        onClick={() => setOpenUserMenu(false)} 
                                        to={"/loginUser"} 
                                        text={"Iniciar sesión"}/>
                                    <DropdownItem 
                                        dropdownItemClass={"user-dropdown-item"} 
                                        link
                                        to={"/signupUser"} 
                                        onClick={() => setOpenUserMenu(false)} 
                                        text={"Registrarse"}/>
                                    </>

                                    
                                }
                            </DropdownMenu>
                        </li>
                    </ul>
                </div>
            </>
        }
        </>
    )
}

function NavbarItemCart(props) {
    return (
        <li className="nav-item"> 
            <Link to="/cart" className="icon-button">
                <FontAwesomeIcon icon={faShoppingCart} className="shopping-bag-icon" />
                <span  className="cart-counter-badge" >{props.numItems}</span>                  
            </Link>
        </li>
    )
}

function NavbarItem(props) {


    return (
        <li className="nav-item">
            <Link to={props.to} className="icon-button">
                {props.text}
            </Link>
            { props.children }
        </li>
    );
}

export default Navbar;


/* <Link className="cart_Link" to="/cart">
                            <span  className="cart-counter-badge" >{numItems}</span>              
                            <FontAwesomeIcon icon={faShoppingBag} className="shopping-bag-icon" />
                        </Link> */

                        /**
                         * roductos Covid
    - Higiene Bucal
    - Cuidado personal y belleza
    - Bebes e incontinencia
    - Helados
                         */