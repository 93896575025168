import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { userIsVerified } from '../actions/userActions';

import getProductSumPrice from '../utils/get-sum-price';

import ShippingForm from './ShippingForm';

import './ShippingData.css'
import ResendEmail from './ResendEmail';
// import StorePickup from './StorePickup';


const ShippingPresenter = () => {
    
  
    const [ isShippingAvailable, setShippingAvailableTo] = useState(false)
    
    const { userInfo } = useSelector(state => state.userLogin);
    const { isVerified } = useSelector(state => state.userIsVerified);
    const { cartItems } = useSelector(state => state.cart);
    
    const dispatch = useDispatch();
    const history = useHistory();

    
    // user identification
    useEffect(() => { 
      ! userInfo && history.push("/loginUser?redirect=shipping");
    }, [userInfo, history]);
    
    // user verification
    useEffect(() => {
      userInfo && dispatch(userIsVerified(userInfo.token))
    }, [userInfo, dispatch])
    
    // verifying shipping availability
    useEffect(() => {
      const total_price = getProductSumPrice(cartItems);
      total_price >= 100 ? setShippingAvailableTo(true) : 
      setShippingAvailableTo(false)
    }, [cartItems])
    

    if (!isVerified){ 
      return(
        <>
          <ResendEmail/>
        </>
      )
    }
    
    return (
        <ShippingForm shippingAvailable={isShippingAvailable}/>
      
    )
}

export default ShippingPresenter
