import axios from "axios";
import { api_url } from "../utils/api-route";
import getProductSumPrice from "../utils/get-sum-price";

const baseUrl = api_url

export const savePurchase = (token) =>  (dispatch, getState) => {
    dispatch({
        type: 'SAVE_PURCHASE_REQUEST',
    })
    try {
        const { cart: { cartItems }} = getState();
        const { shipping: { shippingInfo }} = getState();
        const lista_compra = cartItems.map(item => {
            return(
                [
                    item.product.id,
                    item.qty
                ]
            )
        })
        
        // console.log(shippingInfo);
        axios.post(baseUrl + 'purchases/create', {
            payment_id: shippingInfo.orderID,
            status: 1,
            token: token,
            address: shippingInfo.address,
            references: shippingInfo.references,
            phone: shippingInfo.phone,
            products: lista_compra,
            discount_code: shippingInfo.discount_code,
            billing: shippingInfo.billingDetails ? shippingInfo.billingDetails : ""
        })
        dispatch({
            type: 'SAVE_PURCHASE_SUCCESS',
        })
    } catch (error) {
        // console.log(error);
        dispatch({
            type: 'SAVE_PURCHASE_FAIL',
            payload: error.response
        })
    }
}

export const saveShippingInfo = (
    name, lastname, street, street_number, neighborhoud, zipcode, phone, references, disc_code, discount
    ) => (dispatch, getState) => {    
    const address = `${street}, ${neighborhoud}, ${zipcode}`
    const {cart: {cartItems}} = getState()

    dispatch({
        type: 'SAVE_SHIPPING_INFO',
        payload: {
            address: address,
            street: street,
            street_number: street_number,
            name: name,
            lastname: lastname,
            
            neighborhoud: neighborhoud,
            zipcode: zipcode,
            references: references,
            phone: phone,
            discount_code: disc_code,
            discount: discount,
            final_price: (getProductSumPrice(cartItems) * (1-discount)).toFixed(2)
        }
    })
}

export const saveBillingDetails = (razonSocial, rfc, contact, phone, cfdi, address, email) => (dispatch) => {
    dispatch({ 
        type: 'SAVE_BILLING_DETAILS', 
        payload: {
            razonSocial: razonSocial,
            rfc: rfc,
            name: contact,
            phone: phone,
            cfdi: cfdi,
            billingAddress: address,
            email: email
        }
    })
}

export const saveOrderIDPaypal = ( orderID ) => (dispatch) => {
    dispatch({ 
        type: 'SAVE_ORDER_ID', 
        payload: {
            orderID: orderID
        }
    })
}

export const validateDiscountCode = (discCode) =>  async (dispatch) => {
    try {
        dispatch({ type: 'VALIDATE_CODE_REQUEST' })
        const { data } = await axios.get(baseUrl + 'discountCode/isValid', {
            params: { 
                code: discCode
            }
        })
        // console.log(data);
        dispatch({ type: 'VALIDATE_CODE_SUCCESS', payload:{
            data: data,
            code: discCode
        } })
    } catch (error) {
        // console.log("error: ", error.response);
        dispatch({ type: 'VALIDATE_CODE_FAIL', payload:error })
    }
}


