function userLoginReducer(state={}, action) {
    switch (action.type) {
        case 'USER_LOGIN_REQUEST':
          return { loading: true };
        case 'USER_LOGIN_SUCCESS':
          return { loading: false, userInfo: action.payload }
        case 'USER_LOGIN_FAIL':
          return { loading: false, error: action.payload }
        case 'USER_LOGOUT':
          return {}
        default:
          return state
    }
}

function userSignupReducer(state = {}, action) {
    switch (action.type) {
      case 'USER_SIGNUP_REQUEST':
        return { loading: true };
      case 'USER_SIGNUP_SUCCESS':
        return { loading: false, userInfo: action.payload };
      case 'USER_SIGNUP_FAIL':
        return { loading: false, error: action.payload };
      default: 
        return state;
    }
}

function userResendVerificationEmailReducer(state = {}, action) {
  switch (action.type) {
    case 'RESEND_VERIFICATION_EMAIL_REQUEST':
      return { loadingResend: true };
    case 'RESEND_VERIFICATION_EMAIL_SUCCESS':
      return { loadingResend: false, emailResentMessage: action.payload };
    case 'RESEND_VERIFICATION_EMAIL_FAIL':
      return { loadingResend: false, errorResend: action.payload };
    default: 
      return state;
  }
}

function userIsVerifiedReducer(state = {}, action) {
  switch(action.type){
    case 'USER_IS_VERIFIED_REQUEST':
      return {
        loadingVer: true
      }
    case 'USER_IS_VERIFIED_SUCCESS':
      return {
        loadingVer: false,
        isVerified: action.payload
      }
    case 'USER_IS_VERIFIED_FAIL':
      return {
        loadingVer: false,
        errorVer: action.payload
      }
    default:
      return state
  }
}

function userResetPasswordReducer(state ={}, action) {
  switch (action.type) {
    case 'USER_RESET_PASSWORD_REQUEST':
      return {
        loading: true
      }
    case 'USER_RESET_PASSWORD_SUCCESS':
      return {
        loading: false,
        linkSentMessage: action.payload
      }
    case 'USER_RESET_PASSWORD_FAIL':
      return { loading: false, error: action.payload };
    default:
      return state
  }
}

function userChangePasswordReducer(state ={}, action) {
  switch (action.type) {
    case 'USER_CHANGE_PASSWORD_REQUEST':
      return {
        loading: true
      }
    case 'USER_CHANGE_PASSWORD_SUCCESS':
      return {
        loading: false,
        passwordChangedMessage: action.payload
      }
    case 'USER_CHANGE_PASSWORD_FAIL':
      return { loading: false, error: action.payload };
    default:
      return state
  }
}

function userPurchasesReducer(state = {}, action) {
  switch (action.type) {
    case 'USER_GET_PURCHASES_REQUEST':
      return {
        loading: true
      }
    case 'USER_GET_PURCHASES_SUCCESS':
      return {
        loading: false,
        unactivePurchases: action.payload.unactivePurchases,
        activePurchases: action.payload.activePurchases

      }
    case 'USER_GET_PURCHASES_FAIL':
      return { loading: false, error: action.payload };
    case 'USER_GET_PURCHASE_BY_ID_REQUEST':
      return { ...state, loading: true}
    case 'USER_GET_PURCHASE_BY_ID_SUCCESS':
      return { ...state, loading: false, singlePurchase: action.payload}
    case 'USER_GET_PURCHASE_BY_ID_FAIL':
      return { ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export {
    userLoginReducer,
    userSignupReducer,
    userIsVerifiedReducer,
    userResetPasswordReducer,
    userResendVerificationEmailReducer,
    userChangePasswordReducer,
    userPurchasesReducer
}