import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'


import ClipLoader from 'react-spinners/ClipLoader' //https://www.npmjs.com/package/react-spinners
import { createProduct } from '../actions/adminActions'


const CreateProduct = () => {
    const [ productCategory, setProductCategory ] = useState("Tabletas")
    const [ productSubcategory, setProductSubcategory ] = useState("")
    const [ productName, setProductName ] = useState("");
    const [ productPrice, setProductPrice ] = useState("");
    const [ quantity, setQuantity ] = useState("");
    const [ image, setImage ] = useState("");

    const admin = useSelector(state => state.adminLogin )
    const { adminInfo } = admin;
    const dispatch = useDispatch();

    const productSavedInfo = useSelector(state => state.adminCreateProduct)
    const { loading, productSaved, error} = productSavedInfo


    const fileChangeHandler = (e) => {
        setImage(e.target.files[0])
    }


    const submitHandler = (e) => {
        e.preventDefault()
                
        dispatch(createProduct(
            productName, 
            productPrice, 
            quantity, 
            image, 
            adminInfo.token, 
            productCategory, 
            productSubcategory
        ));
    }

    const handleCategoryChange = (e) => {
        // console.log(e.target.value);
        setProductCategory(e.target.value)
        // console.log(productCategory);
    }
    
    function getErrorMessages() {
        let errorMessages = [];
        for (const [key, value] of Object.entries(error)) {
            value.map((m,i) => errorMessages.push(
                <h4 key={key + i}>{m}</h4>
            ))
        }
        return errorMessages
    }

    return (
        <div className="form-container">
            <form onSubmit={submitHandler} className="form">
                <ul className="form-list">
                    <li>
                        <h3>Crear producto nuevo</h3>
                    </li>
                    <li>
                        {  error && getErrorMessages() } 
                        {  productSaved && <h4>{ productSaved.message }</h4>  }
                    </li>
                    <li>
                        <label htmlFor="nombre-producto">
                            Nombre del producto
                        </label>
                        <input 
                            type="text" 
                            name="nombre-producto" 
                            id="nombre-producto" 
                            required
                            value={productName}
                            onChange={(e) => setProductName(e.target.value)}    
                        />
                    </li>
                    <li>
                        <label htmlFor="price-product">
                            Precio
                        </label>
                        <input 
                            type="price-product" 
                            name="price-product" 
                            id="price-product" 
                            required
                            value={productPrice}
                            onChange={(e) => setProductPrice(e.target.value)}    
                        />
                    </li>
                    <li>
                        <label htmlFor="quantity-product">
                            Cantidad
                        </label>
                        <input 
                            type="number" 
                            name="quantity-product" 
                            id="quantity-product" 
                            required
                            value={quantity}
                            onChange={(e) => setQuantity(e.target.value)}    
                        />
                    </li>
                    <li>
                        <label htmlFor="image-product">
                            Imagen
                        </label>
                        <input 
                            type="file" 
                            name="image-product" 
                            id="image-product" 
                            required
                            set={image}
                            onChange={fileChangeHandler}    
                        />
                    </li>
                    <li>
                        <label htmlFor="categoria-product">
                            Categoría
                        </label>
                        <select 
                            
                            required
                            className="qty-select"
                            onChange={handleCategoryChange}    
                        >
                            <option value="Tabletas">Tabletas</option>
                            <option value="Ampolletas">Ampolletas</option>
                            <option value="Cremas">Cremas</option>
                            <option value="Genéricos">Genéricos</option>
                            <option value="Gotas">Gotas</option>
                            <option value="Jarabes">Jarabes</option>
                            <option value="Supositorios">Supositorios</option>
                            <option value="Ovulos">Ovulos</option>
                        </select>
                    </li>
                    <li>
                        <label htmlFor="subcategoria-product">
                            Subcategoría
                        </label>
                        <input 
                            type="text" 
                            name="subcategoria-product" 
                            id="subcategoria-product" 
                            required
                            value={productSubcategory}
                            onChange={(e) => setProductSubcategory(e.target.value)}    
                        />
                    </li>
                    <li>
                        {
                            loading?
                            <div className="clip-loader">
                                <ClipLoader />
                            </div>
                            :
                            <button type="submit" className="button primary1">
                                Guardar 
                            </button>
                        }
                    </li>        
                </ul>
            </form>
        </div>
    )
}

export default CreateProduct
