import React from 'react';
import { useMediaQuery } from 'react-responsive'
import { useSelector } from 'react-redux';


import CheckoutSteps from '../components/CheckoutSteps';
import OrderSummary from '../components/OrderSummary';

import './Payment.css'
import PaymentForm from '../components/PaymentForm';
import OrderSummaryButton from '../components/OrderSummaryButton';


function Payment() {
    
    const { cartItems } = useSelector(state => state.cart)  

    const isDesktop = useMediaQuery({
      query: '(min-width: 1024px)'
    })

    return (
      <>
        {
          isDesktop ? 
          <div className='payment-container-desktop'>
            <div className="payment-data">
              <CheckoutSteps step1 step2 step3 />
              <h2>Información de pago</h2>
              <PaymentForm cartItems={cartItems} />
            </div>
            <div className="summary">
              <OrderSummary  
                paymentStage={true} 
                cartItems={cartItems}
              />
            </div>
          </div>
          :
          <div className="payment-container-tablet-mobile">
            <div className="headers-payment-container">
              <CheckoutSteps step1 step2 step3 />
              <OrderSummaryButton />
            </div>
            <div className="summary-mobile">
                <OrderSummary
                    paymentStage={true} 
                    cartItems={cartItems}
                />
            </div>
            <div className="payment">
              <h2>Selecciona tu método de pago</h2>
              <PaymentForm cartItems={cartItems} />
            </div>
          </div>
        }
      </>
    )
}

export default Payment
