import React from 'react'
import { Link } from 'react-router-dom'

const UnsuccessfulPayment = () => {
    return (
        <div className="payment-message-page">
            <h1>Tu pago no se pudo procesar</h1>
            <Link to="/" className="primary1">
                Volver a la página principal
            </Link>
        </div>
    )
}

export default UnsuccessfulPayment
