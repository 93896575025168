import Home from '../pages/Home'
import Cart from '../pages/Cart'
import LoginUser from '../pages/LoginUser'
import LoginAdmin from '../pages/LoginAdmin'
import SignupUser from '../pages/SignupUser'
import SignupAdmin from '../pages/SignupAdmin'
import Results from '../pages/Results'
import Shipping from '../pages/Shipping'
import Payment from '../pages/Payment'
import RestorePassword from '../pages/RestorePassword'
import Dashboard from '../pages/Dashboard'

import ChangePassword from '../pages/ChangePassword'
import VerificationEmail from '../pages/VerificationEmail'
import SuccessfulPayment from '../pages/SuccessfulPayment'
import UnsuccessfulPayment from '../pages/UnsuccessfulPayment'
import Profile from '../pages/Profile'
import ProfilePurchaseDetails from '../pages/ProfilePurchaseDetails'
import PedidosDashboard from '../pages/Dashboard/PedidosDashboard'
import ProductosDashboard from '../pages/Dashboard/ProductosDashboard'
import AdministradoresDashboard from '../pages/Dashboard/AdministradoresDashboard'
import OfertasDashboard from '../pages/Dashboard/OfertasDashboard'
import DiscountCodesDashboard from '../pages/Dashboard/DiscountCodesDashboard'
import PurchaseDetails from '../pages/Dashboard/PurchaseDetails'
import AvisoPrivacidad from '../pages/AvisoPrivacidad'
import TerminosYCondiciones from '../pages/TerminosYCondiciones'
import NotFound from '../pages/NotFound'

const routes = [
    {
        path: '/',
        component: Home,
    },
    {
        path: '/cart',
        component: Cart,
    },
    {
        path: '/loginUser',
        component: LoginUser,
    },
    {
        path: '/loginAdmin',
        component: LoginAdmin,
    },
    {
        path: '/signupUser',
        component: SignupUser,
    },
    {
        path: '/verifyEmail',
        component: VerificationEmail,
    },
    {
        path: '/signupAdmin',
        component: SignupAdmin,
    },
    {
        path: '/restorePassword',
        component: RestorePassword,
    },
    {
        path: '/changePassword',
        component: ChangePassword,
    },
    {
        path: '/account',
        component: Profile,
    },
    {
        path: '/account/pedidos/:id',
        component: ProfilePurchaseDetails,
    },
    {
        path: '/dashboard',
        component: Dashboard,
        routes:[
            
            {
                path: '/dashboard/pedidos',
                component: PedidosDashboard,
            },
            {
                path: '/dashboard/pedidos/:id',
                component: PurchaseDetails,
            },
            {
                path: '/dashboard/productos',
                component: ProductosDashboard,
            },
            {
                path: '/dashboard/administradores',
                component: AdministradoresDashboard,
            },
            {
                path: '/dashboard/ofertas',
                component: OfertasDashboard,
            },
            {
                path: '/dashboard/codigosDescuento',
                component: DiscountCodesDashboard,
            }
        ]
    },
    {
        path: '/results',
        component: Results,
    },
    {
        path: '/results_category/:category',
        component: Results,
    },
    {
        path: '/results_query/:query',
        component: Results,
    },
    {
        path: '/shipping',
        component: Shipping,
    },
    {
        path: '/payment',
        component: Payment,
    },
    {
        path: '/successfulPayment',
        component: SuccessfulPayment,
    },
    {
        path: '/unsuccessfulPayment',
        component: UnsuccessfulPayment,
    },
    {
        path: '/aviso-de-privacidad',
        component: AvisoPrivacidad
    },
    {
        path: '/terminos-y-condiciones',
        component: TerminosYCondiciones
    },
    {
        path: '*',
        component: NotFound
    }
]

export default routes;