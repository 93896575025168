import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { saveBillingDetails } from '../actions/orderActions'

const BillingForm = () => {
    const [ razonSocial, setRazonSocial ] = useState("")
    const [ rfc, setRfc ] = useState("")
    const [ contact, setContact ] = useState("")
    const [ phone, setPhone ] = useState("")
    const [ cfdi, setCfdi ] = useState("")
    const [ address, setaAddress ] = useState("")
    const [ email, setEmail ] = useState("")

    const dispatch = useDispatch()

    const handleSaveBillingDetails = (e) => {
        e.preventDefault()
        dispatch(saveBillingDetails(razonSocial, rfc, contact, phone, cfdi, address, email))
    }

    return (
        
        <form className="form" onSubmit={handleSaveBillingDetails}>
            <ul className="form-list">
                <li>
                    <label htmlFor="razon_social">
                        Razón social
                    </label>
                    <input 
                        type="text" 
                        name="razon_social"
                        required
                        placeholder="Tu Empresa S.A de C.V."
                        onChange={(e) => setRazonSocial(e.target.value)}
                    />
                </li>
                <li>
                    <label htmlFor="rfc">
                        RFC
                    </label>
                    <input 
                        type="text" 
                        name="rfc"
                        required
                        placeholder="ABCD012345"
                        onChange={(e) => setRfc(e.target.value)}
                    />
                </li>
                <li>
                    <label htmlFor="contact">
                        Contacto
                    </label>
                    <input 
                        type="text" 
                        name="contact"
                        required
                        placeholder="Tu nombre"
                        onChange={(e) => setContact(e.target.value)}
                    />
                </li>
                <li>
                    <label htmlFor="telephone">
                        Telefono
                    </label>
                    <input 
                        type="number" 
                        name="telephone"
                        required
                        placeholder="0123456789"
                        pattern="[0-9]{10}"
                        onChange={(e) => setPhone(e.target.value)}
                    />
                </li>
                <li>
                    <label htmlFor="cfdi">
                        CFDI
                    </label>
                    <input 
                        type="text" 
                        name="cfdi"
                        required
                        onChange={(e) => setCfdi(e.target.value)}
                    />
                </li>
                <li>
                    <label htmlFor="address">
                        Dirección
                    </label>
                    <input 
                        type="text" 
                        name="address"
                        required
                        placeholder="Calle #10, Colonia, Ciudad, Estado"
                        onChange={(e) => setaAddress(e.target.value)}
                    />
                </li>
                <li>
                    <label htmlFor="email">
                        Correo electrónico
                    </label>
                    <input 
                        type="email" 
                        name="email"
                        required
                        placeholder="tucorreo@tucorreo.com"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </li>
                <li>
                    <button type="submit" className="primary1">
                        Guardar datos de facturación
                    </button>
                </li>
            </ul>        
        </form>
    )
}

export default BillingForm
