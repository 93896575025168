import React from 'react'


import { ReactComponent as FiltersIcon } from '../assets/img/filter.svg';
import './FiltersButton.css'

const FiltersButton = (props) => {
    return (
        <div className="filters-button-container">
            <button onClick={props.onClick} className="filters-button" type={props.type}>
                {props.text} <FiltersIcon className="filters-icon"/>
            </button>
        </div>
    )
}

export default FiltersButton
