import React  from 'react'
import { useDispatch } from 'react-redux'

import { addToCart, removeFromCart } from '../actions/cartActions'

//import { CSSTransition } from 'react-transition-group'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'

import './CartItem.css'

const CartItem = (props) => {
    
    const product = props.product 
    const qty = props.qty
    const dispatch = useDispatch();

    const handleDelete = () => {
        dispatch(removeFromCart(product))
        
    }
    
    return (
        // <CSSTransition
        //     in={showItem}
        //     timeout={500}
        //     classNames="delete-transition"
        //     unmountOnExit
        // >

        <div className="cart-item_Card">
            <div className="cart-item_Image">
                <img 
                    src={product.image} 
                    alt={product.name + " image "}
                />
            </div>
            <div className="cart-item_Info">
                <h2 className="name">
                    <b>{product.name} </b>
                </h2>
                <p className="description">
                    {product.description}
                </p>
                <p>
                    Piezas: {qty}
                </p>
                <p >
                    Precio unitario: 
                    {
                        product.offer_price ?
                        <>
                            <span>
                                <s>$ {product.price.toFixed(2)}</s> 
                            </span>
                            <span>
                                {" "}${product.offer_price.toFixed(2)}
                            </span>
                        </>
                        :
                        <span>
                            {" "}${product.price.toFixed(2)}
                        </span>
                    } 
                </p>
                <h3>
                    Subtotal: $
                    {
                    product.offer_price?
                    (product.offer_price*qty).toFixed(2)
                    :
                    (product.price*qty).toFixed(2)
                    }
                </h3>
                {
                    product.inventory > 0 ?
                    <select 
                        value={qty} 
                        className="qty-select" 
                        onChange={(e) => dispatch(addToCart(product, parseInt(e.target.value)))}
                    >
                        {[...Array(product.inventory).keys()].map(x =>
                            <option key={x + 1} value={x + 1}>{x + 1}</option>
                        )}                            
                    </select>
                    :
                    <h5>Producto no disponible</h5>
                }
                <button className="delete" onClick={handleDelete}>
                    <FontAwesomeIcon icon={faTrashAlt}/>
                </button>
                {
                    props.hasChanged && 
                    <h4>
                        El precio o la disponibilidad de este producto ha cambiado
                    </h4>
                }
                
            </div>
        </div>
        // </CSSTransition>
    )
}


export default CartItem;