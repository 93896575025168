function adminSignupReducer(state = {}, action) {
    switch (action.type) {
      case 'ADMIN_SIGNUP_REQUEST':
        return { loading: true };
      case 'ADMIN_SIGNUP_SUCCESS':
        return { loading: false, admin_register_success: true };
      case 'ADMIN_SIGNUP_FAIL':
        return { loading: false, error: action.payload };
      default: 
        return state;
    }
}

function adminLoginReducer(state = {}, action) {
    switch (action.type) {
      case 'ADMIN_LOGIN_REQUEST':
        return { loading: true };
      case 'ADMIN_LOGIN_SUCCESS':
        return { loading: false, adminInfo: action.payload };
      case 'ADMIN_LOGIN_FAIL':
        return { loading: false, error: action.payload };
      case 'ADMIN_LOGOUT':
        return {}
      default: 
        return state;
    }
}

function adminPurchasesReducer (state = {}, action) {
  switch (action.type) {
    case 'ADMIN_GET_PURCHASES_REQUEST':
      return {...state, loading: true}
    case 'ADMIN_GET_PURCHASES_SUCCESS':
      return { 
        ...state,
        loading: false, 
        unactivePurchases: action.payload.unactivePurchases,
        activePurchases: action.payload.activePurchases
      }
    case 'ADMIN_GET_PURCHASES_FAIL':
      return { ...state, loading: false, error: action.payload }
    case 'ADMIN_GET_PURCHASE_BY_ID_REQUEST':
      return { ...state, loading: true}
    case 'ADMIN_GET_PURCHASE_BY_ID_SUCCESS':
      return { ...state, loading: false, singlePurchase: action.payload}
    case 'ADMIN_GET_PURCHASE_BY_ID_FAIL':
      return { ...state, loading: false, error: action.payload }
    default: 
      return state
  }
}

function adminChangePurchaseReducer (state = { purchaseStatusChanged: false }, action) {
  switch (action.type) {
      case 'CHANGE_PURCHASE_STATUS_REQUEST':
        return { loading: true }
      case 'CHANGE_PURCHASE_STATUS_SUCCESS':
        // console.log("desde el reducer");
        // console.log(state.purchaseStatusChanged);
        return { loading: false, purchaseStatusChanged: !state.purchaseStatusChanged }
      case 'CHANGE_PURCHASE_STATUS_FAIL':
        return { loading: false, error: action.payload }
      default:
        return state
  }
}

function adminCreateProductReducer(state = { productSaved: "" }, action) {
  switch (action.type) {
      case 'SAVE_PRODUCT_REQUEST':
          return {
              ...state,
              loading: true
          }
      case 'SAVE_PRODUCT_SUCCESS':
          return {
              ...state,
              productSaved: action.payload,
              loading: false
          }
      case 'SAVE_PRODUCT_FAIL':
          return {
              ...state,
              loading: false,
              error: action.payload
          }
      default: 
          return state;
          
  }
}

function adminEditProductReducer(state = { productEdited: "" }, action) {
  switch (action.type) {
      case 'EDIT_PRODUCT_REQUEST':
          return {
              ...state,
              loading: true
          }
      case 'EDIT_PRODUCT_SUCCESS':
          return {
              ...state,
              productEdited: action.payload,
              loading: false
          }
      case 'EDIT_PRODUCT_FAIL':
          return {
              ...state,
              loading: false,
              error: action.payload
          }
      default: 
          return state;
          
  }
}

function adminCreateDiscountCodeReducer(state = { codeSaved: "" }, action) {
  switch (action.type) {
      case 'SAVE_CODE_REQUEST':
          return {
              ...state,
              loading: true
          }
      case 'SAVE_CODE_SUCCESS':
          return {
              ...state,
              codeSaved: action.payload,
              loading: false
          }
      case 'SAVE_CODE_FAIL':
          return {
              ...state,
              loading: false,
              error: action.payload
          }
      default: 
          return state;
          
  }
}

function adminGetDiscountCodeReducer(state = { discountCodes: [] }, action) {
  switch (action.type) {
      case 'GET_DISCOUNT_CODES_REQUEST':
          return {
              ...state,
              loadingCodes: true
          }
      case 'GET_DISCOUNT_CODES_SUCCESS':
          return {
              ...state,
              discountCodes: action.payload,
              loadingCodes: false
          }
      case 'GET_DISCOUNT_CODES_FAIL':
          return {
              ...state,
              loadingCodes: false,
              errorCodes: action.payload
          }
      default: 
          return state;
          
  }
}

function adminChangeDiscountCodeStatusReducer(state = { codeStatusChanged: false, error: false}, action) {
  switch (action.type) {
      case 'ACTIVATE_DISCOUNT_CODE_REQUEST':
          return {
              ...state,
              loading: true
          }
      case 'ACTIVATE_DISCOUNT_CODE_SUCCESS':
          return {
              ...state,
              codeStatusChanged: !state.codeStatusChanged,
              loading: false
          }
      case 'ACTIVATE_DISCOUNT_CODE_FAIL':
          return {
              ...state,
              loading: false,
              error: action.payload
          }
      case 'DEACTIVATE_DISCOUNT_CODE_REQUEST':
          return {
              ...state,
              loading: true
          }
      case 'DEACTIVATE_DISCOUNT_CODE_SUCCESS':
          return {
              ...state,
              codeStatusChanged: !state.codeStatusChanged,
              loading: false
          }
      case 'DEACTIVATE_DISCOUNT_CODE_FAIL':
          return {
              ...state,
              loading: false,
              error: action.payload
          }
      default: 
          return state;
          
  }
}


function adminOffersReducer(state = { offers: [], offerHasChanged: false }, action) {
  switch (action.type) {
      case 'CREATE_OFFER_REQUEST':
        return {
            ...state,
            loadingO: true
        }
      case 'CREATE_OFFER_SUCCESS':
          return {
              ...state,
              offerCreationMessage: action.payload,
              loadingO: false
          }
      case 'CREATE_OFFER_FAIL':
          return {
              ...state,
              loadingO: false,
              errorO: action.payload
          }
      case 'GET_OFFERS_REQUEST':
          return {
              ...state,
              loading: true
          }
      case 'GET_OFFERS_SUCCESS':
          return {
              ...state,
              offers: action.payload,
              loading: false
          }
      case 'GET_OFFERS_FAIL':
          return {
              ...state,
              loading: false,
              error: action.payload
          }
      case 'CHANGE_OFFER_STATUS_REQUEST':
        return {
          ...state,
          loading:true,
          offerHasChanged: true,
        }
      case 'CHANGE_OFFER_STATUS_SUCCESS':
        return {
          ...state,
          successMessage: action.payload,
          offerHasChanged: !state.offerHasChanged,
          loading:false
        }
      case 'CHANGE_OFFER_STATUS_FAIL':
      return {
        ...state,
        error: action.payload,
        offerHasChanged: !state.offerHasChanged,
        loading:false
      }
      default: 
          return state;
          
  }
}


export {
    adminSignupReducer,
    adminLoginReducer,
    adminPurchasesReducer,
    adminChangePurchaseReducer,
    adminCreateProductReducer,
    adminEditProductReducer,
    adminCreateDiscountCodeReducer,
    adminGetDiscountCodeReducer,
    adminChangeDiscountCodeStatusReducer,
    adminOffersReducer
}