import React from 'react'
import { useSelector } from 'react-redux';

import { useHistory } from 'react-router-dom'

import Modal from './Modal'

import './AddToCartModal.css'

import getProductPrice from '../utils/get-price'


const AddToCartModal = (props) => {
   
    const product = props.product
    const qty = props.qty 

    const repeatedProduct = useSelector(state => state.cart.repeatedProduct)
    
    const history = useHistory()

    const handlePayNow = () => {
        history.push("/shipping")
    }

    const style = {
        height: "35vh",
        width: "auto",
        
        // top: "0",
        // left: "0",
        background: "url(" + product.image + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundPosition: "center",
    }

    return (
        <Modal isOpen={props.isOpen} onClose={props.onClose} className={"Modal"}>
            <div className="modal-item">
                {
                    product.inventory === 0 ?
                    <h2 style={{"color": "var(--red)"}}>Lo sentimos, este producto no se encuentra disponible.</h2>
                    :
                    repeatedProduct ?
                    <h2>Este artículo ya está en el carrito </h2>
                    :
                    <h2> Artículo añadido exitosamente al carrito</h2>
                }
                <div className="modal-item_Card">
                    <div className="modal-item_Image" style={style}>
                        
                    </div>
                    <div className="modal-item_Info">
                        <h2 className="name">
                            <b>{product.name} </b>
                        </h2>
                        <h4 className="description">
                            {product.description}
                        </h4>
                        <h4>
                            Piezas: {qty}
                        </h4>
                        <h4 >
                            Precio unitario: 
                            {
                                product.offer_price ? 
                                <>
                                    <span>
                                        <s> $ {product.price.toFixed(2)}</s>
                                    </span>
                                    <span>
                                      {"  "}$ {product.offer_price.toFixed(2)}
                                    </span>
                                </>
                                :
                                <span>
                                    $ {product.price.toFixed(2)}
                                </span>
                            }
                        </h4>
                        <h4>
                            Total: $
                            {
                                product.offer_price ?
                                    getProductPrice(product.offer_price, qty)
                                :
                                    getProductPrice(product.price, qty)
                            }
                        </h4>
                    </div>
                </div>
                <div className="add-to-cart-modal-action">
                
                    <button onClick={props.onClose} className="primary1">
                        Continua comprando
                    </button>
                    <button onClick={handlePayNow} className="primary2">
                        Pagar ahora
                    </button>
                
                </div>
            </div>
        </Modal>
    )
}


export default AddToCartModal;