function cartReducer (state = { cartItems: [] }, action) {
    switch (action.type) {
        case 'ADD_TO_CART':
            const itemFound = state.cartItems.find(item=> item.product.id === action.payload.product.id )
            // console.log("item found? ", itemFound);
            if (itemFound) {
                return {
                    ...state,
                    cartItems: state.cartItems.map(item => 
                        item.product.id === itemFound.product.id ? 
                        action.payload : item
                    ),
                    lastItemAdded: action.payload,
                    repeatedProduct: true
                }
            }
            else {
                return {
                    ...state,
                    cartItems: [
                        ...state.cartItems,
                        action.payload
                    ],
                    lastItemAdded: action.payload,
                    repeatedProduct: false
                }
            }
        case 'REMOVE_FROM_CART':
            const productToRemove = action.payload
            // console.log(state.cartItems.filter(x => x.product.id !== productToRemove.id));
            return {
                ...state,
                cartItems: state.cartItems.filter(x => x.product.id !== productToRemove.id)
            }
        case 'REFRESH_CART_REQUEST':
            return {
                ...state,
                loadingRefresh: true
            }
        case 'REFRESH_CART_SUCCESS':
            return {
                ...state,
                cartItems: action.payload,
                loadingRefresh: false
            }
        case 'REFRESH_CART_FAIL':
            return {
                ...state,
                loadingRefresh: false
            }
        case 'CLEAR_CART_ITEMS':
            return {
                ...state,
                cartItems: []
            }
        default:
            return state;
    }
}



export  { 
    cartReducer
}