import axios from "axios"
import { api_url } from "../utils/api-route";
import { filterByLetterRange, filterByPriceRange  } from "../utils/filters";

const baseUrl = api_url

// const delay = ms => new Promise(resolve => setTimeout(resolve, ms));
// const randomNumber = (min = 0, max = 1) =>
//   Math.floor(Math.random() * (max - min + 1)) + min;
// const simulateNetworkLatency = (min = 30, max = 1000) =>
//   delay(randomNumber(min, max));

export const listOffers = () => async (dispatch) => {
    try {
        dispatch({ type: 'OFFERS_REQUEST' })
        const { data } = await axios.get(baseUrl + 'products/offers/getAll')
        dispatch({ type: 'OFFERS_SUCCESS', payload: data })
    } catch (error) {
        // console.log(error);
        dispatch({
            type: 'OFFERS_FAIL',
            payload: error.message
        })
    }
    
}

export const listSuggested = () => async (dispatch) => {
    try {
        dispatch({ type: 'SUGGESTED_REQUEST' })
        const { data } = await axios.get(baseUrl + 'products/suggestedProducts/tab')
        dispatch({ type: 'SUGGESTED_SUCCESS', payload: data[0] })
    } catch (error) {
        // console.log(error);
        dispatch({
            type: 'SUGGESTED_FAIL',
            payload: error.message
        })
    }
    
}

export const searchProducts = (query) => async (dispatch) => {
    try {
        dispatch({ type: 'QUERY_REQUEST' })
        const { data } = await axios.get(baseUrl + 'products/userSearchProduct/a', {
            params:{
                wanted: query
            } 
        })
        
        dispatch({ type: 'QUERY_SUCCESS', payload: data })
    } catch (error) {
        dispatch({
            type: 'QUERY_FAIL',
            payload: error.message
        })
    }
}

export const searchProductsArrayID = (idArray) => async (dispatch) => {
    try {
        dispatch({ type: 'GROUP_QUERY_REQUEST' })
        const { data } = await axios.get(
            baseUrl + 'shoppingCart/refreshShoppingCart', {
            params:{
                "id_products": idArray
            } 
        })
        const categoriesAvailable = data.map(prod => [prod.first_category, 1])
        const subcategoriesAvailable = data.map(prod => [prod.second_category, 1])
        const payload  = [ data, categoriesAvailable, subcategoriesAvailable ]
        dispatch({ type: 'GROUP_QUERY_SUCCESS', payload: payload })
    } catch (error) {
        dispatch({
            type: 'GROUP_QUERY_FAIL',
            payload: error.message
        })
    }
}


export const filterSearchProducts = (filters) => (dispatch, getState) => {
    dispatch({ type: 'FILTER_REQUEST' })
    let { resultsList: { productsResults } } = getState();
    console.log(filters);
    let filteredResults = []

    // Filter ABC
    const letters = filters.abc;
    if (letters.size === 0) {
        filteredResults = productsResults
    } else {
        letters.forEach(letter => {
            switch (letter) {
                case 'C': // A - C
                    filteredResults = [
                        ...filteredResults,
                        ...filterByLetterRange(productsResults, "a", "c")
                    ]
                    break;
                case 'H':  // D - H
                    filteredResults = [
                        ...filteredResults,
                        ...filterByLetterRange(productsResults, "d", "h")
                    ]
                    break;
                case 'O':  // I - O
                    filteredResults = [
                        ...filteredResults,
                        ...filterByLetterRange(productsResults, "i", "o")
                    ]
                    break;
                case 'Z':  //  P - Z
                    filteredResults = [
                        ...filteredResults,
                        ...filterByLetterRange(productsResults, "p", "z")
                    ]
                    break;
                default:
                    break;
            }
        });
    }
    // Filter price
    const prices = filters.price;
    if (prices.size !== 0) {
        productsResults = filteredResults
        filteredResults = []
        prices.forEach(price => {
            // console.log(price);
            switch (price) {
                case 49.99: // A - C
                    filteredResults = [
                        ...filteredResults,
                        ...filterByPriceRange(productsResults, 0, 49.99)
                    ]
                    break;
                case 199.99:  // D - H
                    filteredResults = [
                        ...filteredResults,
                        ...filterByPriceRange(productsResults, 50, 199.99)
                    ]
                    break;
                case 499.99:  // I - O
                    filteredResults = [
                        ...filteredResults,
                        ...filterByPriceRange(productsResults, 200, 499.99)
                    ]
                    break;
                case 1000000:  //  P - Z
                    filteredResults = [
                        ...filteredResults,
                        ...filterByPriceRange(productsResults, 500, 1000000)
                    ]
                    break;
                default:
                    break;
            }
            
        });
    }
    // Filter category 
    const categories = filters.category
    if (categories.size !== 0) {
        productsResults = filteredResults
        // console.log(productsResults);
        filteredResults = []
        categories.forEach(category => {
            filteredResults = [
                ...filteredResults,
                ...productsResults.filter(product => {
                    return product.first_category === category
                })
            ]
        })
    }
    // Filter Subcategory 
    const subcategories = filters.subcategory
    if (subcategories.size !== 0) {
        productsResults = filteredResults
        console.log(productsResults);
        filteredResults = []
        subcategories.forEach(subcategory => {
            filteredResults = [
                ...filteredResults,
                ...productsResults.filter(product => {
                    return product.second_category === subcategory
                })
            ]
        })
    }
    console.log("filtered");
    console.log(filteredResults);
    dispatch({ type: 'FILTER_SUCCESS', payload: filteredResults})
}

export const getProductsByCategory = (category) => async (dispatch) => {
    try {
        dispatch({ type: 'CATEGORY_REQUEST' })
        console.log("Llamando a la api: ");
        // await simulateNetworkLatency();
        const { data } = await axios.get(baseUrl + 'category/' + category)
        // console.log("products by category");
        // console.log(data);
        dispatch({ type: 'CATEGORY_SUCCESS', payload: data })
    } catch (error) {
        // console.log("error");
        // console.log(error.message);
        dispatch({
            type: 'CATEGORY_FAIL',
            payload: error.message
        })
    }
}

export const cleanResults = () => async (dispatch) => {
    dispatch({ type: 'CLEAN_QUERY_RESULTS' })
}


