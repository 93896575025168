import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { userSignup, userLogin } from '../actions/userActions';

import './SignupUser.css'


function Signup(props) {
    const [name, setName] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [rePassword, setRePassword] = useState('');

    const [ errorPassword, setErrorPassword ] = useState(null)
    
    const user = useSelector(state => state.userSignup);
    const { loading, userInfo, error } = user;
    const dispatch = useDispatch();
    
    //const redirect = props.location.search?props.location.search.split("=")[1]:'/'
    const redirect = "/";

    useEffect(() => {
      if (userInfo) {
        dispatch(userLogin(email, password))
        props.history.push('/verifyEmail');
      }
    }, [userInfo, props.history, dispatch, email, password]);
  
    const submitHandler = (e) => {
      e.preventDefault();
      if (password !== rePassword) {
        
        setErrorPassword("Las contraseñas no coinciden")
      } else if (password.length < 8){
        setErrorPassword("La longitud de la contraseña debe ser de almenos 8 caracteres")
      } else {
        setErrorPassword(null)
        dispatch(userSignup(name, lastname, email, password, rePassword));
      }
    }
    
    return (
      <div className="signupUser-container">

        <div className="form-container">  
          <form onSubmit={submitHandler} className="form">
              <ul className="form-list signup-form">
                  <li>
                  <h2>Crear nueva cuenta</h2>
                  </li>
                  <li>
                  {loading && <div>Loading...</div>}
                  {error && <h4 className="error-message">{error.message}</h4>}
                  { errorPassword && <h4 className="error-message">{errorPassword}</h4> }
                  </li>
                  <li>
                    <label htmlFor="name"> Nombre </label>
                    <input required type="name" name="name" id="name" onChange={(e) => setName(e.target.value)}/>
                  </li>
                  <li>
                    <label htmlFor="last_name"> Apellidos </label>
                    <input required type="last_name" name="last_name" id="last_name" onChange={(e) => setLastname(e.target.value)}/>
                  </li>
                  <li>
                  <label htmlFor="email">Email</label>
                  <input required type="email" name="email" id="email" onChange={(e) => setEmail(e.target.value)} />
                  </li>
                  <li>
                  <label htmlFor="password">Contraseña</label>
                  <input required type="password" id="password" name="password" onChange={(e) => setPassword(e.target.value)}/>
                  </li>
                  <li>
                  <label htmlFor="rePassword">Confirmar contraseña</label>
                  <input required type="password" id="rePassword" name="rePassword" onChange={(e) => setRePassword(e.target.value)}/>
                  </li>
                  <li className="checkbox-li">
                  
                      <label className="tyc-checkbox-label" htmlFor="terms">
                      <input type="checkbox" id="temrs" name="terms" className="tyc-checkbox" required/>
                      Acepta los <Link to="/terminos-y-condiciones"> Términos y Condiciones</Link>
                      </label>
                    
                  </li>
                  <li>
                    <label htmlFor="">
                      También puedes leer nuestro <Link to="/aviso-de-privacidad">Aviso de Privacidad.</Link>
                    </label>
                  </li>
                  <li>
                  <button type="submit" className="button primary1">Registrarse</button>
                  </li>
                  <li>
                  Ya tienes una cuenta con nosotros? <Link to={redirect === "/" ? "loginUser" : "loginUser?redirect=" + redirect} 
                          className="button">Iniciar sesión</Link>
                  </li>

              </ul>
          </form>
      
        </div>
      </div>
    )
}

export default Signup
