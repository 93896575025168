import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import CartItem from '../components/CartItem'

import ClipLoader from 'react-spinners/ClipLoader' //https://www.npmjs.com/package/react-spinners

import './Cart.css'

import getProductSumPrice from '../utils/get-sum-price'
import { refreshCart } from '../actions/cartActions'

const Cart = (props) => {
   
    const { cartItems, loadingRefresh } = useSelector(state => state.cart)
    const items_id = cartItems.map(item => item.product.id)

    const numItems = cartItems.reduce((a, c) => a + c.qty, 0);
    const total_price = getProductSumPrice(cartItems);
    

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(refreshCart(items_id))
    }, [dispatch])
    
    const checkOutHandler = () => {
        props.history.push("/loginUser?redirect=shipping")
    }

    return (
        <div className='cart-page-container'>
            {
            cartItems.length > 0 ?
            <>
                <div className="cart-legend-container">
                    {
                    loadingRefresh ?
                    <>
                    <h1>Actualizando el carrito </h1>
                    <ClipLoader/>
                    </>
                    :
                    <>
                    <h1>Tus productos:</h1>
                    </>
                    }
                </div>
                <div className="container_Cart">
                    <div className="products-list_Cart">
                        <ul>
                        {
                            cartItems.map((item, i) => {
                                return(
                                <li key={i}>
                                    <CartItem 
                                        product={item.product}
                                        qty={item.qty}
                                        hasChanged={item.hasChanged}
                                    />
                        
                                </li>
                                )
                            })
                        }
                        </ul> 
                    </div>
                    
                    <div className="cart-action_Cart-grid_cointaner">
                        <div className="cart-action_Cart">
                            <h2>
                                Subtotal ({numItems} productos): $ {total_price}
                            </h2>
                            <h3>
                                {
                                    total_price >= 100 ?
                                    "¡Tu pedido califica para envío gratis!":
                                    "Tu pedido no califica para envío gratis,"
                                    + "favor de recoger en tienda (envíos gratis" 
                                    + " en compras mayores a $100.00 MXN)"
                                }
                            </h3>
                            <button onClick={checkOutHandler} className="primary2">
                                Proceder al pago
                            </button>
                        </div>
                    </div>
                </div>
            </>
            :
            <div className="cart-legend-container">
                <h1>No hay productos en el carrito</h1>
            </div>
            }
        </div>
        
    )
}


export default Cart;
