import React, { useState } from 'react'

const OrderSummaryButton = () => {
    const [ isOpen, setOpenTo ] = useState(false)

    const onCloseSummaryMobile = () => {
        setOpenTo(false)
        document.querySelector(".summary-mobile").classList.remove("open")
    }
    const onOpenSummaryMobile = () => {
        setOpenTo(true)
        document.querySelector(".summary-mobile").classList.add("open")
    }

    return (
        <div>

            <button 
                className="show-details-button"
                onClick={isOpen?onCloseSummaryMobile:onOpenSummaryMobile}
            >
                <span>
                    Resúmen de pedido
                </span>
                {
                    isOpen ? 
                    <span>
                        &#65086;
                    </span>
                    :
                    <span>
                        &#65085;
                    </span>
                }
            </button>
        </div>
    )
}

export default OrderSummaryButton
