import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { searchProductsArrayID } from '../actions/productActions'


import Slide from './Slide';
import SlideShow from './SlideShow';

import './Banner.css'
import { api_url } from '../utils/api-route';



const styleLanding = {
    'backgroundSize': 'cover',
    'backgroundPosition': 'center',
    'backgroundImage': `url(img/care-head4.jpg)`
}

const getOfferStyle = (offer) => {
    const styleOffer = {
        'backgroundImage': `url(https://farmaciasdrvertiz.com/api/public/storage/${offer.image})`,
        'backgroundSize': 'contain',
        'backgroundRepeat': 'no-repeat',
        'backgroundPosition': 'center'
    }
    return styleOffer
}


const Banner = () => {
    const [loadingOffers, setLoadingOffers] = useState(true)
    const [offers, setOffers] = useState([])


    useEffect(() => {
        var call_url = api_url + 'products/offers/getAll'
        fetch(call_url,{
            method: "get"
        })
        .then(res => res.json())
        .then(response => {
            setOffers(response);
            // console.log(response);
            setLoadingOffers(false);
        })
        .catch((error) => {
            setLoadingOffers(false);
            // console.log(error);
        })

    },[])  
    
    
    // console.log(offers);

    const history = useHistory()
    const dispatch = useDispatch()
    const handleClickOffer = (offer) => {
        const idArray = offer.products.map(prod =>  prod.id )
        dispatch(searchProductsArrayID(idArray))
        history.push("/results")
    }

    return (
        <div>
            {
                !loadingOffers && offers.length !== 0 ?
                <SlideShow t={2}>
                    {
                        offers.map((offer,i) => {
                            return(
                                <Slide 
                                    className="carousel-item offer fade" 
                                    style={getOfferStyle(offer)}    
                                    key={i+2}
                                >
                                <button 
                                    className="link-to-offer" 
                                    onClick={() => handleClickOffer(offer)}>
                                
                                </button>
                                    
                                </Slide>
                            )
                        })
                    }
                    
                </SlideShow>
                :
                <div>
                </div>
            }
            
            
        </div>
        
    )
}

export default Banner


// const properties = {
//     duration: 3500,
//     transitionDuration: 800,
//     infinite: true,
//     indicators: true
// };


