import React from 'react'

import Modal from './Modal'

import './ImageModal.css'



const ImageModal = (props) => {
   
    const style = {
        height: "70vh",
        
        position: "relative",
        // top: "0",
        // left: "0",
        background: "url(" + props.img_src + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundPosition: "center",
    }
    return (
        <Modal isOpen={props.isOpen} onClose={props.onClose} className="Modal">
            <div className="imageModal" style={style}>
                
            </div>
        </Modal>
    )
}


export default ImageModal;