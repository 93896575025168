import React, { useState, useEffect } from 'react'
import CreateProduct from '../../components/CreateProduct';
import Editproduct from '../../components/Editproduct';


const ProductosDashboard = () => {
    
    const [ isCreateProductOpen, setCreateProductOpenTo ] = useState(false)
    const [ isEditProductOpen, setEditProductOpenTo ] = useState(false)
    
    useEffect(() => {
        if (document.querySelector(".sidebar")){
            document.querySelector(".sidebar").classList.remove("open")
        }
    }, [])
    
    

    return (
        <div >
            <div className="">
                <button className="primary1" onClick={() => setCreateProductOpenTo(!isCreateProductOpen)}>
                    Crear producto nuevo
                </button>
                { isCreateProductOpen && <CreateProduct /> }
                
            </div>
            <div className="">
                <button className="primary1" onClick={() => setEditProductOpenTo(!isEditProductOpen)}>
                    Editar producto existente
                </button>
                { isEditProductOpen && <Editproduct />}
                
            </div>
            
            
        </div>
    )
}

export default ProductosDashboard;
