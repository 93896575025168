import React from 'react'

import ProductItem from '../components/ProductItem'

import './Products.css'


const Products = ({ products }) => {
    return (
        <>
        {
            products ?
        products.length > 0 ? 
    
        <ul className="products">
            {
            products.map(product => {
                return(
                <li key={product.id}>
                    <ProductItem
                        product={product}
                    />
                </li>
                )
            })
            }
        </ul>
        : 
        <h2>
            No se encontraron productos 
        </h2>:""
        }
    </>
    )
}

export default Products
