import React from 'react'

import './ShippingDetailsItem.css'

const ShippingDetailsItem = (props) => {
    const product = props.product 
    
    const qty = props.qty

    return (
        <div className="shippingDetails-item_Card">
            <div className="shippingDetails-item_Image">
                <img src={product.image} alt={product.name + " image "}/>
                <div className="shippingDetails-item_Info">
                    <h4 className="name">
                        <b>{product.name} </b>
                    </h4>
                    <p>
                        x {qty}
                    </p>
                </div>
            </div>
            <div className="shippingDetails-item_Price">
                {
                    product.offer_price ?
                    <>
                        
                        <h3 >
                            <s>{product.price.toFixed(2)}  $</s>
                        </h3>
                        <h3 >
                            {product.offer_price.toFixed(2)}  $
                        </h3>
                    </>
                    :
                    <h3 >
                        {product.price.toFixed(2)}  $
                    </h3>
                }
                
            </div>
        </div>
    )
}

export default ShippingDetailsItem
