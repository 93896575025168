import React from 'react';
import { useMediaQuery } from 'react-responsive'
import CheckoutSteps from '../components/CheckoutSteps';
import OrderSummary from '../components/OrderSummary';
import OrderSummaryButton from '../components/OrderSummaryButton';

import ShippingPresenter from '../components/ShippingPresenter'

import './Shipping.css'

function Shipping() {

  const isDesktop = useMediaQuery({
    query: '(min-width: 1024px)'
  })
  
  
  return (
      <>
        {
          isDesktop ?
            <div className="shipping-container-desktop">
              <div className="shipping-data">
                <CheckoutSteps step1 step2 />

                <ShippingPresenter />
              </div>
              <div className="summary">
                <OrderSummary />
              </div>
            </div>
          :
          <div className="shipping-container-tablet-mobile">
            <div className="headers_shipping-container">
              <CheckoutSteps step1 step2 />
              <OrderSummaryButton />
            </div>
            <div className="summary-mobile">
              <OrderSummary />
            </div>
            <div className="shipping-data">
              <ShippingPresenter />
            </div>
          </div>
        }


        
          
        
      </>
  )
}

export default Shipping
