import React, { useState } from 'react'

import { CSSTransition } from 'react-transition-group'
import ImageModal from './ImageModal'

import './ProductImage.css'

function ProductImage(props) {
    const [ imageModalOpen, setImageModalOpenTo ] = useState(false)
    
    
    
    const handleOpenImageModal = () => {
        setImageModalOpenTo(true)
    }
    
    const style = {
        height: "25rem",
        width: "27rem",
        position: "relative",
        // top: "0",
        // left: "0",
        background: "url(" + props.img_src + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundPosition: "center",
    
        // "&::before": {
        //     content: `''`,
        //     position: "relative",
        //     top: "0",
        //     left: "0",
        //     background: "black",
        //     width: "300px",
            // height: "25rem",
            // animation: "animate 5s ease-in-out infinite",
            // background: "url(" + props.img_src + ") no-repeat fixed 150%",
            // minHeight: "25rem",
        // }
        
    }

    return (
        <div className="product_Image" style={style}>
            <button onClick={handleOpenImageModal} type="button">    
            
                {/* <GlassMagnifier
                    imageSrc={props.img_src}
                    imageAlt={props.img_alt}
                    magnifierSize="75%"
                    largeImageSrc={props.img_src}
                    style={style} 
                />*/}
            </button>
            <CSSTransition
                in={imageModalOpen}
                timeout={500}
                classNames="modal-transition"
                unmountOnExit
            >
                <ImageModal 
                    img_src={props.img_src}
                    img_alt={props.img_alt}
                    isOpen={imageModalOpen}
                    onClose={()=>setImageModalOpenTo(false)}
                    
                />
            </CSSTransition>
        </div>
    )
}

export default ProductImage

// div {
    //     height: 25rem;
    //     width: 27rem;
    //     border: solid black 1px;
    //     background: url("https://farmaciasdrvertiz.com/api/public/storage/images/2.jpg");
    //     background-repeat: no-repeat;
    //       background-size: contain;
    //       background-position: center;
    // }
    
    // div::before {
    //     content: '';
    //     position: relative;
    //     top: 0;
    //     left: 0;
    //     width: 300px;
    //     background: #000;
        
    // }