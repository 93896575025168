import React from 'react'
import { Link } from 'react-router-dom'

const DropdownItem = (props) => {

    return (
        <>
            {
                props.link &&
                <Link to={props.to} className={props.dropdownItemClass} onClick={props.onClick}>
                    {props.text}
                </Link>
            }
            {
                props.button &&
                <button className={props.dropdownItemClass} onClick={props.onClick}>
                    {props.text}
                </button>
            }
        </>
    )
}

export default DropdownItem
