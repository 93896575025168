import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { activateDiscountCode, createDiscountCode, deactivateDiscountCode, getDiscountCodes } from '../../actions/adminActions';
import { useHistory } from 'react-router-dom'

import Table from '../../components/Table';

import ClipLoader from 'react-spinners/ClipLoader' //https://www.npmjs.com/package/react-spinners

const columnsTable = [
    "No.", "Fecha", "Código", "Descuento", "Status", "Acción"
]

const DiscountCodesDashboard = () => {
    
    const [ codeName, setCodeName ] = useState("");
    const [ discount, setDiscount ] = useState(0);

    const admin = useSelector(state => state.adminLogin )
    const { adminInfo } = admin;
    
    const dispatch = useDispatch();
    const history = useHistory();

    const codeSavedInfo = useSelector(state => state.adminCreateDiscountCode)
    const { loading, codeSaved, error} = codeSavedInfo

    const discountCodesInfo = useSelector(state => state.adminDiscountCodes)
    const { loadingCodes, discountCodes, errorCodes } = discountCodesInfo

    const changeInCodeInfo = useSelector(state => state.adminChangeDiscountCode)
    const loadingChangeCode = changeInCodeInfo.loading
    const codeStatusChanged = changeInCodeInfo.codeStatusChanged
    const errorChangeCode = changeInCodeInfo.error

    useEffect(() => {
        if (document.querySelector(".sidebar")){
            document.querySelector(".sidebar").classList.remove("open")
        }
    }, [])

    useEffect(() => {
        if (adminInfo) {
            dispatch(getDiscountCodes(adminInfo.token))
        } else {
            history.push("/loginAdmin")
        }
    }, [codeStatusChanged, history, dispatch, adminInfo])

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(createDiscountCode(codeName, discount, adminInfo.token));
    }

    const handleStatusChange = (isValid, id) => {
        if (isValid){
            dispatch(deactivateDiscountCode(adminInfo.token, id))
        } else {
            dispatch(activateDiscountCode(adminInfo.token, id))
        }        
    }

    function actionButton(valid, id) {
        if (valid){
            return (
                <button className="delete" onClick={ () => handleStatusChange(valid, id)}>
                    Desactivar
                </button>
            )
        } else {
            return (
                <button className="primary1" onClick={ () => handleStatusChange(valid, id)}>
                    Activar
                </button>
            )
        } 
        

    }
    
    function getTableRows (codes) {
        
        const rows = codes.map(code => {
            return([
                code.discount_code_id,
                code.created_at,
                code.code,
                code.discount_percent,
                code.isValid ? "Activado": "Desactivado",
                actionButton(code.isValid, code.discount_code_id)
            ])

        })
        return rows
    }

    return (
        <div>
            <h2>Crear código de descuento</h2>
            <div className="form-container">
                <form onSubmit={submitHandler} className="form">
                    <ul className="form-list">
                        <li>
                            {  error && <h4>{error}</h4> } 
                            {  codeSaved && <h4>{ codeSaved }</h4>  }
                        </li>
                        <li>
                            <label htmlFor="nombre-producto">
                                Nombre del Código
                            </label>
                            <input 
                                type="text" 
                                name="code-name" 
                                id="code-name" 
                                required
                                onChange={(e) => setCodeName(e.target.value)}    
                            />
                        </li>
                        <li>
                            <label htmlFor="code-discount">
                                Descuento (en porcentaje)
                            </label>
                            <input 
                                type="decimal" 
                                name="code-discount" 
                                id="code-discount" 
                                required
                                onChange={(e) => setDiscount(e.target.value)}    
                            />
                        </li>
                    
                        <li>
                            {
                                loading?
                                <div className="clip-loader">
                                    <ClipLoader />
                                </div>
                                :
                                <button type="submit" className="button primary1">
                                    Crear código 
                                </button>
                            }
                        </li>        
                    </ul>
                </form>
            </div>
            <h2>Códigos de descuento creados anteriormente {loadingChangeCode || loadingCodes ? <ClipLoader/> : ""} </h2>
            <h4>{errorChangeCode ? "No fue posible cambiar el status, intentalo más tarde.":""}</h4>
            
            {
                errorCodes && 
                <h2>Ocurrió un error al obtener los códigos de descuento, inténtelo más tarde</h2>
            }
            {
                discountCodes &&
                <Table
                    className="discount-code-table"
                    columnsHeader={columnsTable}
                    rows={getTableRows(discountCodes)}
                    
                />
            }
        </div>
    )
}



export default DiscountCodesDashboard
