import React from 'react'

//import { useMediaQuery } from 'react-responsive'

import RecomendedProducts from '../components/RecomendedProducts'


import './Home.css'

import CustomerRelations from '../components/CustomerRelations'
import Banner from '../components/Banner'


function Home() {
    if (document.querySelector(".sidebar")){
        document.querySelector(".sidebar").classList.remove("open")
    }
    
    
    // const isMobile = useMediaQuery({
    //     query: '(max-width: 768px)'
    // })
    // const isDesktop = useMediaQuery({
    //     query: '(min-width: 769px)'
    // })

    return(
        <div>
            <Banner />
            <CustomerRelations />
            <RecomendedProducts  />
            {/* <Offers /> */}
        </div>
    ) 
}






export default Home;
