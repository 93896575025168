import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { getPurchaseByIdAdmin } from '../../actions/adminActions';

import { ClipLoader } from 'react-spinners'

import './PurchaseDetails.css'
import Table from '../../components/Table';
const PurchaseDetails = () => {
    const params_url = useParams();

    const admin = useSelector(state => state.adminLogin )
    const { adminInfo } = admin;

    const purchase = useSelector(state => state.adminPurchases)
    const {loading, singlePurchase, error } = purchase

    const dispatch = useDispatch();
    // console.log("singlePurchase: ", singlePurchase);
    
    useEffect(() => {
        if (document.querySelector(".sidebar")){
            document.querySelector(".sidebar").classList.remove("open")
        }
    }, [])
    
    useEffect(() => {
        dispatch(getPurchaseByIdAdmin(adminInfo.token, params_url.id))    
    }, [dispatch, adminInfo.token, params_url.id])

    function getRowsPurchase() {
        return([
            ["Dirección de envio" ,singlePurchase.address],
            ["Teléfono" ,singlePurchase.phone],
            ["Correo electrónico" ,singlePurchase.email],
            ["Usuario" ,singlePurchase.user],
            ["Total de compra" , "$ " + singlePurchase.total],
            ["Facturación" ,singlePurchase.billing_detail ? "Sí" : "No"]
        ])
    }

    function getRowsProducts(){
        const rows = singlePurchase.purchase_detail.map(prod => {
            return([
                prod.id,
                <img src={prod.image} alt="product"/>,
                prod.name,
                prod.quantity,
            ])
        })
        return rows
    }

    return (
        <div>
            {
                loading && <ClipLoader />
            } 
            {
                error && <h2>{error.message}</h2> 
            }
            {
                singlePurchase &&
                <div className="purchase-info">
                    <Table
                        columnsHeader={["",""]}
                        rows={getRowsPurchase()}
                    />
                    
                    <h2>Productos del pedido: </h2>
                    <div className="list-products">
                        <Table
                            columnsHeader={["ID","", "Producto", "Piezas"]}
                            rows={getRowsProducts()}
                        />
                        
                    </div>
                </div>
            }
        </div>
    )
}

export default PurchaseDetails
