import React from 'react'
import RowTable from './RowTable'

const Table = ({ columnsHeader, rows, className }) => {
    
    return (
        <div>
            <table className={className}>
                <thead>
                    <tr>
                        {
                            columnsHeader.map((col,i) => {
                                return(
                                    <th key={i}>{col}</th>
                                )
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        rows.map((row,j)=>{
                            return (
                                <RowTable 
                                    key={j}
                                    values={row}
                                />
                            )
                        })
                    }
                </tbody>
            </table>        
        </div>
    )
}

export default Table
