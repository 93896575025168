import React, { useState } from 'react'

import { useDispatch } from 'react-redux';

import { addToCart } from '../actions/cartActions'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'

import AddToCartModal from './AddToCartModal'

import { CSSTransition } from 'react-transition-group'
import './ProductItem.css'

import ProductImage from './ProductImage';


function ProductItem (props) {
    const [ modalOpen, setModalOpenTo ] = useState(false)
    const [qty, setQty] = useState(1)
    const product = props.product;

    const dispatch = useDispatch();


    const handleAddToCart = e => {
        setModalOpenTo(true)
        if (product.inventory !== 0) {
            setTimeout(() => dispatch(addToCart(product, qty)), 600);
        }
    }

    
    // const style_product_img = {
    //     "backgroundImage": `url(${product.image})`,
    //     "backgroundRepeat": "no-repeat",
    //     "backgroundPosition": "center",
    //     "backgroundSize": "contain"
    // }
    
    return (
        <div className="product_Card">
            <ProductImage 
                img_src={product.image} 
                img_alt={product.name} 

            />
            <div className="product_Info">
                <div className="name-container">
                    <h4 className="name">
                        {product.name}
                    </h4>
                </div>
                
                <p className="status">
                    ({product.inventory > 0 ? "Disponible" : "No disponible."})
                </p>
                {
                    product.offer_price && 
                    <span>
                        <s>
                        $ {product.price.toFixed(2)}
                        </s>
                    </span>
                }
                <div className="action">
                    {
                        product.offer_price ? 
                        <div className="price"> 
                            <span>$ {product.offer_price.toFixed(2)} </span>
                        </div>
                        :
                        <div className="price"> 
                            <span>$ {product.price.toFixed(2)} </span>
                        </div>

                    }
                    <div className="add-to-cart">
                        <select 
                            value={qty} 
                            className="qty-select" 
                            onChange={ (e) => setQty(e.target.value)}
                        >
                            {
                                product.inventory > 0 ?
                                [
                                    ...Array(product.inventory).keys()
                                ].map(x =>
                                    <option key={x + 1} value={x + 1}>
                                        {x + 1}
                                    </option>
                                )
                                : 
                                <option value={0}>
                                    {0}
                                </option>

                            }                         
                        </select>
            
                        <button 
                            onClick={handleAddToCart}
                            type="button" 
                        >
                            <FontAwesomeIcon icon={faShoppingCart} />
                        </button>
                        
                        <CSSTransition
                            in={modalOpen}
                            timeout={500}
                            classNames="modal-transition"
                            unmountOnExit
                        >
                            <AddToCartModal 
                                isOpen={modalOpen}
                                product={product}
                                qty={qty}
                                onClose={()=>setModalOpenTo(false)}
                            />
                        </CSSTransition>
                     
                        
                    </div>
                     
                </div>
            </div>
        </div>
    )
}



export default ProductItem;
