import React, { useEffect } from 'react'
import { Link,  useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { adminLogout } from '../../actions/adminActions';


const Administradores = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { adminInfo } = useSelector(state => state.adminLogin)
    // console.log(adminInfo);
    
    useEffect(() => {
        if (document.querySelector(".sidebar")){
            document.querySelector(".sidebar").classList.remove("open")
        }
    }, [])
    
    const handleLogoutAdmin = () => {
        dispatch(adminLogout())
        history.push("/")
    }

    return (
        <div>
            <h1>

            Hola {adminInfo.first_name}, eres un administrador
            </h1>
            <div>
                
                <div style={{"marginBottom": "3rem"}}>
                    <Link 
                        to="/signupAdmin" 
                        className="primary2"
                    >
                        Crear nuevo administrador
                    </Link>
                </div>
                <button 
                    onClick={handleLogoutAdmin} 
                    className="basic"
                    style={{"width": "22.5rem"}}    
                >
                    Cerrar sesion
                </button>
            </div>
        </div>
    )
}

export default Administradores
