import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { listSuggested } from '../actions/productActions'

import ProductItem from './ProductItem'


import './RecomendedProducts.css'

import { ClipLoader } from 'react-spinners'


const RecomendedProducts = () => {
    const [ productsToShow, setProductsToShow ] = useState(4)

    const suggestedList = useSelector(state => state.suggestedList)
    const { productsSuggested, loadingSuggested } = suggestedList
    
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(listSuggested());
    },[dispatch])  

    const handleShowMoreProducts = () => {
        setProductsToShow(productsToShow + 4)
    }

    return (
        <div className="recommended-products">
            <div className="recommended-products-header">
                <h1>Productos destacados</h1>
            </div>
            <div className="recommended-products-content">
                <ul className="products-recommended">
                    {
                        loadingSuggested && <ClipLoader />
                    }
                    {
                        productsSuggested &&
                        productsSuggested.slice(0,productsToShow).map((product,i) => {
                            return(
                            <li key={i}>
                                <ProductItem
                                    product={product}
                                />
                            </li>
                            )
                        })
                    }
                </ul>
            </div>
            <button className="primary1" onClick={handleShowMoreProducts}>
                Ver más productos destacados
            </button>
        </div>
    )
}

export default RecomendedProducts

