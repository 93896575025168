import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import SideFiltersDesktop from '../components/SideFiltersDesktop'

import { useMediaQuery } from 'react-responsive'

import './Results.css'

//https://www.digitalocean.com/community/tutorials/how-to-build-custom-pagination-with-react
import PaginationDO1 from '../components/PaginationDO1'


import Products from '../components/Products'
import FiltersButton from '../components/FiltersButton'
import SideFilters from '../components/SideFilters'
import { getProductsByCategory, searchProducts } from '../actions/productActions'
import { ClipLoader } from 'react-spinners'

function Results(props) {
    const [ currentProducts, setCurrentProducts ] = useState([]);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ productsPerPage ] = useState(18);
    
    const params_url = useParams()
    const dispatch = useDispatch()
    const results = useSelector(state => state.resultsList)
    const { loading, filteredResults, categoriesAvailable, subcategoriesAvailable } = results
    // console.log("filtered results", filteredResults);
    
    useEffect(() => {
        if (document.querySelector(".sidebar")){
            document.querySelector(".sidebar").classList.remove("open")
        }
    }, [])

    useEffect(() => {
        params_url.category && dispatch(getProductsByCategory(params_url.category))
        params_url.query && dispatch(searchProducts(params_url.query))
    }, [params_url, dispatch])

    useEffect(() => {
        setCurrentPage(1)
    }, [filteredResults])
    
    const onCloseFilters = () => {
        document.querySelector(".sidebar-filters").classList.remove("open")
    }
    const handleOpenFilters = () => {
        document.querySelector(".sidebar-filters").classList.add("open")
    }

    
    const offset = (currentPage - 1) * productsPerPage;
    const onPageChanged = (newPage) => {
        // console.log("NewPage ", newPage);
        const currentProducts = filteredResults.slice(offset, offset + productsPerPage);
        setCurrentProducts(currentProducts)
        setCurrentPage(newPage)
        
    }

    const isMobile = useMediaQuery({
        query: '(max-width: 768px)'
    })


    return (
        <div>
            
            <div className="results-page-grid-container">
                {/* <div className="void"></div> */}
                <div className="filters-desktop-grid-item">
                    <SideFiltersDesktop 
                        subcategories={subcategoriesAvailable}
                        categories={categoriesAvailable}
                    />
                </div>    
                <div className="results-params-grid-item">
                    
                    <div className="products-found">
                        {
                            loading ?
                            <ClipLoader />
                            :
                            <>
                                <h2>Resultados de tu búsqueda: </h2>
                                <h2>{filteredResults &&filteredResults.length} productos encontrados</h2>
                            </>
                        }
                    </div>
                    {
                        filteredResults && 
                        <PaginationDO1
                            totalRecords={filteredResults.length} 
                            pageLimit={productsPerPage} 
                            pageNeighbours={1} 
                            onPageChanged={onPageChanged}
                            currentPage={currentPage}
                            
                        />
                    }
                    {
                        isMobile && !loading ?
                        <FiltersButton 
                            text="Filtrar resultados"
                            onClick={handleOpenFilters}
                        />:""
                    }
                    {
                        currentProducts &&
                        <Products 
                            products={filteredResults &&filteredResults.slice(offset, offset + productsPerPage)}
                        />
                    }
                
                
                </div>
                
                {/* <div className="void"></div> */}
                {

                    isMobile &&
                    <SideFilters 
                        subcategories={subcategoriesAvailable}
                        categories={categoriesAvailable}
                        onCloseFilters={onCloseFilters}
                    />
                }
            </div>            
            
        </div>
    )
}

export default Results

//.slice(indexOfFirstProduct, indexOfLastProduct)


// {
//     isMobile &&
//     <div className="results">
            
//             <div className="products-found">
//                 {
//                     loading ?
//                     <ClipLoader />
//                     :
//                     <h1>Resultados de tu búsqueda: {filteredResults &&filteredResults.length} productos encontrados</h1>
//                 }
//             </div>
//             {
//                 filteredResults && 
//                 <PaginationDO1
//                     totalRecords={filteredResults.length} 
//                     pageLimit={productsPerPage} 
//                     pageNeighbours={1} 
//                     onPageChanged={onPageChanged}
//                     currentPage={currentPage}
                    
//                 />
//             }
//             <FiltersButton text="Filtrar resultados"/>
//             {
//                 currentProducts &&
//                 <Products 
//                     products={filteredResults &&filteredResults.slice(offset, offset + productsPerPage)}
//                 />
//             }
//         </div>
//     }