import axios from "axios"
import Cookie from 'js-cookie'
import { api_url } from "../utils/api-route"

const baseUrl = api_url

const adminSignup = (name, lastname, email, password, rePassword,token) => async (dispatch) => {
    const newAdmin = { 
        first_name: name, 
        last_name: lastname,
        email: email, 
        contraseña: password, 
        confirmar_contraseña: rePassword,
        token: token
    }
  
    dispatch({ 
        type: 'ADMIN_SIGNUP_REQUEST', 
        payload: newAdmin
    });
    try {
        const newAdmin = { 
            first_name: name, 
            last_name: lastname,
            email: email, 
            contraseña: password, 
            confirmar_contraseña: rePassword,
            token: token
        }
        // console.log(newAdmin);
        const { data } = await axios.post(baseUrl + "admin/register", newAdmin);
        const payload = {
        ...data,
        } 
        dispatch({ type: 'ADMIN_SIGNUP_SUCCESS', payload: payload });
    } catch (error) {
        // console.log(error.response);
        dispatch({ type: 'ADMIN_SIGNUP_FAIL', payload: error.response.data });
    }
}

const adminLogout = () => (dispatch) => {
    Cookie.remove('adminInfo')
    // console.log("admin logout and cokies removed");
    dispatch({ type: 'ADMIN_LOGOUT' })
}

const adminLogin = (email, password) => async (dispatch) => {
    dispatch({ type: 'ADMIN_LOGIN_REQUEST', payload: { email, password } })
    try {
        const admin = {
            email: email, 
            password: password
        }
        const { data } = await axios.get(baseUrl + 'admin/login', {
            params: admin
        })
        // console.log(data);
        Cookie.set('adminInfo', JSON.stringify(data)) 
        dispatch({ type: 'ADMIN_LOGIN_SUCCESS', payload: data })
    } catch (error) {
        // console.log(error.data);
        dispatch({ type: 'ADMIN_LOGIN_FAIL', payload: error.response.data })
    }
}

const getPurchasesAdmin = (token) => async (dispatch) => {
    dispatch({ type: 'ADMIN_GET_PURCHASES_REQUEST' })
    try {
        const admin_data = {
            token: token,
        }
        const { data } = await axios.get(baseUrl + 'admin/getPurchases', {
            params: admin_data 
        })
        
        let activePurchases = []
        let unactivePurchases = []
        data.map(purchase => {
            if (purchase.status <= 2) {
                activePurchases.push({
                ...purchase
                })
            }
            else {
                unactivePurchases.push({
                ...purchase
                })
            }
            return []
        })
        
        dispatch({
            type: "ADMIN_GET_PURCHASES_SUCCESS",
            payload: {
              unactivePurchases: unactivePurchases,
              activePurchases: activePurchases
            }
        })
        
    } catch (error) {
        dispatch({ type: 'ADMIN_GET_PURCHASES_FAIL', payload: error.response })
    }
}

const getPurchaseByIdAdmin = (token, id) => async (dispatch) => {
    try {
        dispatch({ type: 'ADMIN_GET_PURCHASE_BY_ID_REQUEST' })
        const admin_data = {
            token: token,
            id: id
        }
        const { data } = await axios.get(baseUrl + 'admin/getPurchaseById', {
            params: admin_data 
        })
        
        
        dispatch({
            type: "ADMIN_GET_PURCHASE_BY_ID_SUCCESS",
            payload: data
        })
    } catch (error) {
        dispatch({ type: 'ADMIN_GET_PURCHASE_BY_ID_FAIL', payload: error.response })
    }
}

const changePurchaseStatus = (token, id, new_status) => async (dispatch) => {
    dispatch({
        type: 'CHANGE_PURCHASE_STATUS_REQUEST'
    })
    try {
        const params = {
            token: token,
            id: id,
            new_status: new_status
        }
        // console.log(params);
        await axios.put(baseUrl + 'admin/modifyPurchase', params)
        // console.log("data");
        // console.log(data);
        dispatch({
            type: 'CHANGE_PURCHASE_STATUS_SUCCESS'
        })
    } catch (error) {
        dispatch({
            type: 'CHANGE_PURCHASE_STATUS_FAIL',
            payload: error.response
        })  
    }
}

const createProduct = (productName, productPrice, quantity, image, token, category, subcategory) => async (dispatch) => {
    try {
        dispatch({ type: 'SAVE_PRODUCT_REQUEST' })
        const body = new FormData() 
        body.append('token', token)
        body.append('imagen', image)
        body.append('inventory', quantity)
        body.append('price', productPrice)
        body.append('name', productName)
        body.append('first_category', category)
        body.append('second_category', subcategory)
        const { data } = await axios.post(baseUrl + 'admin/createProduct', body)
        // console.log(data);
        dispatch({ type: 'SAVE_PRODUCT_SUCCESS', payload: data })
    } catch (error) {
        console.log(error);
        dispatch({ type: 'SAVE_PRODUCT_FAIL', payload: error })
    }
}




const createDiscountCode = (code, discount, token) => async (dispatch) => {
    try {
        dispatch({ type: 'SAVE_CODE_REQUEST' })
        if (discount > 0 && discount < 100){
            const body = {
                token: token,
                code: code,
                discount_percent: discount/100
            }
            // console.log(body);
            const { data } = await axios.post(baseUrl + 'admin/discountCode/create', body)
            // console.log(data);
            dispatch({ type: 'SAVE_CODE_SUCCESS', payload: data.message })
        } else {
            dispatch({ 
                type: 'SAVE_CODE_FAIL', 
                payload: "El código de descuento debe ser mayor que 0 y menor a 100" 
            })
            return {}
        }
    } catch (error) {
        // console.log(error.response.data.message);
        dispatch({ type: 'SAVE_CODE_FAIL', payload: error.response.data.message })
    }
}

const getDiscountCodes = (token) => async (dispatch) => {
    try {
        dispatch({ type: 'GET_DISCOUNT_CODES_REQUEST' })
        const { data } = await axios.get(baseUrl + 'admin/discountCode/all', {
            params: { token: token }
        })
        // console.log(data);
        dispatch({ type: 'GET_DISCOUNT_CODES_SUCCESS', payload: data })
    } catch (error) {
        // console.log(error.response.data.message);
        dispatch({ type: 'GET_DISCOUNT_CODES_FAIL', payload: error.response.data.message })
    }
}

const activateDiscountCode = (token, id_code) => async (dispatch) => {
    dispatch({
        type: 'ACTIVATE_DISCOUNT_CODE_REQUEST'
    })
    try {
        const params = {
            token: token,
            id_code: id_code,
        }
        await axios.put(baseUrl + 'admin/discountCode/activate', params)
        // console.log("data");
        // console.log(data);
        dispatch({
            type: 'ACTIVATE_DISCOUNT_CODE_SUCCESS'
        })
    } catch (error) {
        // console.log("pelotudoerror");
        // console.log(error.response);
        dispatch({
            type: 'ACTIVATE_DISCOUNT_CODE_FAIL',
            payload: error
        })  
    }
}

const deactivateDiscountCode = (token, id_code) => async (dispatch) => {
    dispatch({
        type: 'DEACTIVATE_DISCOUNT_CODE_REQUEST'
    })
    try {
        const params = {
            token: token,
            id_code: id_code,
        }
        await axios.put(baseUrl + 'admin/discountCode/deactivate', params)
        // console.log("data");
        // console.log(data);
        dispatch({
            type: 'DEACTIVATE_DISCOUNT_CODE_SUCCESS'
        })
    } catch (error) {
        // console.log("pelotudoerror");
        // console.log(error);
        dispatch({
            type: 'DEACTIVATE_DISCOUNT_CODE_FAIL',
            payload: error
        })  
    }
}

const createOffer = (token, img, products) => async (dispatch) => {
    try {
        dispatch({ type: 'CREATE_OFFER_REQUEST' })
        const list_products = products.map(prod => {
            return [prod.id, prod.offer_price]
        })

        // validate offer pŕoducts
        var qs = require('qs');
        const { data } = await axios.get(baseUrl + 'admin/products/offers/validateProducts', {
            params: { 
               token: token, 
               products: list_products,
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        })
        const successful_validation = data.success;

        // console.log("Validation of products, ", data);
        if (successful_validation) {
            // create offer
            const create_offer = new FormData() 
            create_offer.append('image', img)
            create_offer.append('token', token)
            const offer_created  = (await axios.post(baseUrl + 'admin/products/offers/create', create_offer)).data
    
            // addProducts to offer
            const add_products = await axios.post(baseUrl + 'admin/products/offers/addProducts', { 
                token: token, products: list_products, id_offer: offer_created.id_offer
            })
            dispatch({ type: 'CREATE_OFFER_SUCCESS', payload: add_products.data.message})
        } else {
            dispatch({ type: 'CREATE_OFFER_SUCCESS', payload: data.message})
        }
    } catch (error) {
        // console.log("error: ");
        // console.log(error.response);
        dispatch({ type: 'CREATE_OFFER_FAIL', payload: error.response.message })
    }
}

const getOffers = (token) => async(dispatch) =>{
    try {
        dispatch({ type: 'GET_OFFERS_REQUEST' })
        const { data } = await axios.get(baseUrl + 'admin/products/offers/getAll', {
            params: { token: token }
        })
        dispatch({ type: 'GET_OFFERS_SUCCESS', payload: data })
    } catch (error) {
        // console.log(error.response.data.message);
        dispatch({ type: 'GET_OFFERS_FAIL', payload: error.response.data.message })
    }
}

const changeOfferStatus = (token, id_offer, isActive) => async (dispatch) => {
    try {
        dispatch({ type: 'CHANGE_OFFER_STATUS_REQUEST' })
        if (isActive) {
            const { data } = await axios.put(baseUrl + 'admin/products/offers/deactivate', {
                token: token, id_offer: id_offer
            })
            dispatch({ type: 'CHANGE_OFFER_STATUS_SUCCESS', payload: data })
        } else {
            const { data } = await axios.put(baseUrl + 'admin/products/offers/activate', {
                token: token, id_offer: id_offer
            })
            dispatch({ type: 'CHANGE_OFFER_STATUS_SUCCESS', payload: data })
        }
    } catch (error) {
        dispatch({ type: 'CHANGE_OFFER_STATUS_FAIL', payload: error.response.data.message })
    }
}
export {
    adminSignup,
    adminLogin,
    adminLogout,
    getPurchasesAdmin,
    getPurchaseByIdAdmin,
    changePurchaseStatus,
    createProduct,
    createDiscountCode,
    getDiscountCodes,
    activateDiscountCode,
    deactivateDiscountCode,
    createOffer,
    getOffers,
    changeOfferStatus,
}