import React, { useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { resendVerificationEmail, userLogout } from '../actions/userActions'


import './VerificationEmail.css'
import { ClipLoader } from 'react-spinners'

const VerificationEmail = (props) => {
    
    const user = useSelector(state => state.userLogin)
    const { userInfo  } = user

    const resendEmail = useSelector(state => state.userResendVerificationEmail);
    const { loadingResend, emailResentMessage } = resendEmail;

    const dispatch = useDispatch()

    let btnRef = useRef();

   

    const handleResentVerificationEmail = () => {
        
        dispatch(resendVerificationEmail(userInfo.token))
    }

    const handleLogout = () => {
        dispatch(userLogout())
        props.history.push("/")
    }

    return (
        <div className="verificationEmail_page_container">
            <div className="verificationEmail_content_container">
                {
                    userInfo && 
                    <div className="message">
                        <h1>
                            Te hemos enviado un correo electrónico de verificacion a {userInfo.email}.
                            haz click en el link que viene dentro para empezar.
                        </h1>
                    </div>
                }
                <div className="action">
                    <button 
                        onClick={handleResentVerificationEmail} 
                        className="primary1"
                        ref={btnRef}
                    >
                        {
                            loadingResend ?
                            <ClipLoader />
                            :
                            "El email no llegó? Haz click aquí para renviar otro"
                        }
                    </button>
                    {
                        emailResentMessage && <h2>{emailResentMessage}</h2>
                    }
                    <button onClick={handleLogout} className="basic">
                        Cerrar sesión
                    </button>
                </div>
            </div>
        </div>
    )
}

export default VerificationEmail
