import React from 'react'

import './AvisoPrivacidad.css'

const AvisoPrivacidad = () => {
    return (
        <div className="adp-page">
            <div className="text-wrapper">
                <h1>Aviso de privacidad integral</h1>
                <p>
                Jose Antonio Lara Morales mejor conocido como Farmacias Dr. Vertiz, con domicilio en Cumbres de maltrata 398 Local D Colonia Narvarte, Alcaldía Benito Juárez, Cdmx. y portal de internet www.farmaciasdrvertiz.com/aviso-de-privacidad, es el responsable del uso y protección de sus datos personales, y al respecto le informamos lo siguiente:
                </p>

                <h3>
                ¿Para qué fines utilizaremos sus datos personales?
                </h3>
                <p>
                Los datos personales que recabamos de usted, los utilizaremos para las siguientes finalidades que son necesarias para el servicio que solicita:
                </p>
                <div className="list-wrapper">

                    <ul>
                        <li>
                        Envío de productos adquiridos en esta tienda en línea
                        </li>
                    </ul>
                </div>


                <h3>
                ¿Qué datos personales utilizaremos para estos fines?
                </h3>
                <p>
                Para llevar a cabo las finalidades descritas en el presente aviso de privacidad, utilizaremos los siguientes datos personales:
                </p>
                <div className="list-wrapper">
                    <ul>
                        <li>Datos de identificación y contacto</li>
                    </ul>
                </div>


                <h3>
                ¿Cómo puede acceder, rectificar o cancelar sus datos personales, u oponerse a su uso o ejercer la revocación de consentimiento?
                </h3>
                <p>
                Usted tiene derecho a conocer qué datos personales tenemos de usted, para qué los utilizamos y las condiciones del uso que les damos (Acceso). Asimismo, es su derecho solicitar la corrección de su información personal en caso de que esté desactualizada, sea inexacta o incompleta (Rectificación); que la eliminemos de nuestros registros o bases de datos cuando considere que la misma no está siendo utilizada adecuadamente (Cancelación); así como oponerse al uso de sus datos personales para fines específicos (Oposición). Estos derechos se conocen como derechos ARCO.
                </p>
                <p>
                Para el ejercicio de cualquiera de los derechos ARCO, debe enviar una petición vía correo electrónico a farmvertiz@hotmail.com y deberá contener:
                </p>
                <div className="list-wrapper">
                    <ul>
                        <li>Nombre completo del titular.</li>
                        <li>Domicilio.</li>
                        <li>Teléfono.</li>
                        <li>Correo electrónico usado en este sitio web.</li>
                        <li>Copia de una identificación oficial adjunta.</li>
                        <li>Asunto «Derechos ARCO»</li>
                    </ul>
                </div>
                <p>
                Descripción el objeto del escrito, los cuales pueden ser de manera enunciativa más no limitativa los siguientes: Revocación del consentimiento para tratar sus datos personales; y/o Notificación del uso indebido del tratamiento de sus datos personales; y/o Ejercitar sus Derechos ARCO, con una descripción clara y precisa de los datos a Acceder, Rectificar, Cancelar o bien, Oponerse. En caso de Rectificación de datos personales, deberá indicar la modificación exacta y anexar la documentación soporte; es importante en caso de revocación del consentimiento, que tenga en cuenta que no en todos los casos podremos atender su solicitud o concluir el uso de forma inmediata, ya que es posible que por alguna obligación legal requiramos seguir tratando sus datos personales. Asimismo, usted deberá considerar que para ciertos fines, la revocación de su consentimiento implicará que no le podamos seguir prestando el servicio que nos solicitó, o la conclusión de su relación con nosotros.
                </p>
                <div className="list-wrapper">
                    <ul className="incisos-letras">
                        <li>
                        ¿En cuántos días le daremos respuesta a su solicitud?
                        <ul>
                        5 días
                        </ul>
                        </li>
                        <li>
                        ¿Por qué medio le comunicaremos la respuesta a su solicitud?
                        <ul>
                            farmvertiz@hotmail.com
                        </ul>
                        </li>
                    </ul>
                </div>


                <h3>
                El uso de tecnologías de rastreo en nuestro portal de internet
                </h3>
                <p>
                Le informamos que en nuestra página de internet utilizamos cookies, web beacons u otras tecnologías, a través de las cuales es posible monitorear su comportamiento como usuario de internet, así como brindarle un mejor servicio y experiencia al navegar en nuestra página. Los datos personales que obtenemos de estas tecnologías de rastreo son los siguientes:
                </p>
                
                <div className="list-wrapper">
                    <ul>
                        <li>Identificadores, nombre de usuario y contraseñas de sesión</li>
                    </ul>
                </div>
                <p>
                Estas cookies, web beacons y otras tecnologías pueden ser deshabilitadas. Para conocer cómo hacerlo, consulte el menú de ayuda de su navegador. Tenga en cuenta que, en caso de desactivar las cookies, es posible que no pueda acceder a ciertas funciones personalizadas en nuestros sitio web.
                </p>

                <h3>
                ¿Cómo puede conocer los cambios en este aviso de privacidad?
                </h3>
                <p>
                El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales; de nuestras propias necesidades por los productos o servicios que ofrecemos; de nuestras prácticas de privacidad; de cambios en nuestro modelo de negocio, o por otras causas. Nos comprometemos a mantener actualizado este aviso de privacidad sobre los cambios que pueda sufrir y siempre podrá consultar las actualizaciones que existan en el sitio web www.farmaciasdrvertiz.com/aviso-de-privacidad.
                </p>
                <p>
                Última actualización de este aviso de privacidad: 11/06/2021
                </p>
            </div>
        </div>
    )
}

export default AvisoPrivacidad
