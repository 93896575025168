import axios from "axios"
import Cookie from 'js-cookie'
import { api_url } from "../utils/api-route"
const baseUrl = api_url

const userLogin = (email, password) => async (dispatch) => {
    dispatch({ type: 'USER_LOGIN_REQUEST', payload: { email, password } })
    try {
      const user = {
        email: email, 
        password: password
      }
      const { data } = await axios.get(baseUrl + 'user/login', {
        params: user
        
      })
      Cookie.set('userInfo', JSON.stringify(data))
      dispatch({ type: 'USER_LOGIN_SUCCESS', payload: data })
    } catch (error) {
      // console.log(error.response.data);
        dispatch({ type: 'USER_LOGIN_FAIL', payload: error.response.data })
    }
}

const userLogout = () => (dispatch) => {
  Cookie.remove('userInfo')
  // console.log("user logout and cokies removed");
  dispatch({ type: 'USER_LOGOUT' })
}

const userSignup = (name, lastname, email, password, rePassword) => async (dispatch) => {
  // console.log("user signup action ....");
  const newUser = { 
    first_name: name, 
    last_name: lastname,
    email: email, 
    contraseña: password, 
    confirmar_contraseña: rePassword
  }
  dispatch({ 
      type: 'USER_SIGNUP_REQUEST', 
      payload: newUser
  });
  try {
    const { data } = await axios.post(baseUrl + "user/register", newUser);
    const payload = {
      ...data,
    }
    dispatch({ type: 'USER_SIGNUP_SUCCESS', payload: payload });
  } catch (error) {
    // console.log(error.response); 
    // for (const [key, value] of Object.entries(error.response.data.message)) {
      // console.log(`${key}: ${value}`);
    // }
    dispatch({ type: 'USER_SIGNUP_FAIL', payload: error.response.data });
  }
}

const userIsVerified = (token) => async (dispatch) => {
  dispatch({
    type: 'USER_IS_VERIFIED_REQUEST'
  })
  try {

    const { data } = await axios.get(baseUrl + 'user/isVerified', {
      params: {
        token: token
      }
    })
    // console.log(data.verified);
    dispatch({
      type: 'USER_IS_VERIFIED_SUCCESS',
      payload: data.verified
    })
  } catch (error) {
    dispatch({
      type: 'USER_IS_VERIFIED_FAIL',
      payload: error.response
    })
  }
}

const resendVerificationEmail = (token) => async (dispatch) => {
  dispatch({ 
    type: 'RESEND_VERIFICATION_EMAIL_REQUEST'
  });
  try {
    const { data } = await axios.post(baseUrl + 'email/verify/resend', {
      token: token
    })
    const payload = {
      ...data
    }
    // console.log(payload);
    dispatch({ type: 'RESEND_VERIFICATION_EMAIL_SUCCESS', payload: payload.message });
  } catch (error) {
    dispatch({ type: 'RESEND_VERIFICATION_EMAIL_FAIL', payload: error });
  }
}

const userSendLinkResetPassword = (email) => async (dispatch) => {
  dispatch({ 
      type: 'USER_RESET_PASSWORD_REQUEST'
  });
  try {
    const { data } = await axios.post(baseUrl + "user/sendPasswordResetLink", { 
      email: email
    });
    const payload = {
      ...data,
    }
    
    dispatch({ type: 'USER_RESET_PASSWORD_SUCCESS', payload: payload });
  } catch (error) {
    dispatch({ type: 'USER_RESET_PASSWORD_FAIL', payload: error });
  }
  
}

const userChangePassword = (email, password, rePassword, token) => async (dispatch) => {
  dispatch({ 
      type: 'USER_CHANGE_PASSWORD_REQUEST'
  });
  try {
    const { data } = await axios.post(baseUrl + "user/resetPassword", { 
      email: email,
      resetToken: token,
      contraseña: password,
      confirmar_contraseña: rePassword
    });
    const payload = {
      ...data,
    }
    console.log(payload);
    dispatch({ type: 'USER_CHANGE_PASSWORD_SUCCESS', payload: payload.message });
  } catch (error) {
    console.log(error.response);
    dispatch({ type: 'USER_CHANGE_PASSWORD_FAIL', payload: error.response.data });
  }
  
}

const userPurchases = (token) => async (dispatch) => {
  dispatch({
    type: "USER_GET_PURCHASES_REQUEST"
  });
  
  try {
    const {data}  = await axios.get(baseUrl + 'purchases', {
      params:{
        token: token
      }
    })
    // console.log(data);
    
    let activePurchases = []
    let unactivePurchases = []
    data.map(purchase => {
      if (purchase.status <= 2) {
        activePurchases.push({
          ...purchase
        })
      }
      else {
        unactivePurchases.push({
          ...purchase
        })
      }
      return []
    })
    

    dispatch({
      type: "USER_GET_PURCHASES_SUCCESS",
      payload: {
        unactivePurchases: unactivePurchases,
        activePurchases: activePurchases
      }
    })
  }catch (error) {
    dispatch({
      type: "USER_GET_PURCHASES_FAIL",
      payload: error.response
    });
  }

}

const getPurchaseByIdUser = (token, id) => async (dispatch) => {
  try {
      dispatch({ type: 'USER_GET_PURCHASE_BY_ID_REQUEST' })
      const user_data = {
          token: token,
          id: id
      }
      const { data } = await axios.get(baseUrl + 'purchases/getById', {
          params: user_data 
      })
      
      
      dispatch({
          type: "USER_GET_PURCHASE_BY_ID_SUCCESS",
          payload: data
      })
  } catch (error) {
      dispatch({ type: 'USER_GET_PURCHASE_BY_ID_FAIL', payload: error.response })
  }
}
export { 
  userLogin, 
  userLogout,
  userSignup,
  userIsVerified,
  resendVerificationEmail,
  userSendLinkResetPassword,
  userChangePassword,
  userPurchases,
  getPurchaseByIdUser
  
}